import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { TableLangValue } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import { t } from '@/services/translator';

const ExpectedQuestionPatternForm = ({ match }) => {
  return (
    <View>
      <ResourceForm
        resource="QuestionPattern"
        id={match.params.id}
        initialValues={{ alias: '', values: null, name: {}, why: null, order: '' }}
      >
        {({ submitting, renderActions }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={12}>
                    <p>{t('question_pattern.name')}</p>
                    <TableLangValue name="name" />
                  </Col>
                  <Col lg={12}>
                    <p>{t('question_pattern.description')}</p>
                    <TableLangValue name="description" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

ExpectedQuestionPatternForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default ExpectedQuestionPatternForm;
