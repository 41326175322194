/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Col, FormGroup } from 'reactstrap';
import WeverDashboard from 'wever-dashboard';

import Context from '@/services/context';
import logger from '@/services/logger';
import getResource from '@/services/resources';
import security from '@/services/security';
import { t } from '@/services/translator';

export default ({ dashboard, type, id }) => {
  const { locale, community } = useContext(Context);
  const [communityId, setCommunity] = useState(null);
  const [dashboardId, setDashboardId] = useState();
  const [reportId, setReportId] = useState();
  const [loaded, setLoaded] = useState();

  const loadMapCommunities = async () => {
    let actualCommunityId = null;

    if (community) {
      actualCommunityId = community.replace('/communities/', '');
    }

    setDashboardId(null);
    setCommunity(parseInt(actualCommunityId));

    if (!community && dashboard) {
      setDashboardId(dashboard.id);
      setLoaded(true);
      return;
    }

    try {
      const element = await getResource('Community').getSheetActionDasboard(actualCommunityId, id, type);

      setDashboardId(element.id);
      setReportId(element.report);
    } catch (e) {
      logger.error('sheet action', 'dashboard', e);
    }

    setLoaded(true);
  };

  useEffect(() => {
    loadMapCommunities();
  }, [community]);

  if (!dashboard || !dashboard.id) {
    return null;
  }

  if (!loaded || !dashboardId) {
    return null;
  }

  logger.warn('DASHBOARD sheet action', '', { dashboardId });

  try {
    return (
      <div>
        <FormGroup row>
          <Col className="iframe-dashboard colHeight iframe-dashboard">
            <WeverDashboard
              editionMode
              dashboard={dashboardId}
              locale={locale}
              token={security.token}
              report={reportId}
              community={communityId}
            />
          </Col>
        </FormGroup>
      </div>
    );
  } catch (e) {
    return (
      <div className="w-100 text-center" style={{ color: '#ccc' }}>
        {t('adminapp_oopps_something_crashed')}
      </div>
    );
  }
};
