import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, Autocomplete, Editor } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

const TagForm = ({ match }) => {
  const update = (values, setValues) => {
    if (!values) {
      return;
    }

    const newValues = { ...values };
    let needUpdate = false;
    if (newValues.type && newValues.type['@id']) {
      newValues.type = newValues.type['@id'];
      needUpdate = true;
    }

    if (newValues.diagnostic && newValues.diagnostic['@id']) {
      newValues.diagnostic = newValues.diagnostic['@id'];
      needUpdate = true;
    }

    if (needUpdate) {
      setValues(newValues);
    }
  };

  return (
    <View>
      <ResourceForm resource="Action" id={match.params.id} initialValues={{ name: '' }}>
        {({ submitting, renderActions, values, setValues }) => (
          <>
            {update(values, setValues)}
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={6}>
                    <Input name="name" />
                  </Col>
                  <Col lg={6}>
                    <Input name="lunchAt" />
                  </Col>
                  <Col lg={12}>
                    <Autocomplete
                      name="type"
                      clearable
                      resource="ActionType"
                      display="name"
                      tag="name"
                      autoload
                      onChange={(value, option) => {
                        const val = JSON.stringify(option.value.parameters)
                          .replace(':{', ':{\n')
                          .replace(',', ',\n')
                          .replace(',"', ',\n"')
                          .replace('{', '{\n')
                          .replace('}', '\n}');
                        setValues({ ...values, actionType: value, actionBack: { parameters: val } });
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Editor name="actionFront.parameters" height="300" />
                  </Col>
                  <Col lg={6}>
                    <Editor name="actionBack.parameters" height="300" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

TagForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default TagForm;
