/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col } from 'reactstrap';

// import { Editor } from '@/components/form';
import _Form from '@/components/form';
const { Editor } = _Form;

// Api platform attend des IRIS. et pas des objets
export default () => (
  <Row className="m-0">
    <Col>
      <Editor name="configuration" />{' '}
    </Col>
  </Row>
);
