/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Col, Row, Card, CardBody, Button, Input as ReactInput } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Input } from '@/components/form';
import { Error } from '@/components/form';
import logger from '@/services/logger';
import getResource from '@/services/resources';

import helper from '../select/helper';

const TableLangValue = ({ name, inputType, onChange }) => {
  const [field, , helpers] = useField({ name });
  const [values, setValues] = useState();
  const [wordsTrans, setWordTrans] = useState();
  const [locales, setLocales] = useState();

  let { value } = field;
  const { setValue } = helpers;

  const addLang = () => {
    if (!values) {
      return;
    }

    if (!value || Array.isArray(value)) {
      value = {};
    }

    if (value[values.value]) {
      return;
    }

    value[values.value] = wordsTrans || 'N/A';
    setValue(value);
    setWordTrans('');

    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  const removeLang = (e, lang) => {
    e.preventDefault();

    if (value[lang]) {
      delete value[lang];
      setValue(value);

      if (typeof onChange === 'function') {
        onChange(value);
      }
    }
  };

  const fetchLanguages = async (terms) => {
    try {
      const response = await getResource('Language').list({ name: terms });

      if (!response || !response['hydra:member'] || !Array.isArray(response['hydra:member'])) {
        return [];
      }

      setLocales(
        response['hydra:member'].map((lang) => {
          return { value: lang.locale, label: lang.locale };
        })
      );
    } catch (e) {
      logger.error('Langue fetcher', 'option', e);
      return [];
    }
  };

  const changeLocale = (oldLocale, locale) => {
    let valueLocale = null;
    if (value[oldLocale]) {
      valueLocale = value[oldLocale];
    }

    delete value[oldLocale];

    setValue({ [locale]: valueLocale, ...value });
  };

  useEffect(() => {
    fetchLanguages();

    if (value) {
      return;
    }

    setValue({ fr: 'N/A' });

    if (typeof onChange === 'function') {
      onChange({ fr: 'N/A' });
    }
  }, []);

  return (
    <Card className="bg-light">
      <CardBody>
        <Row>
          {value &&
            Object.keys(value).map((lang) => {
              return (
                <Col sm="12" key={lang} className="p-0">
                  <Row className="p-0">
                    <Col sm="3" className="pr-1">
                      <Select
                        options={locales}
                        cacheOptions
                        defaultOptions
                        value={{ value: lang, label: lang }}
                        onChange={(option) => changeLocale(lang, option.value)}
                        styles={helper.buildStyles('sm', false)}
                        className="select-hide-indicator"
                      />
                    </Col>
                    <Col sm="7" className="p-0">
                      <Input name={`${name}['${lang}']`} label={null} type={inputType} />
                    </Col>
                    <Col sm="2">
                      <Button size="sm" color="danger" type="button" onClick={(e) => removeLang(e, lang)}>
                        <Icon name="trash" />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          <Col sm="12" className="p-0">
            <Row className="p-0">
              <Col sm="3" className="pr-1">
                <Select
                  options={locales}
                  cacheOptions
                  defaultOptions
                  value={values}
                  placeholder="Lang"
                  onChange={(option) => setValues(option)}
                  styles={helper.buildStyles('sm', false)}
                  className="select-hide-indicator"
                />
              </Col>
              <Col sm="7" className="p-0">
                <ReactInput
                  value={wordsTrans}
                  placeholder="ABCD"
                  onChange={(e) => setWordTrans(e.target.value)}
                  size="sm"
                />
              </Col>
              <Col sm="2">
                <Button size="sm" color="danger" onClick={addLang}>
                  <Icon name="plus" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Error name={name} />
      </CardBody>
    </Card>
  );
};

TableLangValue.propTypes = {
  name: PropTypes.string.isRequired,
  inputType: PropTypes.string
};

TableLangValue.defaultProps = {
  inputType: 'text'
};

export default TableLangValue;
