// Helpers
export const Label = require('./helpers/label').default;
export const Error = require('./helpers/error').default;

// Inputs
export const Input = require('./input').default;
export const Checkbox = require('./checkbox').default;
export const Select = require('./select/input').default;
export const SelectIcon = require('./select/select-icon').default;
export const Toggle = require('./toggle').default;
export const Radio = require('./radio').default;
export const Radios = require('./radios').default;
export const RadioButtons = require('./radio-buttons').default;
export const Datepicker = require('./datepicker').default;
export const Resource = require('./resource').default;
export const Coordinates = require('./coordinates').default;
export const Editor = require('./editor').default;
export const TableLangValue = require('./table-lang-value/table-lv').default;
export const Autocomplete = require('./autocomplete').default;
export const AutocompleteCreatable = require('./autocomplete').default;
export const AutocompleteServer = require('./autocomplete-server').default;
export const MediaInput = require('./media-input').default;
export const WysiwygEditor = require('./wysiwyg').default;
export const WysiwygLocatorEditor = require('./wysiwyg-trad').default;
export const TagsAutocomplete = require('./tags').default;
export const CreatableSelect = require('./creatable-select/input').default;

export default {
  Label,
  Error,
  Input,
  Checkbox,
  Select,
  CreatableSelect,
  SelectIcon,
  Toggle,
  Radio,
  Radios,
  RadioButtons,
  Datepicker,
  Resource,
  Coordinates,
  Editor,
  TableLangValue,
  Autocomplete,
  AutocompleteCreatable,
  AutocompleteServer,
  TagsAutocomplete,
  MediaInput,
  WysiwygEditor,
  WysiwygLocatorEditor
};
