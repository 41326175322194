import Api from '@/services/api';
import Resource from '@/services/resources/common';

export default class Language extends Resource {
  constructor(entrypoint = '/languages', alias = 'Language') {
    super(entrypoint, alias);
  }
  async getLanguages() {
    const response = await Api.get('/languages');

    return response.data;
  }
}
