/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import View from '@/components/view';
import security from '@/services/security';

export default ({ history }) => {
  const redirectToCommunities = () => {
    let isTranslator = security.isGranted('ROLE_TRAD_DIAGNOSTIC');
    let isSuperAdmin = security.isGranted('ROLE_SUPER_ADMIN');
    let pathname = isTranslator && !isSuperAdmin ? 'translator/expected_response_pattern' : 'communities';

    history.push({
      pathname: pathname,
      state: { from: 'login' }
    });
  };

  useEffect(() => {
    redirectToCommunities();
  }, []);

  return <View>Dashboard</View>;
};
