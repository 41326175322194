import React from 'react';
import PropTypes from 'prop-types';
// import { ErrorMessage } from 'formik';
import { FormGroup, Col, Label, Row } from 'reactstrap';
import { t } from '@/services/translator';
import _Security from '@/services/security';
import _Form from '@/components/form';
const { Input, Checkbox } = _Form;

const Copil = ({ idx }) => {
  return (
    <div key={`copil-${idx}`}>
      <Row>
        <Col xs="6">
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_referent_lastname')} :</Label>
            <Col sm={6}>
              <Input label={null} name={`copils[${idx}].lastname`} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_referent_firstname')} :</Label>
            <Col sm={6}>
              <Input label={null} name={`copils[${idx}].firstname`} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_referent_job')} :</Label>
            <Col sm={6}>
              <Input label={null} name={`copils[${idx}].job`} />
            </Col>
          </FormGroup>
        </Col>
        <Col xs="6">
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_referent_email')} :</Label>
            <Col sm={6}>
              <Input label={null} name={`copils[${idx}].email`} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>{t('communitiesapp_referent_phone')} :</Label>
            <Col sm={6}>
              <Input label={null} name={`copils[${idx}].phone`} />
            </Col>
          </FormGroup>
          {!_Security.isGranted('ROLE_ADMIN') ? null : (
            <FormGroup row>
              <Label sm={3}>{t('communitiesapp_access_to_data')} :</Label>
              <Col sm={6}>
                <Checkbox className="" label={null} name={`copils[${idx}].access`} />
              </Col>
            </FormGroup>
          )}
        </Col>
      </Row>
    </div>
  );
};

Copil.propTypes = {
  idx: PropTypes.number
};

export default Copil;
