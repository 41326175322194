import Api from '@/services/api';
import Resource from '@/services/resources/common';

export default class ActionTypeResource extends Resource {
  constructor(entrypoint = '/action_types', alias = 'ActionType') {
    super(entrypoint, alias);
  }

  async getEvents() {
    const response = await Api.get(this.entrypoint + '/events');

    return response.data;
  }

  async read() {
    const responses = await Api.get(this.entrypoint);
    return responses.data;
  };
}
