/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Row, Table } from 'reactstrap';
import 'wever-maps/src/assets/style.scss';

import Media from '@/components/common/media';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';
import { Context } from '@/services/context';

const GeoType = () => {
  const { locale } = useContext(Context);
  return (
    <View>
      <ResourceList
        resource="GeometryType"
        deleteMultiple
        filters={() => <Row></Row>}
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => {
          return (
            <Table responsive hover>
              <thead className="thead-light">
                <tr>
                  {renderColumn(renderSelect('*'), false)}
                  {renderColumn('id')}
                  {renderColumn('name')}
                  {renderColumn('picto')}
                  {renderColumn()}
                </tr>
              </thead>
              <tbody>
                {collection.map((item) => (
                  <tr key={item.id}>
                    <td>{renderSelect(item.id)}</td>
                    <td>{item.id}</td>
                    <td>{item.name[locale]}</td>
                    <td>
                      <Media data={item.picto} height="20px" width="20px" />
                    </td>
                    <td>{renderItemActions(item, null, true)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          );
        }}
      />
    </View>
  );
};
export default GeoType;
