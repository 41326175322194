import classnames from 'classnames';
import React from 'react';
import { Card, CardBody, NavItem, NavLink, Nav, TabContent, TabPane } from 'reactstrap';

import Icon from '@/components/common/icon';
import router from '@/services/router';
import translator, { t } from '@/services/translator';

export default ({ children, baseUri, loading, showTabs, className }) => {
  const panes = [];
  children = !Array.isArray(children) ? [children] : children;

  for (let i = 0, len = children.length; i < len; ++i) {
    if (children[i] && children[i].props && children[i].props.tab) {
      panes.push({ ...children[i].props, ...{ children: children[i] } });
    }
  }

  const buildUri = (path) => (baseUri || '') + (path && path.indexOf('/') !== 0 ? '/' : '') + (path || '') || '#';

  return (
    <>
      <div className={'nav-wrapper' + (loading ? ' ' + translator.translate('loading') : '') + ' ' + className}>
        {showTabs !== false && (
          <Nav className={'nav-fill flex-column flex-md-row ' + className} id="tabs-icons-text" pills role="tablist">
            {panes.map(({ tab, path, icon }) => {
              const href = buildUri(path);
              const isActive = router.currentUri === href;
              const defaultClassName = classnames('mb-sm-3 mb-md-0', {
                'bg-default': isActive,
                'text-white': isActive
              });
              const weverClassName = `mb-sm-3 mb-md-0 ${className} ${isActive ? 'active' : ''}`;

              return (
                <NavItem key={href} className={className}>
                  <NavLink
                    role="tab"
                    href={href}
                    aria-selected={router.currentUri === href}
                    className={className ? weverClassName : defaultClassName}
                    onClick={(e) => {
                      e.preventDefault();
                      if (router.currentUri !== href && !loading) {
                        router.navigate(href);
                      }
                    }}
                  >
                    {typeof icon !== 'string' ? icon : <Icon name={icon} />}
                    {t(tab)}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        )}
      </div>
      <Card className="shadow card-shadow-card-content">
        <CardBody className={loading ? translator.translate('loading') : ''}>
          <TabContent activeTab={router.currentUri} className="tab-content">
            {panes.map(({ path, children }) => {
              const href = buildUri(path);

              return (
                <TabPane tabId={href} key={href}>
                  {children}
                </TabPane>
              );
            })}
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
};
