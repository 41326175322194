/** eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap';

// import IMG from '@/assets/img/common/dashboard.png';
import Icon from '@/components/common/icon';
// import Media from '@/components/common/media';
import router from '@/services/router';
import { t } from '@/services/translator';

export default ({ community /*, onSelect*/ }) => {
  const { name, /*logo, actions,*/ id } = community;

  let idCollapse = name.toLowerCase().trim().replace(/\s/g, '').replace(' ', '');
  idCollapse = idCollapse.replace('(', '').replace(')', '');

  const redirect = () => {
    router.navigate('/viz/gantts_charts/?community=' + id);
  };

  return (
    <div className="accordion-item wever">
      <h2 className="accordion-header" id={idCollapse}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${idCollapse}_collapse`}
          aria-expanded="true"
          aria-controls={`#${idCollapse}_collapse`}
        >
          {/* <span className="mr-4">{logo && <Media data={logo} width="50px" style={{ borderRadius: '50%' }} />}</span>{' '} */}
          {t(name || 'N/A')}
        </button>
      </h2>
      <div id={`${idCollapse}_collapse`} className="accordion-collapse collapse show" aria-labelledby={idCollapse}>
        <div className="accordion-body">
          <ListGroup className="managerMap">
            <ListGroupItem className="justify-content-between managerMapItem" onClick={() => redirect()}>
              <Row>
                <Col lg="6">{t('adminapp_gantt')}</Col>
                <Col lg="3" className="text-right d-flex justify-content-end align-items-center">
                  <span className="text-grey managerMapItemExplore">
                    {t('explore')} <Icon name="chevron-right" />
                  </span>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </div>
      </div>
    </div>
  );
};
