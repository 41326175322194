/* eslint-disable class-methods-use-this */
import api from '@/services/api';
import environment from '@/services/environment';
import notifications from '@/services/notifications';
import Resource from '@/services/resources/common';
import security from '@/services/security';

export default class DiagnosticResource extends Resource {
  constructor(entrypoint = '/diagnostics', alias = 'Diagnostic') {
    super(entrypoint, alias);
  }

  canCreate() {
    return false;
  }

  canUpdate() {
    return security.isGranted('ROLE_ADMIN') || security.isGranted('ROLE_SUPER_ADMIN');
  }

  list(filters, admin, extraParameters) {
    const isAdmin = security.isGranted('ROLE_ADMIN') || security.isGranted('ROLE_SUPER_ADMIN');

    if (admin === false || !isAdmin) {
      return this.managedList(false, extraParameters);
    }

    return super.list(filters);
  }

  async managedList(directResponses = true, community) {
    let filter = '';

    if (community) {
      const id = typeof community === 'object' ? community.id : community.replace('/communities/', '');
      filter = `?id=${id}`;
    }

    const response = await api.get(`${this.entrypoint}/manager${filter}`);

    return directResponses ? response.data['hydra:member'] : response.data;
  }

  update(requestData, ...args) {
    return api
      .put(`${this.buildUri(requestData.id)}/soft`, requestData, ...args)
      .then(({ data }) => data)
      .catch((e) => {
        if (environment.dev) {
          notifications.error('Error', `Unable to update ${this.alias} #${requestData.id} : ${e.message}`);
        }

        throw e;
      });
  }

  read(id, ...args) {
    return api
      .get(`${this.buildUri(id)}/soft`, ...args)
      .then(({ data }) => data)
      .catch((e) => {
        if (environment.dev) {
          notifications.error('Error', `Unable to read ${this.alias} #${id} : ${e.message}`);
        }

        throw e;
      });
  }
}
