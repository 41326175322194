/* eslint-disable global-require */

export default [
  {
    path: 'command_queu',
    name: 'command_queu',
    resource: 'CommandQueue',
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/command_queu').default,
        title: 'command_queu_list_title'
      }
    ]
  }
];
