import { useField } from 'formik';
import React, { useState } from 'react';
import { FormGroup, Table, Button, Row, Col, Spinner, Input } from 'reactstrap';

import Icon from '@/components/common/icon';
import _Api from '@/services/api';
import { t } from '@/services/translator';

import _Form from '@/components/form';
const { Error } = _Form;

const ManagersTable = () => {
  const [field, ...helper] = useField({ name: 'managers' });
  const [userToAdd, setUserToAdd] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAdd = async () => {
    setLoading(true);
    let values = field.value;

    if (!values || !Array.isArray(values)) {
      values = [];
    }

    // on va rechercher dans liste
    let data = await _Api.get(`/users/public?username=${userToAdd}`);
    let resp = data.data;
    // si aucun element on le créé
    if (resp && resp['hydra:totalItems'] === 0) {
      resp = {
        username: userToAdd,
        plainPassword: userToAdd,
        roles: ['ROLE_USER'],
        person: { id: null, firstname: 'firstname' }
      };
    } else {
      // eslint-disable-next-line prefer-destructuring
      resp = resp['hydra:member'][0];
    }

    if (!resp) {
      setLoading(false);

      return;
    }

    if (!resp.roles) {
      resp.roles = [];
    }

    if (resp.roles.indexOf('ROLE_MANAGER') === -1) {
      resp.roles.push('ROLE_MANAGER');
    }

    values.push(resp);

    helper[1].setValue(values);
    setUserToAdd('');
    setLoading(false);
  };

  const handleRemovePartner = (index) => {
    const values = field.value;

    if (!values || !Array.isArray(values)) {
      return;
    }

    values.splice(index, 1);

    helper[1].setValue(values);
  };

  return (
    <FormGroup>
      <Row className="my-2">
        <Col lg="6" md="8" sm="8">
          <Input
            type="text"
            value={userToAdd}
            placeholder="username"
            onChange={(e) => setUserToAdd(e.target.value)}
            disabled={loading}
            label={null}
          />
        </Col>
        <Col lg="6" md="8" sm="8">
          <Button size="sm" type="button" color="info" onClick={handleAdd} disabled={loading}>
            {loading ? <Spinner color="primary" size="sm" /> : t('communitiesapp_add_manager')}
          </Button>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>{t('manager')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {field.value &&
            field.value.map((manager, index) => {
              if (!manager) {
                return null;
              }

              return (
                <tr key={index}>
                  <td>
                    <a href={`/users/${manager.id}`}>{manager.username}</a>
                  </td>
                  <td>
                    <Button size="sm" color="danger" onClick={() => handleRemovePartner(index)}>
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <Error name="managers" />
    </FormGroup>
  );
};

export default ManagersTable;
