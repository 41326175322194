import React, { useState } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
// Style
import { Row, Col, FormGroup, Label, Button, Card, CardBody, CardTitle, CardFooter, Input as RInput } from 'reactstrap';
//Core
import { t } from '@/services/translator';
// Même dossier
import _Form from '@/components/form';
import Icon from '@/components/common/icon';
const { Input } = _Form;

const EnterprisesInput = () => {
  const [field, , helpersInputs] = useField({ name: `dataPrivate` });
  const [isAdding, setAdding] = useState();
  const [name, setName] = useState();
  const [valueInput, setValueInput] = useState();

  const addInput = () => {
    const old = (Array.isArray(field.value) ? {} : field.value) || {};
    old[name] = { name, value: valueInput };

    helpersInputs.setValue(old);
    setAdding(false);
  };

  const deleteInput = (index, key) => {
    if (!field.value[key]) {
      return;
    }
    const fields = { ...field.value };
    delete fields[key];

    helpersInputs.setValue(fields);
  };

  return (
    <div key={`extra-input`} className="px-2 my-2 bg-light py-2">
      <h5>{t('communitiesapp_custom_field_title')}</h5>
      {field.value &&
        Object.keys(field.value).length > 0 &&
        Object.keys(field.value).map((key, index) => {
          const input = field.value[key];
          return (
            <FormGroup row key={key}>
              <Label sm={4}>{t(input.name)} : </Label>
              <Col sm={6}>
                <Input label={null} name={`dataPrivate[${key}].value`} />
              </Col>
              <Col sm="2">
                <Button color="danger" size="sm" onClick={() => deleteInput(index, key)}>
                  <Icon name="trash" />
                </Button>
              </Col>
              <Col sm="2">
                <Button color="danger" size="sm" onClick={() => deleteInput(index)}>
                  <Icon name="trash" />
                </Button>
              </Col>
            </FormGroup>
          );
        })}
      <div className="mt-1">
        <Button
          type="button"
          className="shadow-none informationsheetBtncolor mx-auto mt-3 rounded-pill"
          onClick={() => setAdding(true)}
        >
          <Icon name="plus-circle" className="me-4" />
          {t('communitiesapp_add_input')}
        </Button>
      </div>
      {isAdding && (
        <div className="modal-add-input d-flex justify-content-center align-items-center">
          <Card>
            <CardTitle>
              <Row className="m-0 border-bottom py-2">
                <Col className="d-flex justify-content-start align-items-center">
                  <h5>{t('communitiesapp_add_input_title')}</h5>
                </Col>
                <Col className="col-2 d-flex justify-content-end align-items-center">
                  <Button color="danger" onClick={() => setAdding(false)}>
                    <Icon name="times" />
                  </Button>
                </Col>
              </Row>
            </CardTitle>
            <CardBody>
              {t('communtiesapp_label_name_input')}
              <br />
              <RInput onChange={(e) => setName(e.target.value)} /> <br />
              <br />
              {t('communtiesapp_label_value_input')} <br />
              <RInput onChange={(e) => setValueInput(e.target.value)} />
            </CardBody>
            <CardFooter className="d-flex p-2 justify-content-end align-items-center">
              <Button
                type="button"
                className="shadow-none informationsheetBtncolor mt-3 rounded-pill"
                onClick={addInput}
              >
                {t('communitiesapp_validate_input')}
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
    </div>
  );
};

EnterprisesInput.propTypes = {
  idx: PropTypes.number
};

export default EnterprisesInput;
