import React, { useContext } from 'react';
import PropTypes from 'prop-types';

export const InformationSheetContext = React.createContext();

// -------- CLES PERMETTANT LA MISE A JOUR DE VOS DONNEES ------
export const COMMUNITY_ID = 'communityId';

// Votre context principal
const InformationSheetProvider = (props) => {
  const { communityId, children } = props;

  return <InformationSheetContext.Provider value={communityId}>{children}</InformationSheetContext.Provider>;
};

InformationSheetProvider.propTypes = {
  children: PropTypes.element.isRequired,
  communityId: PropTypes.number
};

InformationSheetProvider.defaultProps = {
  communityId: null
};

export const useInformationSheet = () => useContext(InformationSheetContext);
export default InformationSheetProvider;
