import { Formik } from 'formik';
import React, { useState } from 'react';
import { Col, Row, Card, CardBody, Button, Form, Spinner } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Editor } from '@/components/form';
import View from '@/components/view';
import notifications from '@/services/notifications';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

import queries from './queries';

export default () => {
  const [query, setQuery] = useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const [isSubmittingPreview, setIsSubmittingPreview] = useState(false);

  const getPreview = async (query) => {
    setIsSubmittingPreview(true);
    let response = null;
    try {
      response = await getResource('User').exportUsersAndTokenActionPreview(query);
      setUsersCount(response.data.usersCount);
    } catch (e) {
      notifications.error(t('download'), t('error_cannot_fetch_resource'));
      setUsersCount(0);
    }
    setIsSubmittingPreview(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    let response = null;
    try {
      let param = values.query;
      if (param === '') {
        param = query;
      }
      response = await getResource('User').exportUsersAndTokens(param);
    } catch (e) {
      notifications.error(t('download'), t('error_cannot_fetch_resource'));
      return;
    }
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export-users-token-csv.zip');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setSubmitting(false);
  };

  return (
    <View>
      <Card style={{ height: '100%' }}>
        <CardBody style={{ position: 'relative', overflow: 'auto', padding: 0 }}>
          <Formik initialValues={{ query: '' }} onSubmit={handleSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Editor
                      height="500"
                      name="query"
                      label={null}
                      value={query}
                      onChange={(value) => {
                        setQuery(value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h4 className="text-right mr-4">
                      {t('users_count')} : {usersCount}
                    </h4>
                    <Button
                      disabled={isSubmittingPreview}
                      name="preview"
                      className="mr-4 float-right"
                      color="primary"
                      onClick={() => getPreview(query)}
                    >
                      {isSubmittingPreview ? <Spinner size="sm mr-2" /> : <Icon name="download" className="mr-2" />}
                      {t('get_preview')}
                    </Button>
                  </Col>
                </Row>
                {queries.queries.map(function (element, i) {
                  return (
                    <Row key={i}>
                      <Col md={12}>
                        <Button
                          name={element.name}
                          className="m-2"
                          color="info"
                          onClick={() => setQuery(JSON.stringify(element.query, null, 2))}
                        >
                          {t(element.name)}
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
                <Button disabled={isSubmitting} type="submit" color="primary" className="m-2">
                  {isSubmitting ? <Spinner size="sm" /> : <Icon name="download" className="mr-2" />}
                  {t('export_token')}
                </Button>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </View>
  );
};
