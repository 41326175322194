/* eslint-disable react-hooks/exhaustive-deps */
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup } from 'reactstrap';

import { Error, Label } from '@/components/form';
import api from '@/services/api';
import Context from '@/services/context';
import Logger from '@/services/logger';

// import { t } from '@/services/translator';

const TagAutocomplete = (props) => {
  // props
  const {
    // display,
    name,
    size,
    multiple,
    hasLabel = true,
    onChange,
    placeholder,
    // defaultOption,
    groupProps,

    className = 'w-100'
  } = props;
  const { locale } = useContext(Context);
  // hooks
  const [field, meta, fieldHelper] = useField({ name });
  const { submitCount } = useFormikContext();
  // state
  const [values, setValues] = useState();
  const [timer, setTimer] = useState();
  // var
  const invalid = name ? (meta.touched || submitCount > 0) && meta.error !== undefined : false;

  /**
   * Format value or received options (from api)
   * used in fetchOptions and useEffect.
   *
   * @param {array} collection
   */
  // const formatCollectionToOptions = async (collection, isMultiple, isSettingValue = false) => {
  //   if (!Array.isArray(collection) && (multiple || isMultiple)) {
  //     collection = [collection];
  //   }

  //   let response = null;
  //   if (multiple || isMultiple) {
  //     const objCollection = [];

  //     if (defaultOption) {
  //       objCollection.push(defaultOption);
  //     }

  //     for (let i = 0; i < collection.length; i++) {
  //       if (typeof collection[i] === 'object') {
  //         let entity = collection[i];

  //         if (!entity[display] && entity['@id']) {
  //           response = await api.get(entity['@id']);
  //           entity = response.data;
  //         }

  //         let labelToDisplay = entity[display];

  //         if (typeof labelToDisplay === 'object') {
  //           labelToDisplay = labelToDisplay[locale];
  //         }

  //         objCollection.push({ label: labelToDisplay, value: entity });
  //       } else {
  //         response = await api.get(collection[i]);

  //         if (response.data) {
  //           let labelToDisplay = response.data;
  //           if (typeof labelToDisplay === 'object') {
  //             labelToDisplay = response.data[locale];
  //           }

  //           objCollection.push({ label: labelToDisplay, value: response.data });
  //         }
  //       }
  //     }

  //     if (isSettingValue) {
  //       setValues(objCollection);
  //       return;
  //     }

  //     return objCollection;
  //   }

  //   let obj = collection;

  //   if (typeof collection !== 'object') {
  //     response = await api.get(collection);

  //     if (response.data) {
  //       obj = response.data;
  //     }
  //   }

  //   let labelToDisplay = obj[display];
  //   if (typeof labelToDisplay === 'object') {
  //     labelToDisplay = labelToDisplay[locale];
  //   }

  //   if (isSettingValue) {
  //     setValues({ label: t(labelToDisplay || ''), value: obj });
  //   }

  //   return { label: t(labelToDisplay || ''), value: obj };
  // };

  /**
   * @param {string} terms
   */
  const fetchOptions = async (terms) => {
    try {
      const response = await api.get('/tags/v2', { alias: terms });
      const responseData = response.data;
      const tmpOptions = [...responseData['hydra:member']];
      const allOptions = [];

      tmpOptions.forEach((opt) => {
        if (opt.alias) {
          allOptions.push(opt);
        }
      });

      allOptions.sort(function (a, b) {
        let aName = a['names'][locale] || '';
        let bName = b['names'][locale] || '';

        aName = aName.toLowerCase();
        bName = bName.toLowerCase();

        if (aName < bName) {
          return -1;
        } else if (aName > bName) {
          return 1;
        }

        return 0;
      });

      return allOptions.map((option) => option.alias && { value: option.alias, label: option.names[locale] });
    } catch (e) {
      Logger.error('Autocomplete', 'option', e);
      return [];
    }
  };

  /**
   * @param {string} value
   */
  const loadAsyncOptions = (terms) => {
    clearTimeout(timer);

    return new Promise((resolve) => {
      const newTimer = setTimeout(() => {
        resolve(fetchOptions(terms));
      }, 500);

      setTimer(newTimer);
    });
  };

  /**
   * @param {array} options
   */
  const handleOnChange = (options) => {
    if (!options) {
      return;
    }
    let optionsToValue = [];

    if (multiple) {
      options.forEach((option) => {
        if (option.value) {
          optionsToValue.push(option.value);
        }
      });
    }

    setValues(options);

    if (onChange && typeof onChange === 'function') {
      onChange(optionsToValue, options);
    }

    if (name) {
      console.log(optionsToValue);
      fieldHelper.setValue(optionsToValue);
    }
  };

  const initialize = async () => {
    if (!field.value || !field.value.length > 0) {
      return;
    }

    const options = [];

    for (let i = 0; i < field.value.length; i++) {
      const val = field.value[i];
      const fetchedOptions = await fetchOptions(val);

      fetchedOptions.forEach((option) => {
        if (option.value === val) {
          options.push({ value: val, label: option.label });
        }
      });
    }

    setValues(options);
  };

  // load values with existing one.
  useEffect(() => {
    if (!field || !field.value) {
      return;
    }

    initialize();
  }, []);

  return (
    <FormGroup {...groupProps} className={className}>
      {name && hasLabel && (
        <Label name={name} size={size}>
          {name}
        </Label>
      )}
      <AsyncSelect
        isMulti
        isClearable
        defaultOption
        cacheOptions
        loadOptions={loadAsyncOptions}
        invalid={invalid}
        onChange={handleOnChange}
        value={values}
        onBlur={() => name && fieldHelper.setTouched(true)}
        className="autocomplete-async"
        placeholder={placeholder}
        defaultOptions
      />
      {name && <Error name={name} />}
    </FormGroup>
  );
};

TagAutocomplete.propTypes = {
  name: PropTypes.string
};

export default TagAutocomplete;
