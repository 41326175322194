import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'Sheet-action',
    icon: <Icon name="file-alt" className="iconRightMarginMid" />,
    roles: ['ROLE_ADMIN'],
    items: [
      {
        label: 'list',
        route: 'sheet_actions_list',
        roles: ['ROLE_ACCOUNT_MANAGER']
      },
      {
        label: 'create',
        route: 'sheet_actions_create',
        roles: ['ROLE_ACCOUNT_MANAGER']
      },
      {
        label: 'Category',
        icon: <Icon name="pager" className="iconRightMarginMid" />,
        roles: ['ROLE_ADMIN'],
        items: [
          {
            label: 'list',
            route: 'category_list',
            roles: ['ROLE_ADMIN']
          },
          {
            label: 'create',
            route: 'category_create',
            roles: ['ROLE_ADMIN']
          }
        ]
      }
    ]
  }
];
