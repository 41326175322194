/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import Maps from 'wever-maps';
import 'wever-maps/src/assets/style.scss';

import useCommunity from '@/components/hook/use-community';
import View from '@/components/view';
import Context from '@/services/context';
import getResource from '@/services/resources';
import security from '@/services/security';
import { t } from '@/services/translator';

export default () => {
  const { community, isLoadingCommunity, setLoading, Loader, loadCommunity } = useCommunity();
  const { locale, editionMode } = useContext(Context);
  const [id, setId] = useState(null);
  const [reportId, setReportId] = useState(null);
  const [access, setAccess] = useState(false);
  const refCard = useRef();

  const checkAccessFront = async () => {
    setLoading(true);

    const list = await getResource('Community').managedList(true, community, false);

    if (list[0] && list[0].id === community.id) {
      list[0].maps.forEach((map) => {
        if (map.id === id) {
          setAccess(true);
        }
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setId(parseInt(urlParams.get('id'), 10));
    const communityIdParams = urlParams.get('community');
    if (communityIdParams) {
      loadCommunity(parseInt(communityIdParams, 10));
    }

    setReportId(parseInt(urlParams.get('report'), 10));
  }, [locale]);

  useEffect(() => {
    if (!community) {
      if (security.isAdmin()) {
        setAccess(true);
      }
      return;
    }

    if (!security.isAdmin()) {
      setAccess(false);
    }

    const report = community.report?.replace('/reports/', '');
    setReportId(report ? parseInt(report) : null);
    checkAccessFront();
  }, [community]);

  if (!id) {
    return (
      <div>
        {' '}
        {`[In progress] missing map id and report id in url. "?id=:number&report=:number" (example -> id=3&report=1)`}{' '}
      </div>
    );
  }

  if (isLoadingCommunity) {
    return <Loader />;
  }

  return (
    <View>
      <Card style={{ height: '100%' }} ref={refCard}>
        <CardBody style={{ position: 'relative', overflow: 'auto', padding: 0 }}>
          <Link className="ml-2 bg-darkpink rounded-pill px-2" to="/viz/map_list">
            {t('back')}
          </Link>
          {!reportId || !access ? (
            <div className="w-100 text-center">{t('adminapp_no_resource_for_this_community')}</div>
          ) : (
            <Maps
              token={security.token}
              locale={locale}
              mapId={id}
              reportId={reportId}
              editionMode={editionMode}
              CoreContext={Context}
            />
          )}
        </CardBody>
      </Card>
    </View>
  );
};
