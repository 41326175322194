import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Table, DropdownItem } from 'reactstrap';

import Date from '@/components/common/date';
import Icon from '@/components/common/icon';
import { Datepicker, Input, Checkbox } from '@/components/form';
import ResourceListAdmin from '@/components/resources/list';
import View from '@/components/view';
import Context from '@/services/context';
import security from '@/services/security';
import { t } from '@/services/translator';

export default () => {
  const { community } = useContext(Context);
  const isManager = !security.isGranted('ROLE_ADMIN') && !security.isGranted('ROLE_SUPER_ADMIN');

  useEffect(() => {}, [community]);

  const redirectAction = (item) => {
    if (isManager) {
      return null;
    }

    return (
      <DropdownItem>
        <Link to={`/diagnostics/editor?id=${item.id}`}>
          <Icon name="directions" className="iconRightMarginMax" /> {t('editor')}
        </Link>
      </DropdownItem>
    );
  };

  return (
    <View>
      <ResourceListAdmin
        resource="Diagnostic"
        extraParameters={community}
        deleteMultiple
        filters={() => (
          <Row>
            <Col lg={3}>
              <Input name="alias" />
            </Col>
            <Col lg={3}>
              <Checkbox name="monoBloc" />
            </Col>
            {/* <Col lg={3}>
              <Resource name="community" display="name"  resource="Community" clearable />
            </Col> */}
            <Col lg={3}>
              <Datepicker name="['startDate[after]']" label="diagnostic.start_date.after" />
            </Col>
            <Col lg={3}>
              <Datepicker name="['startDate[before]']" label="diagnostic.start_date.before" />
            </Col>
            <Col lg={3}>
              <Datepicker name="['endDate[after]']" label="diagnostic.end_date.after" />
            </Col>
            <Col lg={3}>
              <Datepicker name="['endDate[before]']" label="diagnostic.end_date.before" />
            </Col>
          </Row>
        )}
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                {renderColumn(renderSelect('*'), false)}
                {renderColumn('id')}
                {renderColumn('monoBloc')}
                {renderColumn('alias')}
                {renderColumn('dateStart')}
                {renderColumn('dateEnd')}
                {renderColumn()}
              </tr>
            </thead>
            <tbody>
              {collection.map((item) => (
                <tr key={item.id}>
                  <td>{renderSelect(item.id)}</td>
                  <td>{item.id}</td>
                  <td>{item.monoBloc ? <Icon name="check" /> : null}</td>
                  <td>{item.alias}</td>
                  <td>
                    <Date>{item.startDate || ''}</Date>
                  </td>
                  <td>
                    <Date>{item.endDate || ''}</Date>
                  </td>
                  <td>{!isManager && renderItemActions(item, redirectAction(item))}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </View>
  );
};
