import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_geometry',
    path: '',
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', '!ROLE_TRAD_DIAGNOSTIC', '!ROLE_MANAGER'],
    icon: <Icon name="file-alt" className="iconRightMarginMid" />,
    items: [
      {
        label: 'adminapp_geometry_type',
        path: '/geometry_type',
        icon: <Icon name="file-alt" className="iconRightMarginMid" />
      },
      {
        label: 'adminapp_geometry_group',
        path: '/geometry_group',
        icon: <Icon name="calendar-alt" className="iconRightMarginMid" />
      },
      {
        label: 'adminapp_geometry_line',
        path: '/geometry_line',
        icon: <Icon name="calendar-alt" className="iconRightMarginMid" />
      },
      {
        label: 'adminapp_geometry_polygon',
        path: '/geometry_polygon',
        icon: <Icon name="calendar-alt" className="iconRightMarginMid" />
      },
      {
        label: 'adminapp_geometry_point',
        path: '/geometry_point',
        icon: <Icon name="calendar-alt" className="iconRightMarginMid" />
      }
    ]
  }
];
