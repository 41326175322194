import React from 'react';
import { Spinner } from 'reactstrap';

import Favicon from '@/assets/img/brand/favicon.png';

export default ({ height, color = 'primary' }) => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
      <div>
        <img src={Favicon} height={height || '30px'} alt="loading" />
      </div>
      <div className="mt-1 pt-1">
        <Spinner color={color} size="sm" />
      </div>
    </div>
  );
};
