import React from 'react';
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap';

// import IMG from '@/assets/img/common/dashboard.png';
import Icon from '@/components/common/icon';
// import Media from '@/components/common/media';
import router from '@/services/router';
import { t } from '@/services/translator';

export default ({ community /*, onSelect*/ }) => {
  const { name, /*logo,*/ sheetActions } = community;

  let idCollapse = name.toLowerCase().trim().replace(/\s/g, '').replace(' ', '');
  idCollapse = idCollapse.replace('(', '').replace(')', '');

  const redirect = (id) => {
    router.navigate('/sheet_actions/view/' + id + '?community=' + community.id);
  };

  return (
    <div className="accordion-item wever">
      <h2 className="accordion-header" id={idCollapse}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${idCollapse}_collapse`}
          aria-expanded="true"
          aria-controls={`#${idCollapse}_collapse`}
        >
          {/* <span className="mr-4">{logo && <Media data={logo} width="50px" style={{ borderRadius: '50%' }} />}</span>{' '} */}
          {t(name || 'N/A')}
        </button>
      </h2>
      <div id={`${idCollapse}_collapse`} className="accordion-collapse collapse show" aria-labelledby={idCollapse}>
        <div className="accordion-body">
          <ListGroup className="managerMap">
            {Array.isArray(sheetActions) &&
              sheetActions.map((element) => {
                const sheetActionId = element.sheetAction.replace('/sheet_actions/', '');

                return (
                  <ListGroupItem
                    className="justify-content-between managerMapItem"
                    onClick={() => redirect(sheetActionId)}
                    key={element.id}
                  >
                    <Row>
                      <Col lg="8">{t(element.name || 'N/A')}</Col>
                      <Col lg="4" className="text-right d-flex justify-content-end align-items-center">
                        <span className="text-grey managerMapItemExplore">
                          {t('explore')} <Icon name="chevron-right" />
                        </span>
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              })}
          </ListGroup>
        </div>
      </div>
    </div>
  );
};
