/* eslint-disable global-require */

export default [
  {
    path: 'structure_types',
    name: 'structure_types',
    resource: 'StructureType',
    roles: ['ROLE_ACCOUNT_MANAGER'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/data/structure/list').default,
        title: 'structure_types_list',
        permissions: {
          StructureType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/data/structure/form').default,
        title: 'structure_types_create',
        roles: ['ROLE_MANAGER'],
        permissions: {
          StructureType: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/data/structure/form').default,
        title: 'structure_types_update',
        permissions: {
          StructureType: ['read', 'update']
        }
      },
      {
        path: '/:id',
        name: 'delete',
        component: require('@/views/data/structure/form').default,
        title: 'structure_types_delete',
        permissions: {
          StructureType: ['delete']
        }
      }
    ]
  },
  {
    path: 'structure_activities',
    name: 'structure_activities',
    resource: 'StructureActivity',
    roles: ['ROLE_ACCOUNT_MANAGER'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/data/activity/list').default,
        title: 'structure_activities_list',
        permissions: {
          StructureActivity: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/data/activity/form').default,
        title: 'structure_activities_create',
        roles: ['ROLE_MANAGER'],
        permissions: {
          StructureActivity: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/data/activity/form').default,
        title: 'structure_activities_update',
        permissions: {
          StructureActivity: ['read', 'update']
        }
      },
      {
        path: '/:id',
        name: 'delete',
        component: require('@/views/data/activity/form').default,
        title: 'structure_activities_delete',
        permissions: {
          StructureActivity: ['delete']
        }
      }
    ]
  }
];
