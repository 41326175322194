/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Dashboard from 'wever-dashboard';
import 'wever-dashboard/dist/styles/bootstrap.css';

import useCommunity from '@/components/hook/use-community';
import Context from '@/services/context';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

export default (props) => {
  const { onClose, name, report, dashboard, communityId } = props;
  const { community, isLoadingCommunity, Loader } = useCommunity(communityId);
  const [reportId, setReport] = useState(null);
  const [loading, setLoading] = useState(null);
  const { locale } = useContext(Context);

  const loadFromCommunityChanged = async () => {
    setLoading(true);
    const list = await getResource('Community').managedDashboard(true, community, false);
    let isFound = false;

    if (list[0] && list[0].dashboards) {
      list[0].dashboards.forEach((element) => {
        if (element.id === dashboard) {
          isFound = true;
        }
      });
    }

    if (isFound) {
      setReport(community.report?.replace('/reports/', ''));
    } else {
      setReport(null);
    }

    setLoading(false);
  };

  const getName = () => {
    if (typeof name === 'string') {
      return name;
    }

    return name[locale] || '';
  };

  useEffect(() => {
    if (community) {
      loadFromCommunityChanged();
    } else {
      setReport(report);
    }
  }, [community, locale]);

  if (loading || isLoadingCommunity) {
    return <Loader color="info" />;
  }

  return (
    <Row>
      <Col className="col-4 pl-2 pt-2 d-flex justify-content-start align-items-center">
        <Button className="ml-2 bg-darkpink rounded-pill" onClick={() => onClose()}>
          {t('back')}
        </Button>
      </Col>
      <Col className="col-8 d-flex justify-content-center align-items-center">{getName()}</Col>
      <Col className="col-12">
        {reportId ? (
          <Dashboard editionMode {...props} locale={locale} report={parseInt(reportId.replace('undefined', ''))} />
        ) : (
          <div className="w-100 text-center">{t('adminapp_no_resource_for_this_community')}</div>
        )}
      </Col>
    </Row>
  );
};
