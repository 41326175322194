import api from '@/services/api';
import environment from '@/services/environment';
import Mapping from '@/services/mapping/mapping';
import notifications from '@/services/notifications';
import security from '@/services/security';
import { t } from '@/services/translator';
import string from '@/services/utils/string';

export default class Resource {
  mappings = {};
  entrypoint = null;
  entrypointSecondary = null;

  constructor(entrypoint = '', alias = 'common') {
    this.alias = alias;
    this.entrypoint = entrypoint;
  }

  async autocompleteOnContent(filters) {
    let strFilter = '';
    if (filters && typeof filters === 'object') {
      Object.keys(filters).forEach((key, index) => {
        if (index > 0) {
          strFilter += '&';
        }

        strFilter += `${key}=${filters[key]}`;
      });
    }

    const response = await api.get(`/autocompletes/${this.alias}?${strFilter}`);

    return response.data;
  }

  /**
   * Permet de customiser le retour à la liste (navigation)
   */
  navigateToList() {
    return false;
  }

  /**
   * List a collection of resource
   *
   * @param {?object} filters
   * @param {array}   args
   *
   * @return {Promise}
   */
  list(filters, ...args) {
    return api
      .get(this.entrypoint, filters, ...args)
      .then(({ data }) => data)
      .catch((e) => {
        if (environment.dev) {
          notifications.error('Error', `Unable to fetch list ${this.alias} : ${e.message}`);
        }

        throw e;
      });
  }

  /**
   * Create a resource item
   *
   * @param {?object} data
   * @param {array}   args
   *
   * @return {Promise}
   */
  create(requestData, ...args) {
    requestData = this.formatRequest(requestData);

    return api
      .post(this.entrypoint, requestData, ...args)
      .then(({ data }) => data)
      .catch((e) => {
        if (environment.dev) {
          notifications.error('Error', `Unable to create ${this.alias} : ${e.message}`);
        }

        throw e;
      });
  }

  /**
   * Create a resource item
   *
   * @param {?object} data
   * @param {array}   args
   *
   * @return {Promise}
   */
  update(requestData, ...args) {
    // requestData = this.formatRequest(requestData);

    return api
      .put(this.buildUri(requestData.id), requestData, ...args)
      .then(({ data }) => data)
      .catch((e) => {
        if (environment.dev) {
          notifications.error('Error', `Unable to update ${this.alias} #${requestData.id} : ${e.message}`);
        }

        throw e;
      });
  }

  /**
   * Read a resource item
   *
   * @param {string|number} id
   * @param {array}         args
   *
   * @return {Promise}
   */
  read(id, ...args) {
    return api
      .get(this.buildUri(id), ...args)
      .then(({ data }) => data)
      .catch((e) => {
        if (environment.dev && this.alias !== 'Media') {
          notifications.error('Error', `Unable to read ${this.alias} #${id} : ${e.message}`);
        }

        throw e;
      });
  }

  /**
   * Delete a resource item
   *
   * @param {string|number|object} id
   * @param {array}                args
   *
   * @return {Promise}
   */
  delete(id, ...args) {
    if (typeof id !== 'number' && typeof id !== 'string') {
      return this.deleteMultiple(id, ...args);
    }

    return api.delete(this.buildUri(id), ...args).catch((e) => {
      if (environment.dev) {
        notifications.error('Error (dev mode)', `Unable to delete ${this.alias} #${id} : ${e.message}`);
      }

      if (e.response && e.response.data && e.response.data.message) {
        notifications.warning(t('Error'), t(e.response.data.message), null, null, 6500);
      }
    });
  }

  /**
   * Delete a collection of resources
   * Caution : this is a custom action out of REST context
   *
   * @param {object} params
   * @param {array}  args
   *
   * @return {Promise}
   */
  deleteMultiple(params, ...args) {
    params.perPage = params.id ? params.id.length : 1000;

    return api.get(this.buildUri('delete'), params, ...args).catch((e) => {
      if (environment.dev) {
        notifications.error('Error', `Unable to delete collection of ${this.alias} : ${e.message}`);
      }

      throw e;
    });
  }

  /**
   * Fetch mapping and initialize Mapping class
   *
   * @param {string}  alias
   * @param {string}  method
   * @param {?string} uri
   *
   * @return {Promise}
   */
  fetchMapping(alias, method, uri) {
    if (this.mappings[alias]) {
      return Promise.resolve(this.mappings[alias]);
    }

    const uriData = ['mapping'];
    if (uri) {
      uriData.unshift(uri);
    }

    return api[method](this.buildUri(...uriData))
      .then(({ data }) => {
        this.mappings[alias] = new Mapping(data);
        return data;
      })
      .catch((e) => {
        notifications.error('Oops', 'Unable to fetch mapping definition');
        throw e;
      });
  }

  /**
   * Get mapping instance
   *
   * @param {string} alias
   *
   * @return {?Mapping}
   */
  getMapping(alias) {
    return this.mappings[alias];
  }

  /**
   * Build uri of action with entrypoint
   *
   * @param {string[]} uri
   *
   * @return {string}
   */
  buildUri(...uri) {
    return `${this.entrypoint}/${uri.join('/')}`;
  }

  /**
   * Can create
   *
   * @return {boolean|null}
   */
  canCreate() {
    return security.hasPermission(this.alias, 'create');
  }

  /**
   * Can read
   *
   * @return {boolean|null}
   */
  canRead() {
    return security.hasPermission(this.alias, 'read');
  }

  /**
   * Can read list
   *
   * @return {boolean|null}
   */
  canReadList() {
    return security.hasPermission(this.alias, 'read:list');
  }

  /**
   * Can update
   *
   * @return {boolean|null}
   */
  canUpdate() {
    return security.hasPermissions({ [this.alias]: ['read', 'update'] });
  }

  /**
   * Can delete
   *
   * @return {boolean|null}
   */
  canDelete() {
    return security.hasPermission(this.alias, 'delete');
  }

  /**
   * Can delete multiple
   *
   * @return {boolean|null}
   */
  canDeleteMultiple() {
    return security.hasPermission(this.alias, 'delete:multiple');
  }

  /**
   * Fetch property description in api
   *
   * @param {string} name
   *
   * @return {Promise}
   */
  fetchPropertyDescription(name) {
    return api
      .get(this.buildUri('property'), { name })
      .then(({ data }) => data)
      .catch(() => {
        notifications.error('Oops', `Unable to fetch definition of "${name}" for alias "${this.alias}"`);
      });
  }

  /**
   * Fetch property options in api
   *
   * @param {string} name
   *
   * @return {Promise}
   */
  fetchOptions(name) {
    return new Promise((resolve) => {
      this.fetchPropertyDescription(name).then((description) => {
        if (description && Array.isArray(description.constraints)) {
          for (let i = 0, len = description.constraints.length; i < len; ++i) {
            if (description.constraints[i].alias === 'Choice') {
              const data = description.constraints[i].choices.map((value) => ({ label: t(value), value }));
              resolve(data);
            }
          }
        } else {
          resolve([]);
        }
      });
    });
  }

  /**
   * Get full translation key for a property
   *
   * @param {string} propertyPath
   *
   * @return {*}
   */
  getTranslationKey(propertyPath) {
    return string.camelCaseToSnakeCase(`${string.lcfirst(this.alias)}.${propertyPath}`);
  }

  // eslint-disable-next-line class-methods-use-this
  formatCollectionData(collection) {
    return collection.map((element, index) => {
      if (typeof element === 'object' && element['@id']) {
        collection[index] = element['@id'];
        return true;
      }

      return false;
    });
  }

  formatObjectData(data) {
    Object.keys(data).map((key) => {
      if (!data[key] || key.indexOf('action') === 0) {
        return false;
      }

      if (typeof data[key] === 'object') {
        if (data[key]['@id']) {
          let hasObject = false;
          Object.keys(data[key]).forEach((subKey) => {
            if (typeof data[key][subKey] === 'object') {
              hasObject = true;
            }
          });

          if (!hasObject) {
            data[key] = data[key]['@id'];
          }
        } else {
          return this.formatObjectData(data[key]);
        }
      }

      // For each attribute of the entity
      if (Array.isArray(data[key])) {
        this.formatCollectionData(data[key]);
      }

      return true;
    });
  }

  /**
   * Remets en place pour les entités liés leur URIs (si elle existe)
   * @todo Check for each field the groups.
   */
  formatRequest(data) {
    this.formatObjectData(data);

    return data;
  }
}
