import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_command_queu',
    icon: <Icon name="play" className="text-info iconRightMarginMax" />,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    path: 'command_queu'
  }
];
