import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_diagnostic_expected_response_pattern_list',
    icon: <Icon name="file-alt" className="iconRightMarginMid" />,
    path: '/translator/expected_response_patterns',
    roles: ['!ROLE_SUPER_ADMIN', '!ROLE_MANAGER', 'ROLE_TRAD_DIAGNOSTIC']
  },
  {
    label: 'adminapp_menu_diagnostic_question_pattern_list',
    icon: <Icon name="file-alt" className="iconRightMarginMid" />,
    path: '/translator/question_patterns',
    roles: ['!ROLE_SUPER_ADMIN', '!ROLE_MANAGER', 'ROLE_TRAD_DIAGNOSTIC']
  }
];
