import React from 'react';

import Context from '@/services/context';

export const TranslateContent = ({ object }) => {
  if (typeof object !== 'object') {
    return null;
  }
  const keys = Object.keys(object);

  return <Context.Consumer>{({ locale }) => object[locale] || object[keys[0]]}</Context.Consumer>;
};

export default (obj) => <TranslateContent object={obj} />;
