import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_eligibiliy_rules',
    icon: <Icon name="building" className="text-pink iconRightMarginMax" />,
    roles: ['ROLE_ACCOUNT_MANAGER'],
    items: [
      {
        label: 'adminapp_menu_eligibiliy_rules_list',
        route: 'eligibility_rules_list'
      },
      {
        label: 'adminapp_menu_eligibiliy_rules_create',
        route: 'eligibility_rules_create'
      }
    ]
  }
];
