import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, Row, Col, Container } from 'reactstrap';

import LoginForm from '@/components/security/login';
import notifications from '@/services/notifications';
import security from '@/services/security';
import { t } from '@/services/translator';

const Login = ({ location, history }) => (
  <Container className="mt--8 pb-5">
    <Row className="justify-content-center">
      <Col lg="5" md="7">
        <p className="text-center">{t('connexion_label')}</p>
        <Card className="bg-white shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <LoginForm
              onSuccess={() => {
                let isTranslator = security.isGranted('ROLE_TRAD_DIAGNOSTIC');
                let isSuperAdmin = security.isGranted('ROLE_SUPER_ADMIN');
                if (
                  !security.isGranted('ROLE_MANAGER') &&
                  !security.isGranted('ROLE_ACCOUNT_MANAGER') &&
                  !isTranslator
                ) {
                  notifications.error(t('oops'), t('required_role_missing'));
                  return false;
                }

                const pathname = isTranslator && !isSuperAdmin ? 'translator/expected_response_pattern' : 'communities';

                history.push({
                  pathname,
                  state: { from: 'login' }
                });

                return true;
              }}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

Login.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.any
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default Login;
