import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Resource
import getResource from '@/services/resources';
// Style
import { FormGroup, Col, Label } from 'reactstrap';
// Core
import { t } from '@/services/translator';
import _Security from '@/services/security';

import Sites from './information-sheet-sites';
import MoreField from './information-sheet-more-field';

import _Form from '@/components/form';
const { Input, Editor, Select, Autocomplete, Resource } = _Form;

const General = (props) => {
  // const { enterpriseCapacity, sitesLength } = props;
  // State
  const [referent, setReferent] = useState();

  // Fonction pour afficher les communautés référentes
  const fetchReferent = async () => {
    if (_Security.isGranted('ROLE_ADMIN')) {
      const responses = await getResource('Community').list();
      const list = [];
      responses['hydra:member'].map((response) => {
        list.push({ value: response.name, label: response.name });
        return null;
      });

      setReferent(list);
    }
  };

  useEffect(() => {
    fetchReferent();
  }, []);

  return (
    <div className="mt-5">
      {!_Security.isGranted('ROLE_ADMIN') ? null : (
        <FormGroup row>
          <Label sm={2}>{t('communitiesapp_reference_community')} :</Label>
          <Col sm={3}>
            {/* <Select name="referent" label={null} options={referent} className="informationsheetSelectinput" /> */}
            <Autocomplete
              name="parent"
              clearable
              resource="Community"
              display="name"
              tag="name"
              autoload
              lablel={null}
            />
          </Col>
        </FormGroup>
      )}

      <FormGroup row>
        <Label sm={2}>{t('communitiesapp_client_name')} :</Label>
        <Col sm={3}>
          <Input label={null} name="name" />
        </Col>
      </FormGroup>
      {/* ADMIN PART */}
      {_Security.isGranted('ROLE_ADMIN') ? (
        <div className="bg-light">
          <FormGroup row>
            <Label sm={2}>{t('communitiesapp_biling_name')} :</Label>
            <Col sm={3}>
              <Input label={null} name="information.biling" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>{t('communitiesapp_shape')} :</Label>
            <Col sm={7}>
              <Editor name="information.shape" label={null} height="200" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>{t('communitiesapp_shape_color')} :</Label>
            <Col sm={3}>
              <Input type="color" label={null} name="information.shape_color" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>{t('communitiesapp_styles')} :</Label>
            <Col sm={7}>
              <Editor name="information.styles" label={null} height="200" />
            </Col>
          </FormGroup>
        </div>
      ) : null}

      {/*  A rajouter plus tard
      
      {enterpriseCapacity === 0 ? null : (
        <FormGroup row>
          <Label sm={2}>{t('communitiesapp_site_nb_workforce')} :</Label>
          <Col sm={3}>
            <p className="form-control-plaintext">{enterpriseCapacity}</p>
          </Col>
        </FormGroup>
      )}

      {sitesLength === 0 ? null : (
        <FormGroup row>
          <Label sm={2}>{t('communitiesapp_site_number')} :</Label>
          <Col sm={3}>
            <Input label={null} name="numberOfSites" disabled />
          </Col>
        </FormGroup>
      )} */}
      <FormGroup row>
        <Label sm={2}>{t('communitiesapp_sector_of_activity')} :</Label>
        <Col sm={3}>
          <Input label={null} name="information.activitySector" />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>{t('communitiesapp_langue')} :</Label>
        <Col sm={3}>
          <Resource name="language" display="name" />
        </Col>
      </FormGroup>
      <MoreField />
      <Sites />
    </div>
  );
};

General.propTypes = {
  enterpriseName: PropTypes.string,
  enterpriseCapacity: PropTypes.number,
  sitesGeneral: PropTypes.number,
  showNumberOfSites: PropTypes.number
};

export default General;
