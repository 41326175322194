import PropTypes from 'prop-types';
import React from 'react';
import { TabPane } from 'reactstrap';

const TabElement = ({ tabId, children }) => {
  return (
    <TabPane tabId={tabId} className="coreTabContent">
      {children}
    </TabPane>
  );
};

TabElement.propTypes = {
  tabId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType])
};

TabElement.defaultProps = {
  children: null
};

export default TabElement;
