/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, CardBody, Table, Button, Spinner } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Resource } from '@/components/form';
import api from '@/services/api';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

// Api platform attend des IRIS. et pas des objets
const Actions = ({ values, setFieldValue }) => {
  const [isLoading, setLoading] = useState(false);
  const [actions, setActions] = useState();

  const handleDelete = (id, index) => {
    const newValActions = [...values.actions];

    newValActions.splice(index, 1);
    setFieldValue('actions', newValActions);
  };

  const handleAdd = async () => {
    setLoading(true);
    const id = values.action;

    let isExisting = false;
    actions.map((c) => {
      if (c.id === id) {
        isExisting = true;
      }

      return null;
    });

    if (isExisting) {
      return;
    }

    const action = await getResource('Action').read(id);

    const valActions = values.actions;
    valActions.push(action['@id']);

    setFieldValue('actions', valActions);
    setActions([...actions, action]);

    setLoading(false);
  };

  const updateActions = async (vals) => {
    const action = [];
    for (let i = 0; i < vals.length; i++) {
      const response = await api.get(vals[i]);
      action.push(response.data);
    }

    setActions(action);
  };

  useEffect(() => {
    if (!values || !values.actions) {
      return;
    }

    updateActions(values.actions);
  }, [values.actions]);

  return (
    <Card className="bg-light">
      <CardBody>
        <Card>
          <CardBody>
            <Row className="m-0">
              <Col lg={6} sm={12}>
                <Resource name="action" resource="Action" display="name" clearable useId />
              </Col>
              <Col lg={3} sm={12}>
                <Button onClick={handleAdd} disabled={isLoading} size="sm" color="default">
                  {isLoading ? <Spinner color="primary" size="sm" /> : t('add')}
                </Button>
              </Col>
              <Col lg={12} sm={12}>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>{t('action.name')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actions &&
                      Object.keys(actions).map((key, index) => {
                        const action = actions[key];
                        if (!action) {
                          return null;
                        }

                        return (
                          <tr key={action.id}>
                            <td>
                              <Link to={`/actions/${action.id}`}>{action.name}</Link>
                            </td>
                            <td>
                              <Button color="danger" size="sm" onClick={() => handleDelete(action.id, index)}>
                                <Icon name="trash" />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

Actions.propTypes = {
  //setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    action: PropTypes.number,
    actions: PropTypes.array
  }).isRequired
};

export default Actions;
