import React from 'react';
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap';

import MapImg from '@/assets/img/common/maps.png';
import Icon from '@/components/common/icon';
import translate from '@/components/common/translate';
// import Media from '@/components/common/media';
import router from '@/services/router';
import { t } from '@/services/translator';

export default ({ community }) => {
  const { name, maps, report } = community;

  const redirect = (mapId) => {
    const reportId = typeof report === 'string' ? report.replace('/reports/', '') : report.id;

    router.navigate(`/viewer/map?id=${mapId}&report=${reportId}&community=${community.id}`);
  };

  return (
    <div className="accordion-item wever">
      <h2 className="accordion-header" id={name}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${name}_collapse`}
          aria-expanded="true"
          aria-controls={`#${name}_collapse`}
        >
          {/* <span className="mr-4">{logo && <Media data={logo} width="50px" style={{ borderRadius: '50%' }} />}</span>{' '} */}
          {t(name)}
        </button>
      </h2>
      <div id={`${name}_collapse`} className="accordion-collapse collapse show" aria-labelledby={name}>
        <div className="accordion-body">
          <ListGroup className="managerMap">
            {maps &&
              maps.map((map) => (
                <ListGroupItem
                  className="justify-content-between managerMapItem"
                  onClick={() => redirect(map.id)}
                  key={map.id}
                >
                  <Row>
                    <Col lg="3" sm="5">
                      <img src={MapImg} width="65px" alt="" />
                    </Col>
                    <Col lg="6">{translate(map.name)}</Col>
                    <Col lg="3" className="text-right d-flex justify-content-end align-items-center">
                      <span className="text-grey managerMapItemExplore">
                        {t('explore')} <Icon name="chevron-right" />
                      </span>
                    </Col>
                  </Row>
                </ListGroupItem>
              ))}
          </ListGroup>
        </div>
      </div>
    </div>
  );
};
