import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TabContent, Nav, NavItem, NavLink } from 'reactstrap';

import _TabElement from './content';

export const TabElement = _TabElement;

const TabContainer = ({ navigation, children }) => {
  const [activeTab, setActiveTab] = useState('0');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const displayNavigation = () => {
    if (!Array.isArray(navigation)) {
      return null;
    }

    return navigation.map((item, index) => {
      const classNames = classnames({ active: activeTab === `${index}` });

      return (
        <NavItem className="coreTabNavItem" key={index}>
          <NavLink
            className={`coreTabNavLink ${classNames}`}
            onClick={() => {
              toggle(`${index}`);
            }}
          >
            {item}
          </NavLink>
        </NavItem>
      );
    });
  };

  return (
    <div>
      <Nav tabs className="coreTab">
        {displayNavigation()}
      </Nav>
      <TabContent activeTab={activeTab}>{children}</TabContent>
    </div>
  );
};

TabContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]).isRequired,
  navigation: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string, PropTypes.shape({})])
  ).isRequired
};

export default TabContainer;
