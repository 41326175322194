import React from 'react';

import Icon from '@/components/common/icon';

const DiagnosticNav = require('@/_configs/navigations/diagnostic.nav').default;
const PartnerTypeNav = require('@/_configs/navigations/partner-type.nav').default;
const CommunityNav = require('@/_configs/navigations/community.nav').default;
const ServerNav = require('@/_configs/navigations/server.nav').default;
const DocumentNav = require('@/_configs/navigations/document.nav').default;
const MapsNav = require('@/_configs/navigations/maps.nav').default;
const ManagerNav = require('@/_configs/navigations/manager.nav').default;
const DiagnosticTranslatorNav = require('@/_configs/navigations/diagnostic-translator.nav').default;
const AccountTypeNav = require('@/_configs/navigations/account-type.nav').default;
const FakeNav = require('@/_configs/navigations/fake.nav').default;
const SheetActionNav = require('@/_configs/navigations/sheet-action.nav').default;
const DashbordNav = require('@/_configs/navigations/dashboard.nav').default;
const EligibilityRuleNav = require('@/_configs/navigations/eligibility-rule.nav').default;
const ActionsNav = require('@/_configs/navigations/actions.nav').default;
const ExporterNav = require('@/_configs/navigations/exporter.nav').default;
const MediaNav = require('@/_configs/navigations/media.nav').default;
const CommandQueuNav = require('@/_configs/navigations/command-queu.nav').default;
const ImportNav = require('@/_configs/navigations/import.nav').default;
const CommonNav = require('@/_configs/navigations/common.nav').default;

/**
 * Navigation of sidebar
 */
export default [
  ...CommunityNav,
  {
    label: 'adminapp_menu_reports',
    route: 'report',
    icon: <Icon name="calculator" className="text-green iconRightMarginMax" />,
    items: [
      {
        label: 'adminapp_menu_reports_list',
        route: 'report_list'
      },
      {
        label: 'adminapp_menu_reports_create',
        route: 'report_create'
      },
      {
        label: 'adminapp_menu_reports_instance_type',
        route: 'report_instance_type_list'
      },
      {
        label: 'adminapp_menu_reports_patterns',
        route: 'report_patterns',
        icon: <Icon name="calculator" className="text-green-dark iconRightMarginMid" />,
        items: [
          {
            label: 'adminapp_menu_reports_patterns_list',
            route: 'report_patterns_list'
          },
          {
            label: 'adminapp_menu_reports_patterns_create',
            route: 'report_patterns_create'
          }
        ]
      }
    ]
  },
  ...MapsNav,
  ...DiagnosticNav,
  {
    label: 'adminapp_menu_user',
    icon: <i className="ni ni-circle-08 text-dark" />,
    route: 'user',
    items: [
      {
        label: 'adminapp_menu_user_list',
        route: 'user_list'
      },
      {
        label: 'adminapp_menu_user_create',
        route: 'user_create'
      }
    ]
  },
  {
    label: 'adminapp_menu_contract_partner',
    route: 'contract_partner',
    icon: <Icon name="handshake" className="text-danger iconRightMarginMax" />,
    items: [
      {
        label: 'adminapp_menu_contract_partner_list',
        route: 'contract_partner_list'
      },
      {
        label: 'adminapp_menu_contract_partner_create',
        route: 'contract_partner_create'
      },
      ...PartnerTypeNav
    ]
  },
  ...DocumentNav,
  {
    label: 'adminapp_menu_translations',
    icon: <Icon name="language" className="text-violet iconRightMarginMax" />,
    route: 'translation',
    roles: ['ROLE_TRAD_DIAGNOSTIC'],
    items: [
      {
        label: 'adminapp_menu_translations_list',
        route: 'translation_list',
        roles: ['ROLE_TRAD_DIAGNOSTIC']
      },
      {
        label: 'adminapp_menu_translations_languages',
        route: 'language_list',
        roles: ['ROLE_TRAD_DIAGNOSTIC']
      }
    ]
  },
  ...FakeNav,
  ...ActionsNav,
  ...EligibilityRuleNav,
  ...SheetActionNav,
  ...ExporterNav,
  ...DashbordNav,
  {
    label: 'adminapp_menu_parameters',
    icon: <Icon name="cogs" className="iconRightMarginMax" />,
    roles: ['ROLE_ADMIN'],
    items: [
      {
        label: 'adminapp_menu_parameters_configurations',
        route: 'configurations',
        icon: <Icon name="cog" className="iconRightMarginMid" />,
        items: [
          {
            label: 'adminapp_menu_parameters_configurations_list',
            route: 'configurations_list'
          },
          {
            label: 'adminapp_menu_parameters_configurations_create',
            route: 'configurations_create'
          }
        ]
      },

      ...ServerNav,
      ...AccountTypeNav,
      ...MediaNav,
      ...CommonNav,
      ...ImportNav,
      ...CommandQueuNav
    ]
  },
  ...ManagerNav,
  ...DiagnosticTranslatorNav
];

export const subNavigationTitle = null;
export const subNavigation = [];
export const bottomNavigationItem = null;
