import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { Input, Select, Toggle, Resource, MediaInput, AutocompleteServer } from '@/components/form';
import environment from '@/services/environment';
import security from '@/services/security';
import { t } from '@/services/translator';

const UserProfile = ({ mapping, values }) => {
  const [, , helper] = useField({ name: 'person.server' });
  const isAdmin = security.isGranted('ROLE_ADMIN') || security.isGranted('ROLE_SUPER_ADMIN');
  const isDevMode = environment.get('node_env') !== 'production';

  const serverChange = async (key) => {
    helper.setValue(key);
  };

  return (
    <Row className="m-0">
      <Col lg={6}>
        <Row>
          <Col lg={12}>
            <Input name="username" />
          </Col>
          <Col lg={12}>
            <Input name="plainPassword" size="sm" type="password" />
            <span className="text-info text-sm">{t('adminapp_minimum_pasword_requirement')}</span>
          </Col>
          {mapping.has('roles') && (
            <Col lg={12}>
              <Select name="roles" multiple />
            </Col>
          )}
          <Col lg={12}>
            <Toggle name="enabled" groupProps={{ className: 'pt-2' }} />
          </Col>
          <Col lg={12}>
            <AutocompleteServer name="server" onChange={serverChange} />
          </Col>
          <Col lg={12}>
            {isAdmin && isDevMode && values.report && (
              <Link to={`/reports/${values.report.id}`}>
                {t('report')} : {values.report.id}
              </Link>
            )}

            {isAdmin && isDevMode && values.map && (
              <span className="ml-2">
                {t('map')} : {values.map.id}
              </span>
            )}
          </Col>
        </Row>
      </Col>
      <Col lg={6}>
        <Row>
          <Col lg={12}>
            <Input name="person.firstName" />
          </Col>
          <Col lg={12}>
            <Input name="person.lastName" />
          </Col>
          <Col lg={12}>
            <Input name="person.phone" />
          </Col>
          <Col lg={12}>
            <Resource name="person.language" display="name" />
          </Col>
          <Col lg={12}>
            <MediaInput name="person.profilePicture" resource="users" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

UserProfile.propTypes = {
  mapping: PropTypes.shape({ has: PropTypes.func }).isRequired
};

export default UserProfile;
