import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_actions',
    icon: <Icon name="play" className="text-info iconRightMarginMax" />,
    roles: ['ROLE_ACCOUNT_MANAGER'],
    items: [
      {
        label: 'adminapp_menu_actions_list',
        route: 'actions_list'
      },
      {
        label: 'adminapp_menu_action_create',
        route: 'actions_create'
      },
      {
        label: 'adminapp_menu_diagnostic_action_type',
        route: 'action_type',
        icon: <Icon name="palette" className="iconRightMarginSmall" />,
        items: [
          {
            label: 'adminapp_menu_diagnostic_action_type_list',
            route: 'action_type_list'
          },
          {
            label: 'adminapp_menu_diagnostic_action_type_create',
            route: 'action_type_create'
          }
        ]
      }
    ]
  }
];
