import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';

import { Input, TableLangValue, Datepicker, Autocomplete, Checkbox, WysiwygLocatorEditor } from '@/components/form';
import MediaInput from '@/components/form/media-input';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import { t } from '@/services/translator';

import Actions from './actions';

const DiagnosticForm = ({ match }) => {
  return (
    <View>
      <ResourceForm
        resource="Diagnostic"
        id={match.params.id}
        initialValues={{ name: {}, alias: '', startDate: null, endDate: null, localeDescr: null }}
      >
        {({ submitting, values, renderActions, setFieldValue }) => (
          <>
            <Row>
              <Col lg={6}>
                <Input name="alias" placeholder="ex: my_alias" pattern="/^[a-zA-Z_0-9]+$" />
              </Col>
              <Col lg={6}>
                <Autocomplete name="community" clearable resource="Community" display="name" tag="name" autoload />
              </Col>
              <Col lg={12}>
                <Input name="slug" placeholder="ex: my-diagnostic" pattern="/^[a-zA-Z_0-9]+$" />
              </Col>
              <Col lg={12}>
                <Input name="description" type="textarea" />
              </Col>
              <Col lg={6}>
                <Datepicker name="startDate" />
              </Col>
              <Col lg={6}>
                <Datepicker name="endDate" />
              </Col>
              <Col lg={6}>
                <Autocomplete name="type" clearable resource="DiagnosticType" display="alias" tag="alias" autoload />
              </Col>
              <Col lg={12}>
                <div>{t('app_title')}</div>
                <TableLangValue name="title" />
              </Col>
              <Col lg={6}>
                <Autocomplete
                  name="defaultLocale"
                  clearable
                  resourceFunction="getLanguages"
                  resource="Language"
                  display="name"
                  tag="name"
                  autoload
                />
              </Col>
              <Col lg={6}>
                <Autocomplete
                  multiple={true}
                  name="locales"
                  clearable
                  resourceFunction="getLanguages"
                  resource="Language"
                  display="name"
                  tag="name"
                  autoload
                />
              </Col>
              <Col lg={6}>
                <MediaInput mineTypeRestriction="image/png" resource="diagnostics" name="favicon" />
              </Col>
              <Col lg={6}>
                <Autocomplete
                  name="eligibilityRule"
                  clearable
                  resource="EligibilityRule"
                  display="alias"
                  tag="alias"
                  autoload
                />
              </Col>
              <Col lg={12}>
                <Actions values={values} setFieldValue={setFieldValue} />
              </Col>

              <Col lg={12}>
                <TableLangValue name="name" />
              </Col>

              <Col lg={12}>
                <Row>
                  <Col className="col-12">
                    <WysiwygLocatorEditor name={`endDescription`} />
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <Checkbox name="multilang" />
              </Col>
            </Row>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

DiagnosticForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default DiagnosticForm;
