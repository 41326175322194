/* eslint-disable react-hooks/exhaustive-deps  */
import { useField } from 'formik';
import React, { useState } from 'react';
import MonacoEditor from 'react-monaco-editor';

const EditorValues = ({ index }) => {
  const [filterValuesField, , helpers] = useField({ name: `filters.${index}.values` });
  const [fcTimeout, setFcTimeout] = useState('');

  const handleChange = (newValue) => {
    clearTimeout(fcTimeout);

    const timeoutFc = setTimeout(() => {
      try {
        helpers.setValue(JSON.parse(newValue));
      } catch (e) {}
    }, 550);
    setFcTimeout(timeoutFc);
  };

  return (
    <MonacoEditor
      height={300}
      theme="vs-dark"
      language="json"
      defaultValue={filterValuesField.value ? JSON.stringify(filterValuesField.value) : ''}
      // value={value}
      onChange={handleChange}
      options={{
        tabSize: 2,
        automaticLayout: true
      }}
    />
  );
};

export default EditorValues;
