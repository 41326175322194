import React from 'react';
import { useField, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
// Style
import { Col, FormGroup, Label } from 'reactstrap';
//Core
import _Security from '@/services/security';
import { t } from '@/services/translator';
// Même dossier
import AddressApi from '../address';
import _Form from '@/components/form';
const { Input, Checkbox } = _Form;

const Enterprisesite = ({ idx }) => {
  const [fieldAddress, , helpersAddress] = useField({ name: `sites[${idx}].addressInformationSheet` });

  const onChangeAddress = (newState) => {
    helpersAddress.setValue(newState);
  };

  return (
    <div key={`site-${idx}`}>
      <FormGroup row>
        <Label sm={4}>{t('communitiesapp_site_name')} : </Label>
        <Col sm={6}>
          <Input label={null} name={`sites[${idx}].name`} />
          <ErrorMessage name={`sites[${idx}].name`}>
            {(msg) => <div className="informationsheetErrorcolor">{msg}</div>}
          </ErrorMessage>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={4}>{t('communitiesapp_site_address')} : </Label>
        <AddressApi
          value={fieldAddress.value}
          onChange={onChangeAddress}
          errorName={`sites[${idx}].addressInformationSheet`}
        />
      </FormGroup>
      <FormGroup row>
        <Label sm={4}>{t('communitiesapp_site_workforce')} : </Label>
        <Col sm={6}>
          <Input type="number" label={null} name={`sites[${idx}].capacity`} />
          <ErrorMessage name={`sites[${idx}].capacity`}>
            {(msg) => <div className="informationsheetErrorcolor">{msg}</div>}
          </ErrorMessage>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={4}>{t('communitiesapp_site_active')} : </Label>
        <Col sm={6}>
          <Checkbox className="" label={null} name={`sites[${idx}].active`} />
        </Col>
      </FormGroup>
      {
        (
          _Security.isGranted('ROLE_ACCOUNT_MANAGER') ||
          _Security.isGranted('ROLE_SUPER_ADMIN') ||
          _Security.isGranted('ROLE_ADMIN')
        ) && (
          <FormGroup row>
            <Label sm={4}> {t('communitiesapp_site_duplicate')} : </Label>
            <Col sm={6}>
              <Checkbox className="" label={null} name={`sites[${idx}].duplicate`} />
            </Col>
          </FormGroup>
        )
      }
    </div>
  );
};

Enterprisesite.propTypes = {
  idx: PropTypes.number,
  siteState: PropTypes.array,
  handleSiteChange: PropTypes.func
};

export default Enterprisesite;
