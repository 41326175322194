import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Table, Spinner } from 'reactstrap';

import notifications from '@/services/notifications';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

const DataView = ({ index }) => {
  const [total, setTotal] = useState();
  const [collection, setCollection] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [ran, setRan] = useState(0);
  const [preview, setPreview] = useState();

  const [queryField] = useField({ name: `instances[${index}].query` });
  const [instanceIdField] = useField({ name: `instances[${index}].id` });
  const [idField] = useField({ name: `id` });
  const query = queryField.value || '';
  const id = idField.value;

  const columns = collection ? (collection[0] !== undefined ? Object.keys(collection[0]) : []) : [];

  const setState = (object) => {
    Object.keys(object).forEach((key) => {
      switch (key) {
        case 'total':
          setTotal(object[key]);
          break;
        case 'collection':
          setCollection(object[key]);
          break;
        case 'submitting':
          setSubmitting(object[key]);
          break;
        case 'ran':
          setRan(object[key]);
          break;
        case 'preview':
          setPreview(object[key]);
          break;
        default:
      }
    });
  };

  const fetchData = () => {
    setState({ submitting: true, collection: [], ran: ran + 1, preview: 'data' });

    getResource('Report')
      .fetchPreview(id, instanceIdField.value)
      .then((data) => {
        setState({ submitting: false, collection: data.preview, total: data.total });
      })
      .catch(() => {
        setState({ submitting: false });
      });
  };

  const runQuery = () => {
    try {
      JSON.parse(query);
    } catch (e) {
      return notifications.error('Query syntax invalid', e.message);
    }

    setState({ submitting: true, collection: [], ran: ran + 1, preview: 'query' });

    getResource('Response')
      .query(query)
      .then((data) => {
        setState({ submitting: false, collection: data.preview, total: data.total });
      })
      .catch(({ response }) => {
        if (response && response.status === 400) {
          notifications.error('Oops query error detected', response.data.error);
        }
        setState({ submitting: false });
      });

    return true;
  };

  useEffect(() => {
    if (!collection) {
      return;
    }
  }, [collection, ran]);

  return (
    <Row className="m-0">
      <Col lg={12} className={`flex justify-content-${id ? 'between' : 'end'}`}>
        {id !== undefined && (
          <Button
            size="sm"
            type="button"
            color="terciary"
            outline
            disabled={submitting}
            className={`mb-2 shadow-none ${preview === 'data' ? 'border-dark' : ''}`}
            onClick={fetchData}
          >
            {!submitting || preview !== 'data' ? (
              t('report.show_current_data')
            ) : (
              <i>
                <Spinner size="sm" />
              </i>
            )}
          </Button>
        )}

        <div className="flex align-items-center">
          {ran > 0 && !submitting && typeof total === 'number' && (
            <div className="text-black-50 mr-3">
              <small>{t('nb_elements', total)}</small>
            </div>
          )}
          {ran > 0 && (
            <Button
              size="sm"
              type="button"
              color="terciary"
              outline
              disabled={submitting}
              className="shadow-none"
              onClick={() => setState({ ran: 0, collection: [], preview: null })}
            >
              {t('report.hide_preview')}
            </Button>
          )}

          <Button
            size="sm"
            type="button"
            color="terciary"
            outline
            disabled={submitting}
            className={`shadow-none ${preview === 'query' ? 'border-dark' : ''}`}
            onClick={runQuery}
          >
            {!submitting || preview !== 'query' ? (
              t('report.show_preview')
            ) : (
              <i>
                <Spinner size="sm" />
              </i>
            )}
          </Button>
        </div>
      </Col>
      {ran > 0 && (
        <Col lg={12} style={{ maxHeight: '500px', overflow: 'scroll' }}>
          <Table className="mt-2">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(collection) &&
                collection.map((item) => (
                  <tr key={item.id}>
                    {Object.keys(item).map((prop) => (
                      <td key={prop}>
                        {item[prop] && typeof item[prop] === 'object' ? JSON.stringify(item[prop]) : item[prop]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
          {collection.length === 0 && !submitting && (
            <div className="text-center mt-3">
              <i>{t('no_result')}</i>
            </div>
          )}
        </Col>
      )}
    </Row>
  );
};

DataView.propTypes = {
  query: PropTypes.string
};

DataView.defaultProps = {
  query: ''
};

export default DataView;
