import api from '@/services/api';
import Resource from '@/services/resources/common';

export default class EligibilityRule extends Resource {
  constructor(entrypoint = '/eligibility_rules', alias = 'EligibilityRule') {
    super(entrypoint, alias);
  }

  async fetchEligibilityFunctionsTypes() {
    const responses = await api.get(this.buildUri('functions_name'));
    return responses.data;
  }
}
