/* eslint-disable react-hooks/exhaustive-deps */
import qs from 'qs';
import React, { useEffect, useContext, useReducer } from 'react';
import { Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';

import Icon from '@/components/common/icon';
import Context from '@/services/context';
import logger from '@/services/logger';
import notifications from '@/services/notifications';
import getResource from '@/services/resources';
import router from '@/services/router';
import { t } from '@/services/translator';
import object from '@/services/utils/object';

/**
 * Valeur initial du reducer
 */
const initialState = {
  loading: true,
  collection: [],
  totalItems: 0,
  filters: {},
  navigating: false,
  selected: {},
  listContainer: null
};

/**
 * REDUCER
 */
const listAdminReducer = (state, action) => {
  const newState = { ...state };

  if (action.type === 'all') {
    return action.value;
  }

  Object.keys(action).forEach((key) => {
    newState[key] = action[key];
  });

  return newState;
};

/**
 * Composant hook effectuant les actions LISST
 */
const useListAdmin = (props) => {
  const context = useContext(Context);
  const [state, dispatch] = useReducer(listAdminReducer, initialState);

  // PROPS
  const {
    initialFilters,
    extraParameters,
    admin,
    // components
    body,
    actions,
    filters
  } = props;
  // STATE

  if (!props.resource) {
    throw new Error('You must define a resource in ResourceList props');
  }

  const resource = getResource(props.resource);

  const initialFiltersValues = () => {
    return {
      page: 1,
      perPage: 30,
      order: {
        id: 'DESC'
      },
      ...initialFilters
    };
  };

  /**
   * Récupère la liste avec les filtres.
   *
   * @param {object}  filters
   * @param {boolean} merge
   */
  const fetch = (filtersFetch, merge = true) => {
    const navigating = filtersFetch && filtersFetch.page && Number(filtersFetch.page) !== Number(state.filters.page);

    // Reset page to 1 on perPage changes
    if (filtersFetch && filtersFetch.perPage && !filtersFetch.page) {
      filtersFetch.page = 1;
    }

    // Merge new filters with new one or reset filters with new value
    filtersFetch = merge
      ? object.merge(state.filters, filtersFetch)
      : object.merge(object.clone(initialFiltersValues), filters);

    // Remove all unused properties
    filtersFetch = object.unsetProperties(filtersFetch, ['', null, undefined]);

    try {
      dispatch({ loading: true, filtersFetch, navigating, selected: {} });
    } catch (e) {
      //
    }

    router.navigate(`${router.currentUri}?${qs.stringify(filters)}`);

    logger.info('Admin LIST', ' fetch ', { filtersFetch });

    resource
      .list(filtersFetch, admin, extraParameters)
      .then((data) => {
        dispatch({
          collection: data['hydra:member'],
          totalItems: data['hydra:totalItems'],
          navigating: false,
          loading: false
        });

        // If user navigates between pages we scroll on top of the list
        // if (navigating && listContainer) {
        //     listContainer.scrollIntoView();
        // }
      })
      .catch(() => {
        dispatch({ loading: false });
      });
  };

  /**
   * Get current direction order of field
   *
   * @param {string} field
   *
   * @return {?string}
   */
  const getOrderDir = (field) => state.filters.order !== undefined && state.filters.order[field];

  /**
   * Add order by for a field
   * Direction is determinate by current direction value
   *
   * @param {string} field
   */
  const orderBy = (field) => {
    const { order } = state.filters;

    // If order field value is DESC it means that next step is to reset value
    // Scheme is "ASC" first then "DESC" and finally remove it from order by collection
    if (order && order[field] && order[field] === 'DESC') {
      delete order[field];

      const filterState = {
        filters: {
          ...state.filters,
          ...{ order }
        }
      };

      dispatch(filterState);
    }

    // Add order by with 'ASC' then 'DESC'
    fetch({
      order: {
        [field]: !getOrderDir(field) ? 'ASC' : 'DESC'
      }
    });
  };

  /**
   * Options of input "item per page"
   *
   * @return {array}
   */
  const perPageOptions = () => (Array.isArray(props.perPageOptions) ? props.perPageOptions : [30, 60, 120]);

  /**
   * Reset filters value to initial values
   *
   * @param {function} setValues
   */
  const resetFilters = (setValues) => {
    fetch({}, false);
    setValues(initialFiltersValues);
  };

  /**
   * Select item id to make massive actions
   *
   * @param {string|number} id - '*' means the whole collection
   */
  const select = (id) => {
    const { collection, selected } = state;

    if (id === '*') {
      const newSelected = {};

      for (let i = 0, len = collection.length; i < len; ++i) {
        newSelected[collection[i].id] = true;
      }

      dispatch({ selected: newSelected });
      return;
    }

    if (!selected[id]) {
      selected[id] = true;
      dispatch({ selected });
    }
  };

  /**
   * Unselect item id in collection to make massive actions
   *
   * @param {string|number} id - '*' means the whole collection
   */
  const unselect = (id) => {
    if (id === '*') {
      dispatch({ selected: {} });
      return;
    }

    const { selected } = state;
    delete selected[id];
    dispatch({ selected });
  };

  /**
   * Toggle selection of an item
   *
   * @param {string|number} id
   */
  const toggleSelect = (id) => {
    const { selected, collection } = state;

    return (id === '*' && Object.keys(selected).length === collection.length) || selected[id] !== undefined
      ? unselect(id)
      : select(id);
  };

  /**
   * Run deletion of item(s)
   *
   * @param {string|number[]|string[]|number[]} id
   */
  const handleDelete = (id) => {
    const isMultiple = Array.isArray(id);

    context.confirm(
      <div>
        {t('confirm_delete')}`: ${isMultiple ? id.join(', ') : id}`
      </div>,
      () => {
        dispatch({ loading: true });

        const method = isMultiple ? 'deleteMultiple' : 'delete';
        resource[method](isMultiple ? { id } : id).then(() => fetch());
      }
    );
  };

  /**
   * Build multiple actions options
   *
   * @return {object[]}
   */
  const buildMultipleActionsOptions = () => {
    const multipleActions = [...(props.multipleActions || [])];

    if (props.deleteMultiple && resource.canDeleteMultiple()) {
      multipleActions.push({
        label: (
          <>
            <Icon name="trash-alt" className="mr-2" /> {t('delete')}
          </>
        ),
        value: () => handleDelete(Object.keys(state.selected))
      });
    }

    return multipleActions;
  };

  /**
   * Build column and add orderable event on it
   *
   * @param {string}   field          - Field path
   * @param {?boolean} orderable      - To define only if you want to make column orderable
   * @param {?object}  propsRender          - Props to inject in th tag
   *
   * @return {JSX.Element}
   */
  const renderColumn = (field, orderable = true, propsRender = {}) => {
    const label = typeof field !== 'string' ? field : resource.getTranslationKey(field);

    return (
      <th scope="col" onClick={() => orderable && orderBy(field)} className={field && 'pointer'} {...propsRender}>
        {typeof label === 'string' ? t(label) : label}
        {orderable && <Icon name="sort" className={`ml-2 ${getOrderDir(field) ? 'text-grey' : 'text-light'}`} />}
      </th>
    );
  };

  /**
   * Render item checkbox for multiple selection
   *
   * @param {string|number} itemId
   *
   * @return {JSX.Element}
   */
  const renderSelect = (itemId) => {
    const { selected } = state;
    const keys = Object.keys(selected);
    const checked =
      itemId === '*' ? keys.length > 0 && keys.length === state.collection.length : selected[itemId] !== undefined;
    return (
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          checked={checked}
          id={`list-checkbox-${itemId}`}
          className="custom-control-input"
          onChange={() => toggleSelect(itemId)}
        />
        <label className="custom-control-label" htmlFor={`list-checkbox-${itemId}`} />
      </div>
    );
  };

  /**
   * Render button for navigate to create view
   *
   * @return {JSX.Element|null}
   */
  const renderCreateButton = () => {
    if (!resource.canCreate()) {
      return null;
    }

    return (
      <Button
        color="default"
        type="button"
        className="mb-3"
        target="_blank"
        href={router.resourcePath(resource.alias, 'create')}
      >
        <Icon name="plus-circle" className="mr-2" />
        {t('create')}
      </Button>
    );
  };

  /**
   * Render ellipsis button with actions for an item
   *
   * @param {object}              item
   * @param {function|JSX.Element }actions
   *
   * @return {JSX.Element}
   */
  const renderItemActions = (item, actionsFc) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle color="" className="btn-icon-only text-light bg-transparent" role="button" size="sm">
          <Icon name="ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          {resource.canUpdate() && (
            <DropdownItem href={router.resourcePath(resource.alias, 'update', { id: item.id })} target="_blank">
              <Icon name="pen-alt" /> {t('edit')}
            </DropdownItem>
          )}
          {typeof actionsFc === 'function' ? actionsFc(DropdownItem) : actionsFc}
          {resource.canDelete() && (
            <DropdownItem onClick={() => handleDelete(item.id)}>
              <Icon name="trash-alt" /> {t('delete')}
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  /**
   * Get props to inject in body component
   */
  const bodyProps = () => {
    return {
      fetch,
      resource,
      setLoading: (loading, callback) => {
        dispatch({ loading });
        callback();
      },
      collection: state.collection || [],
      renderColumn,
      renderSelect,
      renderItemActions
    };
  };

  /**
   * Run callback of a multiple action
   *
   * @param {?callback} callback
   */
  const handleMultipleAction = (callback) => {
    const { selected } = state;
    const ids = Object.keys(selected);

    if (ids.length === 0) {
      return notifications.warning(t('action_ignored'), t('no_item_selected_in_list'));
    }

    if (typeof callback === 'function') {
      callback(ids, bodyProps);
    }
  };

  // "constructeur"
  useEffect(() => {
    dispatch({
      filters: {
        ...initialFiltersValues,
        ...qs.parse(router.currentSearch.substring(1))
      }
    });
  }, []);

  useEffect(() => {
    fetch();
  }, [extraParameters]);

  return {
    state,
    dispatchState: dispatch,
    listContainer: null,
    components: {
      Body: body,
      Actions: actions,
      Filters: filters
    },
    functions: {
      bodyProps,
      buildMultipleActionsOptions,
      fetch,
      getOrderDir,
      handleDelete,
      handleMultipleAction,
      orderBy,
      perPageOptions,
      unselect,
      resource,
      resetFilters,
      renderSelect,
      renderCreateButton,
      select,
      toggleSelect
    }
  };
};

export default useListAdmin;
