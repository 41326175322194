/* eslint-disable class-methods-use-this */
import api from '@/services/api';
import Resource from '@/services/resources/common';
import security from '@/services/security';

export default class CommunityResource extends Resource {
  constructor(entrypoint = '/communities', alias = 'Community') {
    super(entrypoint, alias);
  }

  list(filters, admin, extraParameters) {
    const isAdmin =
      security.isGranted('ROLE_ADMIN') ||
      security.isGranted('ROLE_SUPER_ADMIN') ||
      security.isGranted('ROLE_ACCOUNT_MANAGER');

    if (admin === false || !isAdmin) {
      return this.managedList(false, extraParameters);
    }

    return super.list(filters);
  }

  async managedList(directResponses = true, community, all = true, url = '/me') {
    let filter = '';

    if (community) {
      const id = typeof community === 'object' ? community.id : community.replace('/communities/', '');
      filter = `?id=${id}`;
    }

    if (all) {
      filter += (community ? '&' : '?') + 'all=true';
    }

    const response = await api.get(`${this.entrypoint}${url}${filter}`);

    return directResponses ? response.data['hydra:member'] : response.data;
  }

  async managedDashboard(directResponses = true, community) {
    const response = await this.managedList(directResponses, community, community !== null, '/manager/dashboard');

    return response;
  }

  async readPublicInformation(id) {
    const response = await api.get(`/communities/${id}/public`);

    return response.data;
  }

  async getSheetActions(community) {
    let filter = '';
    if (community) {
      const id = typeof community === 'object' ? community.id : community.replace('/communities/', '');
      filter = `?id=${id}`;
    } else {
      filter += '?all=true';
    }

    const response = await api.get(`${this.entrypoint}/manager/sheet_actions${filter}`);

    return response.data;
  }

  async getSheetActionDasboard(communityId, sheetActionId, type) {
    const response = await api.get(
      `${this.entrypoint}/manager/sheet_action/dashboard?id=${communityId}&action=${sheetActionId}&type=${type}`
    );

    return response.data;
  }

  async getSheetActionGantt(communityId) {
    const response = await api.get(`${this.entrypoint}/${communityId}/sheet_action_gantt`);

    return response.data;
  }

  /**
 * Permet de sauvegarder les données pour la ressource entreprise.
 */
readPublic = async (id) => {
  const responses = await api.get(this.entrypoint + '/' + id + '/public');

  return responses.data;
};

update = async (data) => {
  const responses = await api.put(this.entrypoint + '/' + data.id, data);

  return responses.data;
};

getDefault = async () => {
  const responses = await api.get(this.entrypoint + '/default');

  return responses.data['hydra:member'][0];
};

exportCommunityReport = async (id) => {
  return api.get(`${this.entrypoint}/export/${id}`, null, {
    responseType: 'arraybuffer'
  });
}
}
