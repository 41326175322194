import api from '@/services/api';
import Resource from '@/services/resources/common';
import security from '@/services/security';

export default class UserResource extends Resource {
  constructor(entrypoint = '/users', alias = 'User') {
    super(entrypoint, alias);
  }

  /**
   * Anonymize a user item of a users collection
   *
   * @param {string|string[]|number|number[]} id
   *
   * @return {Promise}
   */
  anonymize(id) {
    const isMultiple = Array.isArray(id);

    return api.get(
      isMultiple ? this.buildUri('anonymize') : this.buildUri(id, 'anonymize'),
      isMultiple ? { id, perPage: id.length } : {}
    );
  }

  // eslint-disable-next-line class-methods-use-this
  canUpdate() {
    const canRead = security.hasPermission('User', 'read', false);
    const canUpdate = security.hasPermission('User', 'update', false);

    return (
      (canRead === true && canUpdate === true) ||
      (canRead !== false && canUpdate !== false && security.isGranted('ROLE_ADMIN'))
    );
  }

  canAnonymize() {
    return security.hasPermission(this.alias, 'anonymize');
  }

  canAnonymizeMultiple() {
    return security.hasPermission(this.alias, 'anonymize:multiple');
  }

  exportUsersAndTokens(query) {
    return api.post('/users/export/token', { query: query }, null, {
      responseType: 'arraybuffer'
    });
  }

  exportUsersAndTokenActionPreview(query) {
    return api.post('/users/export/preview', { query: query });
  }
}
