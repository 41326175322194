import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, Select, Editor } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

const EligibilityRuleForm = ({ match }) => {
  const [eligibilityFunctionsTypesLoading, setEligibilityFunctionsTypesLoading] = useState(true);
  const [eligibilityFunctionsTypesOptions, setEligibilityFunctionsTypesOptions] = useState({});

  const loadEligibilityFunctionsTypes = async () => {
    try {
      const eligibilityFunctionResult = await getResource('EligibilityRule').fetchEligibilityFunctionsTypes();
      const options = eligibilityFunctionResult.map((key, index) => ({ value: key, label: t(key) }));
      setEligibilityFunctionsTypesOptions(options);
      setEligibilityFunctionsTypesLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadEligibilityFunctionsTypes();
  }, []);

  return (
    <View>
      <ResourceForm
        resource="EligibilityRule"
        id={match.params.id}
        initialValues={{ name: {}, alias: '', startDate: null, endDate: null }}
      >
        {({ submitting, values, renderActions }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={12}>
                    <Input name="alias" placeholder="ex: my_alias" pattern="/^[a-zA-Z_0-9]+$" />
                  </Col>
                  <Col lg={12}>
                    <Select
                      name="functionName"
                      isLoading={eligibilityFunctionsTypesLoading}
                      isDisabled={eligibilityFunctionsTypesLoading}
                      options={eligibilityFunctionsTypesOptions}
                    />
                  </Col>
                  <Col lg={12}>
                    <Editor name="functionParameters" height="300" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

EligibilityRuleForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default EligibilityRuleForm;
