// import logger from '@/services/logger';

/**
 * The storage is in charge to persist data into browser storage
 */
export default {
  /**
   * Get storage data
   *
   * @return {object}
   */
  get data() {
    // const name = 'wever_app=';
    // const decodedCookie = decodeURIComponent(document.cookie);
    // const cookieData = decodedCookie.split(';');

    const LS_weverApp = localStorage.getItem('wever_app');

    if (null !== LS_weverApp) {
      return JSON.parse(decodeURIComponent(LS_weverApp));
    } else {
      return {};
    }

    // for (let i = 0; i < cookieData.length; i++) {
    //   let cookie = cookieData[i];
    //   while (cookie.charAt(0) === ' ') {
    //     cookie = cookie.substring(1);
    //   }

    //   if (cookie.indexOf(name) === 0) {
    //     return JSON.parse(decodeURIComponent(cookie.substring(name.length, cookie.length)));
    //   }
    // }

    // return {};
  },

  delete() {
    localStorage.removeItem('wever_app');
    // const cookies = document.cookie.split(';');

    // for (let i = 0; i < cookies.length; i++) {
    //   const cookie = cookies[i];
    //   const eqPos = cookie.indexOf('=');
    //   const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    //   if (name === 'wever_app') {
    //     document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path="/"`;
    //   }
    // }
  },

  /**
   * Save data in storage
   *
   * @param {*} data
   */
  set data(data) {
    // let cookie = `wever_app=${encodeURIComponent(JSON.stringify(data))};path="/";`;

    // // Set domain and allow sub domain to access cookie
    // // cookie += `domain=.${environment.get('app_domain')}; path=/;`;

    // // Set expiration date
    // const d = new Date();
    // const expDays = 100; // 100 days
    // d.setTime(d.getTime() + expDays * 24 * 60 * 60 * 1000);

    // cookie += `expires=${d.toUTCString()};`;

    localStorage.setItem('wever_app', encodeURIComponent(JSON.stringify(data)));

    // document.cookie = cookie;
  },

  /**
   * Save data in storage interface
   *
   * @param {string} key
   *
   * @return {*}
   */
  get(key) {
    return this.data[key];
  },

  /**
   * Check if a key exist in storage
   *
   * @param {string} key
   *
   * @return {boolean}
   */
  has(key) {
    return this.data[key] !== undefined;
  },

  /**
   * Set value into storage
   *
   * @param {string} key
   * @param {*}      value
   *
   * @return {self}
   */
  set(key, value) {
    const { data } = this;
    data[key] = value;
    this.data = data;

    return this;
  },

  /**
   * Remove value of key from storage
   *
   * @param {string}  key
   *
   * @return {self}
   */
  unset(key) {
    const { data } = this;
    delete data[key];
    this.data = data;

    return this;
  }
};
