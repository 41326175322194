import { Formik } from 'formik';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import SimpleSelect from 'react-select';
import {
  Button,
  Spinner,
  CardBody,
  CardHeader,
  Card,
  Form,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';

import Icon from '@/components/common/icon';
import Pagination from '@/components/common/pagination';
import { Select } from '@/components/form';
import selectHelper from '@/components/form/select/helper';
import Context from '@/services/context';
import logger from '@/services/logger';
import notifications from '@/services/notifications';
import getResource from '@/services/resources';
import router from '@/services/router';
import security from '@/services/security';
import translator, { t } from '@/services/translator';
import object from '@/services/utils/object';

export default class List extends React.Component {
  static contextType = Context;

  state = {
    loading: true,
    collection: [],
    totalItems: 0,
    filters: {},
    navigating: false,
    selected: {}
  };

  /**
   * Get resource service class
   *
   * @return {Resource}
   */
  get resource() {
    return getResource(this.props.resource);
  }

  /**
   * Initial filters values when filters are empty
   *
   * @type {object}
   */
  get initialFiltersValues() {
    return {
      page: 1,
      perPage: 30,
      order: {
        id: 'DESC'
      },
      ...this.props.initialFilters
    };
  }

  /**
   * Options of input "item per page"
   *
   * @return {array}
   */
  get perPageOptions() {
    return this.props.perPageOptions || [30, 60, 120];
  }

  /**
   * Get props to inject in body component
   */
  get bodyProps() {
    return {
      fetch: this.fetch.bind(this),
      resource: this.resource,
      setLoading: (loading, callback) => this.setState({ loading }, callback),
      collection: this.state.collection || [],
      renderColumn: this.renderColumn.bind(this),
      renderSelect: this.renderSelect.bind(this),
      renderItemActions: this.renderItemActions.bind(this)
    };
  }

  constructor(props) {
    super(props);

    if (!props.resource) {
      throw new Error('You must define a resource in ResourceList props');
    }

    this.state.extraParameters = props.extraParameters;

    // Parse query to initialize filters values
    this.state.filters = {
      ...this.initialFiltersValues,
      ...qs.parse(router.currentSearch.substring(1))
    };

    // Bind function used outside component
    this.fetch = this.fetch.bind(this);
    this.orderBy = this.orderBy.bind(this);
  }

  /**
   * Initialize component
   */
  componentDidMount() {
    this.fetch();
    this.body = this.props.body;
    this.actions = this.props.actions;
    this.filters = this.props.filters;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.extraParameters !== this.props.extraParameters) {
      this.setState({ extraParameters: this.props.extraParameters });
    }
  }

  /**
   * Fetch collection with filters
   *
   * @param {object}  filters
   * @param {boolean} merge
   */
  fetch(filters, merge = true) {
    const navigating = filters && filters.page && Number(filters.page) !== Number(this.state.filters.page);

    // Reset page to 1 on perPage changes
    if (filters && filters.perPage && !filters.page) {
      filters.page = 1;
    }

    // Merge new filters with new one or reset filters with new value
    filters = merge
      ? object.merge(this.state.filters, filters)
      : object.merge(object.clone(this.initialFiltersValues), filters);

    // Remove all unused properties
    filters = object.unsetProperties(filters, ['', null, undefined]);

    this.setState({ loading: true, filters, navigating, selected: {} }, () => {
      // Stringify filters in query
      router.navigate(`${router.currentUri}?${qs.stringify(filters)}`);

      this.resource
        .list(filters, this.props.admin, this.state.extraParameters)
        .then((data) => {
          this.setState({
            collection: data['hydra:member'],
            totalItems: data['hydra:totalItems'],
            navigating: false,
            loading: false
          });

          // If user navigates between pages we scroll on top of the list
          if (navigating && this.listContainer) {
            this.listContainer.scrollIntoView();
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  }

  /**
   * Add order by for a field
   * Direction is determinate by current direction value
   *
   * @param {string} field
   */
  orderBy(field) {
    const { order } = this.state.filters;

    // If order field value is DESC it means that next step is to reset value
    // Scheme is "ASC" first then "DESC" and finally remove it from order by collection
    if (order && order[field] && order[field] === 'DESC') {
      delete order[field];

      const state = {
        filters: {
          ...this.state.filters,
          ...{ order }
        }
      };

      return this.setState(state, this.fetch);
    }

    // Add order by with 'ASC' then 'DESC'
    this.fetch({
      order: {
        [field]: !this.getOrderDir(field) ? 'ASC' : 'DESC'
      }
    });
  }

  /**
   * Get current direction order of field
   *
   * @param {string} field
   *
   * @return {?string}
   */
  getOrderDir(field) {
    return this.state.filters.order !== undefined && this.state.filters.order[field];
  }

  /**
   * Reset filters value to initial values
   *
   * @param {function} setValues
   */
  resetFilters(setValues) {
    this.fetch({}, false);
    setValues(this.initialFiltersValues);
  }

  /**
   * Select item id to make massive actions
   *
   * @param {string|number} id - '*' means the whole collection
   */
  select(id) {
    const { collection, selected } = this.state;

    if (id === '*') {
      const selected = {};

      for (let i = 0, len = collection.length; i < len; ++i) {
        selected[collection[i].id] = true;
      }

      return this.setState({ selected });
    }

    if (!selected[id]) {
      selected[id] = true;
      this.setState({ selected });
    }
  }

  /**
   * Unselect item id in collection to make massive actions
   *
   * @param {string|number} id - '*' means the whole collection
   */
  unselect(id) {
    if (id === '*') {
      return this.setState({ selected: {} });
    }

    const { selected } = this.state;
    delete selected[id];
    this.setState({ selected });
  }

  /**
   * Toggle selection of an item
   *
   * @param {string|number} id
   */
  toggleSelect(id) {
    const { selected, collection } = this.state;

    const method =
      (id === '*' && Object.keys(selected).length === collection.length) || selected[id] !== undefined
        ? 'unselect'
        : 'select';
    return this[method](id);
  }

  /**
   * Run callback of a multiple action
   *
   * @param {?callback} callback
   */
  handleMultipleAction(callback) {
    const { selected } = this.state;
    const ids = Object.keys(selected);

    if (ids.length === 0) {
      return notifications.warning(t('action_ignored'), t('no_item_selected_in_list'));
    }

    typeof callback === 'function' && callback(ids, this.bodyProps);
  }

  /**
   * Run deletion of item(s)
   *
   * @param {string|number[]|string[]|number[]} id
   */
  handleDelete(id) {
    const isMultiple = Array.isArray(id);

    this.context.confirm(
      <div>
        {t('confirm_delete')}`: ${isMultiple ? id.join(', ') : id}`
      </div>,
      () => {
        this.setState({ loading: true }, () => {
          const method = isMultiple ? 'deleteMultiple' : 'delete';
          try {
            this.resource[method](isMultiple ? { id } : id).then(() => this.fetch());
          } catch (e) {
            logger.error('ADMIN', 'DELETE', { e });
          }
        });
      }
    );
  }

  handleDuplicate(id) {
    this.context.confirm(
      <div>
        {t('confirm_duplicate')}`: ${id}`
      </div>,
      () => {
        this.setState({ loading: true }, () => {
          try {
            this.resource['duplicate'](id).then(() => this.fetch());
          } catch (e) {
            logger.error('ADMIN', 'DUPLICATE', { e });
          }
        });
      }
    );
  }

  /**
   * Build multiple actions options
   *
   * @return {object[]}
   */
  buildMultipleActionsOptions() {
    const multipleActions = [...(this.props.multipleActions || [])];

    if (this.props.deleteMultiple && this.resource.canDeleteMultiple()) {
      multipleActions.push({
        label: (
          <>
            <Icon name="trash-alt" className="mr-2" /> {t('delete')}
          </>
        ),
        value: () => this.handleDelete(Object.keys(this.state.selected))
      });
    }

    return multipleActions;
  }

  /**
   * Build column and add orderable event on it
   *
   * @param {string}   field          - Field path
   * @param {?boolean} orderable      - To define only if you want to make column orderable
   * @param {?object}  props          - Props to inject in th tag
   *
   * @return {JSX.Element}
   */
  renderColumn(field, orderable = true, props = {}) {
    const label = typeof field !== 'string' ? field : this.resource.getTranslationKey(field);

    return (
      <th scope="col" onClick={() => orderable && this.orderBy(field)} className={field && 'pointer'} {...props}>
        {typeof label === 'string' ? t(label) : label}
        {orderable && <Icon name="sort" className={`ml-2 ${this.getOrderDir(field) ? 'text-grey' : 'text-light'}`} />}
      </th>
    );
  }

  /**
   * Render item checkbox for multiple selection
   *
   * @param {string|number} itemId
   *
   * @return {JSX.Element}
   */
  renderSelect(itemId) {
    const { selected } = this.state;
    const keys = Object.keys(selected);
    const checked =
      itemId === '*' ? keys.length > 0 && keys.length === this.state.collection.length : selected[itemId] !== undefined;
    return (
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          checked={checked}
          id={`list-checkbox-${itemId}`}
          className="custom-control-input"
          onChange={() => this.toggleSelect(itemId)}
        />
        <label className="custom-control-label" htmlFor={`list-checkbox-${itemId}`} />
      </div>
    );
  }

  /**
   * Render button for navigate to create view
   *
   * @return {JSX.Element|null}
   */
  renderCreateButton() {
    if (!this.resource.canCreate()) {
      return null;
    }
    return (
      <Button
        color="default"
        type="button"
        className="mb-3"
        href={router.resourcePath(this.resource.alias, 'create')}
        target="_blank"
      >
        <Icon name="plus-circle" className="mr-2" />
        {t('create')}
      </Button>
    );
  }

  /**
   * Render ellipsis button with actions for an item
   *
   * @param {object}              item
   * @param {function|JSX.Element }actions
   *
   * @return {JSX.Element}
   */
  renderItemActions(item, actions, showDuplicate = false, context = 'update') {
    return (
      <UncontrolledDropdown>
        <DropdownToggle color="" className="btn-icon-only text-light bg-transparent" role="button" size="sm">
          <Icon name="ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          {this.resource.canUpdate() && (
            <DropdownItem href={router.resourcePath(this.resource.alias, context, { id: item.id })} target="_blank">
              <Icon name="pen-alt" /> {t('edit')}
            </DropdownItem>
          )}
          {typeof actions === 'function' && actions(DropdownItem) ? actions(DropdownItem) : actions}

          {showDuplicate && (
            <DropdownItem onClick={() => this.handleDuplicate(item.id)}>
              <Icon name="copy" /> {t('duplicate')}
            </DropdownItem>
          )}

          {this.resource.canDelete() && (
            <DropdownItem onClick={() => this.handleDelete(item.id)}>
              <Icon name="trash-alt" /> {t('delete')}
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  /**
   * @return {JSX.Element}
   */
  render() {
    const { loading, totalItems, filters, navigating } = this.state;
    const Body = this.body;
    const Filters = this.filters;
    const isAdmin = security.isGranted('ROLE_ADMIN') || security.isGranted('ROLE_SUPER_ADMIN');
    const Actions = this.actions;
    const multipleActions = this.buildMultipleActionsOptions();

    return (
      <div className="crud-list">
        <Formik initialValues={filters} onSubmit={(v) => this.fetch(v, false)}>
          {(formik) => {
            const [initContext, setInitContext] = useState(true);

            useEffect(() => {
              formik.setFormikState({ ...formik, resource: this.resource });
              setInitContext(false);
            }, []); // eslint-disable-line react-hooks/exhaustive-deps

            return (
              !initContext && (
                <>
                  {this.renderCreateButton()}
                  {Actions && <Actions />}
                  {isAdmin && Filters && (
                    <Card>
                      <CardBody className={`bg-transparent${loading ? ' loading' : ''}`}>
                        <Form role="form" noValidate onSubmit={formik.handleSubmit}>
                          <div className="list-filters">
                            <Filters {...formik} />
                          </div>
                          <div className="text-center pt-3">
                            <Button outline color="primary" size="sm" type="submit">
                              <Icon name="filter" className="mr-1" />
                              {t('filter')}
                            </Button>
                            <Button
                              outline
                              color="warning"
                              size="sm"
                              onClick={() => this.resetFilters(formik.setValues)}
                            >
                              <Icon name="eraser" className="mr-1" />
                              {t('reset')}
                            </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  )}
                  <div className="f-height flex align-items-center justify-content-center mb-2 mt-2">
                    {(!loading || navigating) && (
                      <span className={`text-center ${Filters ? 'text-grey' : 'text-white'}`}>
                        <small className="font-weight-bold">{t('nb_result', totalItems)}</small>
                      </span>
                    )}
                  </div>
                  <Card className="shadow" id="list-table-container" innerRef={(el) => (this.listContainer = el)}>
                    <CardHeader className="pt-3 pb-3">
                      <div className="flex align-items-center justify-content-between">
                        {multipleActions === undefined || multipleActions.length === 0 ? (
                          <span />
                        ) : (
                          <div style={{ minWidth: '150px' }}>
                            <SimpleSelect
                              placeholder={translator.translate('actions')}
                              isSearchable={false}
                              isDisabled={loading}
                              options={multipleActions}
                              noOptionsMessage={() => <small>-</small>}
                              value={null}
                              onChange={({ value }) => this.handleMultipleAction(value)}
                              styles={selectHelper.buildStyles('sm')}
                            />
                          </div>
                        )}
                        <Select
                          size="sm"
                          label={false}
                          name="perPage"
                          groupTag="span"
                          isDisabled={loading}
                          className="input-per-page"
                          options={this.perPageOptions.map((value) => ({
                            label: `${value} / ${translator.translate('page')}`,
                            value
                          }))}
                          onSelect={(perPage) => this.fetch({ perPage })}
                        />
                      </div>
                    </CardHeader>
                    <CardBody className="p-0">
                      {loading || !Body ? (
                        <div className="text-center pt-3 pb-3">
                          <Spinner />
                        </div>
                      ) : (
                        <Body {...this.bodyProps} />
                      )}
                    </CardBody>
                  </Card>

                  {/* Show pagination only if pages > 1 */}

                  {(!loading || navigating) && Math.ceil(totalItems / filters.perPage) > 1 && (
                    <nav className="flex justify-content-center mt-3">
                      <Pagination
                        perPage={filters.perPage}
                        total={totalItems}
                        loading={loading}
                        active={Number(filters.page)}
                        onClick={(page) => this.fetch({ page })}
                        disabled={loading}
                      />
                    </nav>
                  )}
                </>
              )
            );
          }}
        </Formik>
      </div>
    );
  }
}
