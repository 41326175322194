import { useField, useFormikContext } from 'formik';
import React from 'react';
import { FormFeedback } from 'reactstrap';

import { t } from '@/services/translator';

export default ({ name, ...props }) => {
  const { submitCount } = useFormikContext();
  const data = useField({ name: name });
  const { touched, error } = data[1];

  // if nothing happen and no error occured, and u check here, its probably a missing field.
  if ((touched || submitCount > 0) && typeof error === 'string') {
    return <FormFeedback {...props}>{t(error)}</FormFeedback>;
  }

  return null;
};
