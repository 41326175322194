/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { Col, Row } from 'reactstrap';

import logger from '@/services/logger';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

import WysiwygEditor from '../wysiwyg';

const WysiwygTranslator = ({ name }) => {
  const [field, , helper] = useField({ name });
  const [values, setValues] = useState();
  const [timer, setTimer] = useState();

  const fetchOptions = async (terms) => {
    try {
      const response = await getResource('Language').list({ name: terms });

      if (!response || !response['hydra:member'] || !Array.isArray(response['hydra:member'])) {
        return [];
      }

      return response['hydra:member'].map((lang) => {
        const langValue = { value: lang.locale, label: lang.name };

        if (lang.locale === 'fr' && !values) {
          setValues(langValue);
        }

        return langValue;
      });
    } catch (e) {
      logger.error('Langue fetcher', 'option', e);
      return [];
    }
  };

  /**
   * @param {string} value
   */
  const loadAsyncOptions = (terms) => {
    clearTimeout(timer);

    return new Promise((resolve) => {
      const newTimer = setTimeout(() => {
        resolve(fetchOptions(terms));
      }, 500);

      setTimer(newTimer);
    });
  };

  useEffect(() => {
    if (!field.value || !Array.isArray(field.value)) {
      return;
    }

    helper.setValue({ fr: '' });
  }, [field.value]);

  return (
    <Row>
      <Col className="bg-light my-2 py-2">
        <AsyncSelect
          loadOptions={loadAsyncOptions}
          cacheOptions
          defaultOptions
          value={values}
          placeholder={t('weverapp_select_locale')}
          onChange={(option) => setValues(option)}
          className="w-175px"
        />
        {!Array.isArray(field.value) && values?.value && <WysiwygEditor name={`${name}[${values.value}]`} />}
      </Col>
    </Row>
  );
};

WysiwygTranslator.propTypes = {
  name: PropTypes.string.isRequired,
  inputType: PropTypes.string
};

WysiwygTranslator.defaultProps = {
  inputType: 'text'
};

export default WysiwygTranslator;
