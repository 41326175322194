/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, CardBody } from 'reactstrap';

import View from '@/components/view';
import Context from '@/services/context';
import logger from '@/services/logger';
import getResource from '@/services/resources';

import AccordionItem from './accordion-item';
import Viewer from './viewer';

export default () => {
  const { community, locale } = useContext(Context);
  const [communities, setCommunities] = useState();
  const [selected, setSelected] = useState();
  const refCard = useRef();

  const loadMapCommunities = async () => {
    const list = await getResource('Community').managedDashboard(true, community, false);

    setCommunities(list);
  };

  const handleOnSelect = (communityId, report, dashboard, name) => {
    setSelected({ communityId, report, dashboard, locale, name });
  };

  useEffect(() => {
    loadMapCommunities();
  }, [community]);

  if (!Array.isArray(communities)) {
    logger.error('Manager', 'dashboard', 'cannot display communities', { communities, locale });
  }

  return (
    <View>
      <Card style={{ height: '100%' }} ref={refCard}>
        <CardBody style={{ position: 'relative', overflow: 'auto', padding: 0 }}>
          {!selected &&
            Array.isArray(communities) &&
            communities.map((communityMapped, index) => {
              if (!communityMapped) {
                logger.error('Manager', 'dashboard', 'cannot display community', { index, communities, locale });
                return null;
              }

              return <AccordionItem community={communityMapped} key={communityMapped.id} onSelect={handleOnSelect} />;
            })}
          {selected && <Viewer {...selected} onClose={() => setSelected(null)} />}
        </CardBody>
      </Card>
    </View>
  );
};
