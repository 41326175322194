import { useField } from 'formik';
import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import { t } from '@/services/translator';

import Instance from './instance';

const Instances = ({ getFieldHelpers }) => {
  const [instancesField, , helpers] = useField({ name: 'instances' });

  const addInstance = () => {
    const newInstances = instancesField.value ? [...instancesField.value] : [];
    newInstances.push({ name: '', query: '', calculations: null });
    helpers.setValue(newInstances);
  };

  const deleteInstance = (index) => {
    const instances = [...instancesField.value];
    instances.splice(index);
    helpers.setValue(instances);
  };

  const cloneInstance = (index) => {
    const instances = [...instancesField.value];
    const instance = JSON.parse(JSON.stringify(instances[index]));

    instance.id = null;
    instance['@id'] = null;
    instance.name = instance.name + '-- COPY';

    if (Array.isArray(instance.calculations)) {
      instance.calculations.forEach((calculation) => {
        calculation.id = null;
        calculation['@id'] = null;
      });
    }
    instances.push(instance);
    helpers.setValue(instances);
  };

  const calcInstance = (index) => {
    const instances = [...instancesField.value];
    instances.splice(index);
    helpers.setValue(instances);
  };

  return (
    <Row className="border-top mt-2 pt-3">
      <Col lg="12">
        <Button className="btn-wever bg-pink-wever" size="sm" onClick={addInstance}>
          {t('adminapp_add_report_instance')}
        </Button>
      </Col>
      {instancesField.value &&
        instancesField.value.map((instance, index) => {
          return (
            <Col className="col-12 mb-1 mt-2" key={index}>
              <Instance
                index={index}
                getFieldHelpers={getFieldHelpers}
                removeHandler={() => deleteInstance(index)}
                cloneHandler={() => cloneInstance(index)}
                calcHandler={() => calcInstance(index)}
              />
            </Col>
          );
        })}
    </Row>
  );
};

Instances.propTypes = {
  // values: PropTypes.shape({ points: PropTypes.array, lines: PropTypes.array, polygons: PropTypes.array }).isRequired,
  // getFieldHelpers: PropTypes.func.isRequired
};

export default Instances;
