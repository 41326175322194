import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Error, Input, Select } from '@/components/form';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

const Calculations = ({ index, getFieldHelpers }) => {
  const [calculationParametersLoading, setCalculationParametersLoading] = useState(true);
  const [calculationParameters, setCalculationParameters] = useState({});
  const [calculationParametersOptions, setCalculationParametersOptions] = useState({});

  const [calculationsField] = useField({ name: `instances[${index}].calculations` });
  const { setValue, setError, setTouched } = getFieldHelpers(`instances[${index}].calculations`);
  const { value } = calculationsField;

  const getDefaultValue = (type, param) => {
    const params = calculationParameters[type];

    return params !== undefined && params[param] !== undefined ? String(params[param]) : null;
  };

  useEffect(() => {
    getResource('Report')
      .fetchCalculationTypeParams()
      .then((calculationParametersResult) => {
        const options = Object.keys(calculationParametersResult).map((key) => ({ value: key, label: t(key) }));
        setCalculationParametersOptions(options);
        setCalculationParameters(calculationParametersResult);
        setCalculationParametersLoading(false);
      });
  }, []);

  return (
    <Row>
      <Error name="calculations" />
      {(value || []).map((calculation, i) => (
        <Col lg={4} key={i}>
          <Card className="mb-3 shadow" key={`card_${i}`}>
            <CardHeader className="p-2 flex justify-content-between align-items-center">
              <h5 className="m-0">
                {t('calculation')} {i + 1}
              </h5>
              <Button
                size="sm"
                color="gray"
                outline
                className="shadow-none"
                onClick={() => {
                  value.splice(i, 1);
                  setValue(value);
                  setError({});
                  setTouched(false);
                }}
              >
                <Icon name="times" />
              </Button>
            </CardHeader>
            <CardBody className="p-3">
              <Row key={`row_${i}`}>
                <Col lg={6}>
                  <Input name={`instances[${index}].calculations[${i}].alias`} />
                </Col>
                <Col lg={6}>
                  <Select
                    name={`instances[${index}].calculations[${i}].type`}
                    isLoading={calculationParametersLoading}
                    isDisabled={calculationParametersLoading}
                    options={calculationParametersOptions}
                    onSelect={(type) => {
                      const defaultParams = calculationParameters[type];
                      const parameters = Object.keys(defaultParams).map((name) => ({ name }));

                      value[i].type = type;
                      value[i].parameters = parameters;

                      setValue(value);
                    }}
                  />
                </Col>
                <Col lg={12}>
                  <Table>
                    <thead>
                      <tr>
                        <th>{t('report.calculation.parameter')}</th>
                        <th>{t('report.calculation.value')}</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {!calculationParametersLoading &&
                        (calculation.parameters || []).map((parameters, n) => (
                          <tr key={`parameter_${n}`}>
                            <td className="p-2">
                              <Input
                                name={`instances[${index}].calculations[${i}].parameters[${n}].name`}
                                label={false}
                                groupTag="span"
                              />
                            </td>
                            <td className="p-2">
                              <Input
                                name={`instances[${index}].calculations[${i}].parameters[${n}].value`}
                                label={false}
                                groupTag="span"
                                placeholder={getDefaultValue(value[i].type, value[i].parameters[n].name)}
                              />
                            </td>
                            <td className="p-2">
                              <Button
                                size="sm"
                                color="gray"
                                outline
                                className="shadow-none"
                                onClick={() => {
                                  const tmpValue = [...value];
                                  tmpValue[i].parameters.splice(n, 1);
                                  setValue(tmpValue);
                                }}
                              >
                                <Icon name="times" />
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <div className="p-2">
                    <Button
                      color="light"
                      outline
                      size="sm"
                      onClick={() => {
                        const tmpValue = [...value];
                        tmpValue[i].parameters = tmpValue[i].parameters || [];
                        tmpValue[i].parameters.push({});
                        setValue(tmpValue);
                      }}
                    >
                      <Icon name="plus-circle" /> {t('add_parameter')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      ))}
      <Col lg="12">
        <Button
          size="sm"
          color="secondary"
          className="mb-3"
          onClick={() => {
            const tmpValue = value ? [...value] : [];
            tmpValue.push({});
            setValue(tmpValue);
          }}
        >
          <Icon name="plus-circle" /> {t('add_calculation')}
        </Button>
      </Col>
    </Row>
  );
};

Calculations.propTypes = {
  index: PropTypes.number.isRequired,
  getFieldHelpers: PropTypes.func.isRequired
};

export default Calculations;
