/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';

import Icon from '@/components/common/icon';
import View from '@/components/view';
import notifications from '@/services/notifications';
import getResource from '@/services/resources';
import { t } from '@/services/translator';
import Actions from '@/views/actions/actions';

const ActionsDashboard = ({ values, setFieldValue }) => {
  const [isSpinnerOn, setIsSpinnerOn] = useState(false);

  const SubmitActions = async () => {
    setIsSpinnerOn(true);

    try {
      await getResource('SheetAction').update(values);
    } catch (e) {
      notifications.error(t('download'), t('error_cannot_fetch_resource'));
      return;
    }
    setIsSpinnerOn(false);
  };

  return (
    <View>
      <Actions values={values} setFieldValue={setFieldValue} />
      <Button color="primary" disabled={isSpinnerOn} onClick={SubmitActions} className="m-4">
        {isSpinnerOn ? <Spinner size="sm" className="mr-2" /> : <Icon name="check" className="mr-2" />}
        {t('button_update_actions')}
      </Button>
    </View>
  );
};

export default ActionsDashboard;
