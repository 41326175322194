/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import 'wever-maps/src/assets/style.scss';

import '@/assets/scss/components/tab-sheet-action.scss';
import View from '@/components/view';
import Api from '@/services/api';
import { t } from '@/services/translator';

const Index = ({ match }) => {
  const [collection, setCollection] = useState();
  const [email, setEmail] = useState();
  const [file, setFile] = useState();

  const upload = async () => {
    Api.defaultContentType = 'multipart/form-data';
    const body = new FormData();
    body.append('collection', collection);
    body.append('email', email);
    body.append('file', file);
    await Api.post('/imports', body);
  };

  return (
    <View>
      <Row>
        <Col lg={6}>
          <div>{t('adminapp_email')}</div>
          <Input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Col>
        <Col lg={6}>
          <div>{t('adminapp_collection')}</div>
          <Input type="text" value={collection} onChange={(e) => setCollection(e.target.value)} />
        </Col>
        <Col lg={12} className="mt-2">
          <div>{t('adminapp_file')}</div>
          <Input type="file" onChange={(e) => setFile(e.target.files[0])} />
        </Col>

        <Col sm="12" md="4" lg="3" className="mt-5">
          <Button type="button" onClick={upload}>
            {t('admin_app_import_file')}
          </Button>
        </Col>
      </Row>
    </View>
  );
};
export default Index;
