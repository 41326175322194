import React, { useContext } from 'react';
import { Col, Row, Table } from 'reactstrap';

import { Input } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';
import Context from '@/services/context';

export default () => {
  const { locale } = useContext(Context);

  const getName = (tag) => {
    if (tag.names && Object.keys(tag.names).length > 0) {
      return <>{tag.names[locale]} (v2)</>;
    }

    return tag.name || tag.alias;
  };

  return (
    <View>
      <ResourceList
        resource="Tag"
        deleteMultiple
        filters={() => (
          <Row>
            <Col lg={3}>
              <Input name="name" />
            </Col>
          </Row>
        )}
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                {renderColumn(renderSelect('*'), false)}
                {renderColumn('id')}
                {renderColumn('name')}
                {renderColumn('alias')}
                {renderColumn()}
              </tr>
            </thead>
            <tbody>
              {collection.map((item) => (
                <tr key={item.id}>
                  <td>{renderSelect(item.id)}</td>
                  <td>{item.id}</td>
                  <td>{getName(item)}</td>
                  <td>{item.alias || ''}</td>
                  <td>{renderItemActions(item)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </View>
  );
};
