/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Table } from 'reactstrap';
import 'wever-maps/src/assets/style.scss';

import '@/assets/scss/components/tab-sheet-action.scss';
import Date from '@/components/common/date';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';

const Index = ({ match }) => {
  return (
    <View>
      <ResourceList
        resource="CommandQueue"
        deleteMultiple
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                {renderColumn(renderSelect('*'), false)}
                {renderColumn('id')}
                {renderColumn('command')}
                {renderColumn('state')}
                {renderColumn('createdAt')}
                {renderColumn('updatedAt')}
              </tr>
            </thead>
            <tbody>
              {collection.map((item) => (
                <tr key={item.id}>
                  <td>{renderSelect(item.id)}</td>
                  <td>{item.id}</td>
                  <td>{item.command}</td>
                  <td>{item.state}</td>
                  <td>
                    <Date time>{item.createdAt}</Date>
                  </td>
                  <td>{item.updatedAt && <Date time>{item.updatedAt}</Date>}</td>
                  <td>{renderItemActions(item)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </View>
  );
};
export default Index;
