import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FormGroup, Row, Col, Button } from 'reactstrap';

import Media from '@/components/common/media';
import Uploader from '@/components/common/upload';
import { Error, Label } from '@/components/form';
import { t } from '@/services/translator';

export default ({
  name,
  nameProp,
  label,
  type,
  size = 'sm',
  resource = null,
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  groupProps = {},
  labelProps = {},
  errorProps = {},
  mineTypeRestriction,
  ...props
}) => {
  const [field, meta, helper] = useField({ name });
  const [file, setFile] = useState(field.value);
  const { submitCount } = useFormikContext();

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  const handleFileChange = async (media, mediaObject) => {
    await media;
    helper.setValue(media);
    setFile(mediaObject);
  };

  return (
    <GroupTag {...groupProps}>
      {/* Label */}
      {label && (
        <LabelTag name={name} size={size} {...labelProps}>
          {label}
        </LabelTag>
      )}
      <Row>
        <Col md="3" className="col-3">
          {field.value && <Media data={file} className="media-input" />}
        </Col>
        <Col>
          <Uploader
            mineTypeRestriction={mineTypeRestriction}
            component={({ onClick }) => (
              <Button type="button" onClick={onClick} color="default">
                {t('upload')}
              </Button>
            )}
            multiple={props.multiple}
            onChange={handleFileChange}
            resource={resource}
            name={nameProp || name}
            invalid={(meta.touched || submitCount > 0) && meta.error !== undefined}
          />
        </Col>
      </Row>
      {/* Errors */}
      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
