/* eslint-disable global-require */

export default [
  {
    path: 'viz',
    name: 'viz',
    component: require('@/components/layout/view-layout').default,
    roles: ['ROLE_MANAGER'],
    routes: [
      {
        path: '/map_list',
        name: 'map',
        title: 'map_list_title',
        exact: true,
        component: require('@/views/manager/map').default
      },
      // {
      //   path: '/map_create',
      //   name: 'map',
      //   exact: true,
      //   component: require('@/views/maps/form').default
      // },
      {
        path: '/dashboard_list',
        name: 'dashboard_list',
        title: 'dashboard_list_title',
        roles: ['ROLE_MANAGER'],
        exact: true,
        component: require('@/views/manager/dashboard').default
      },
      // {
      //   path: '/dashboard_create',
      //   name: 'dashboard_create',
      //   roles: ['ROLE_ACCOUNT_MANAGER'],
      //   exact: true,
      //   component: require('@/views/dashboard-user/layout/form').default
      // }
      // {
      //   path: '/graph_list',
      //   name: 'create',
      //   component: require('@/views/report-pattern/form').default,
      //   title: 'report_pattern_create_title',
      //   permissions: {
      //     ReportPattern: ['create']
      //   }
      // },
      {
        path: '/sheet_actions',
        name: 'sheet_actions',
        title: 'sheet_actions_list_title',
        roles: ['ROLE_MANAGER'],
        exact: true,
        component: require('@/views/manager/sheet-action').default
      },
      {
        path: '/gantts',
        name: 'gantts',
        title: 'gantts_list_title',
        roles: ['ROLE_MANAGER'],
        exact: true,
        component: require('@/views/manager/gantts').default
      },
      {
        path: '/gantts_charts',
        name: 'gantts_charts',
        title: 'gantts_charts_list_title',
        roles: ['ROLE_MANAGER'],
        exact: true,
        component: require('@/views/manager/gantts/viewer').default
      }
    ]
  }
];
