export default {
  queries: [
    {
      name: 'get_users_by_diagnostic_ids_request',
      query: [
        {
          $match: {
            source: 'response',
            'data.get_user_diagnostics_list': {
              $in: [8]
            }
          }
        },
        {
          $lookup: {
            from: 'user',
            localField: 'data.id',
            foreignField: '_id',
            as: 'user_data'
          }
        },
        {
          $project: {
            data: 1
          }
        }
      ]
    },
    {
      name: 'get_users_by_community_ids_request',
      query: [
        {
          $match: { source: 'response' }
        },
        {
          $lookup: {
            from: 'user',
            localField: 'data.id',
            foreignField: '_id',
            as: 'user'
          }
        },
        {
          $match: {
            'user.communities': {
              $in: [25]
            }
          }
        },
        {
          $project: {
            data: 1
          }
        }
      ]
    },
    {
      name: 'get_users_by_report_id_request',
      query: [
        {
          $match: { source: 'response' }
        },
        {
          $lookup: {
            from: 'user',
            localField: 'data.id',
            foreignField: '_id',
            as: 'user'
          }
        },
        {
          $match: {
            'user.report': 18356
          }
        },
        {
          $project: {
            data: 1
          }
        }
      ]
    }
  ]
};
