import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Table, Button } from 'reactstrap';

import Icon from '@/components/common/icon';
// Resource
import getResource from '@/services/resources';
import { t } from '@/services/translator';

import _Form from '@/components/form';
const { Autocomplete, Error } = _Form;

const PartnerTable = ({ name }) => {
  const [field, ...helper] = useField({ name });

  const handleAddPartner = async () => {
    let values = field.value;

    if (!values || !Array.isArray(values)) {
      values = [];
    }

    const resp = await getResource('CommunityPartner').create({});
    values.push(resp);

    helper[1].setValue(values);
  };

  const handleRemovePartner = (index) => {
    const values = field.value;

    if (!values || !Array.isArray(values)) {
      return;
    }

    values.splice(index, 1);

    helper[1].setValue(values);
  };

  /** @todo a refaire propre */
  const handleAutocompleteOnChange = (value, index, type) => {
    const values = field.value;

    values[index][type] = value;

    helper[1].setValue(values);

    const objectToUpdate = JSON.parse(JSON.stringify(values[index]));

    objectToUpdate[type] = value['@id'];
    if (type !== 'partner') {
      if (!objectToUpdate.partner) {
        delete objectToUpdate.partner;
      } else {
        objectToUpdate.partner = objectToUpdate.partner['@id'] || objectToUpdate.partner;
      }
    } else if (type !== 'type') {
      if (!objectToUpdate.type) {
        delete objectToUpdate.type;
      } else {
        objectToUpdate.type = objectToUpdate.type['@id'] || objectToUpdate.type;
      }
    }

    getResource('CommunityPartner').update(objectToUpdate);
  };

  return (
    <FormGroup>
      <br />
      <Button size="sm" type="button" color="info" onClick={handleAddPartner}>
        {t('add')}
      </Button>

      <Table>
        <thead>
          <tr>
            <th>{t('partner')}</th>
            <th>{t('type')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {field.value &&
            field.value.map((communityPartner, index) => (
              <tr key={index}>
                <td>
                  <Autocomplete
                    value={communityPartner.partner}
                    onChange={(value) => handleAutocompleteOnChange(value, index, 'partner')}
                    clearable
                    resource="ContractPartner"
                    display="name"
                    tag="name"
                    autoload
                  />
                </td>
                <td>
                  <Autocomplete
                    value={communityPartner.type}
                    onChange={(value) => handleAutocompleteOnChange(value, index, 'type')}
                    clearable
                    resource="PartnerType"
                    display="key"
                    tag="key"
                    autoload
                  />
                </td>
                <td>
                  <Button size="sm" color="danger" onClick={() => handleRemovePartner(index)}>
                    <Icon name="trash" />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Error name={name} />
    </FormGroup>
  );
};

PartnerTable.propTypes = {
  name: PropTypes.string.isRequired
};

export default PartnerTable;
