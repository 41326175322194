import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row, Card, CardBody, Button, Alert } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Editor, Input, Autocomplete } from '@/components/form';
import { t } from '@/services/translator';

import Calculations from './calculations';
import DataView from './data-view';
import Fields from './fields';
import useInstance from './use-instance';

const Index = ({ index, getFieldHelpers, removeHandler, cloneHandler, calcHandler }) => {
  const [nameField] = useField({ name: `instances[${index}].name` });
  const [open, setOpen] = useState(false);
  const { instanceTypeName } = useInstance(index);

  return (
    <Row>
      <Col sm="12">
        <Button
          color="transparent"
          className={`bg-transparent ${!open ? 'text-info' : ''}`}
          onClick={() => setOpen(!open)}
        >
          <span className="mr-1">
            <Icon name={`chevron-${open ? 'down' : 'up'}`} />{' '}
          </span>
          {t('adminapp_instance_number')} - {index} {nameField.value ? `(${nameField.value})` : null}
        </Button>
      </Col>
      {open && (
        <Col sm="12">
          <Card className="mt-2 bg-wever-light">
            <CardBody>
              <Row className="m-0">
                <Col className="col-12 d-flex justify-content-end">
                  <div className="border-bottom">
                    <Button color="transparent" className="mr-1" title="clone" disabled>
                      <Icon name="download" />
                    </Button>
                    <Button color="transparent" className="mr-1" title="clone" onClick={cloneHandler}>
                      <Icon name="clone" />
                    </Button>
                    <Button color="transparent" className="mr-1" title="calc" onClick={calcHandler}>
                      <Icon name="sync" />
                    </Button>
                    <Button color="transparent" className="text-danger" onClick={removeHandler}>
                      <Icon name="trash" />
                    </Button>
                  </div>
                </Col>
                <Col lg={6}>
                  <Input name={`instances[${index}].name`} />

                  <Autocomplete
                    name={`instances[${index}].type`}
                    resource="ReportInstanceType"
                    display="name"
                    tag="name"
                    autoload
                  />
                </Col>
                <Col lg={6}>
                  <Editor name={`instances[${index}].query`} height="200" />
                </Col>
              </Row>
              {(instanceTypeName === 'user' || instanceTypeName === 'community') && (
                <Row>
                  <Col lg="12">
                    <Calculations getFieldHelpers={getFieldHelpers} index={index} />
                  </Col>
                </Row>
              )}
              {instanceTypeName === 'diagnostic' && (
                <>
                  <Alert color="info">
                    {t('adminapp_all_diagnostic_or_field_update_will_create_an_automatic_query')}
                  </Alert>
                  <Autocomplete
                    name={`instances[${index}].diagnostic`}
                    resource="Diagnostic"
                    display="alias"
                    tag="alias"
                    autoload
                  />
                  <Row>
                    <Col lg="12">
                      <Fields getFieldHelpers={getFieldHelpers} index={index} />
                    </Col>
                  </Row>
                </>
              )}

              <DataView index={index} />
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  );
};

Index.propTypes = {
  index: PropTypes.number.isRequired
};

export default Index;
