import React from 'react';
import { useField } from 'formik';
// Style
import { Button, FormGroup, Col, Card, Label } from 'reactstrap';
// Core
import Icon from '@/components/common/icon';
import {  t } from '@/services/translator';
import Copil from './information-sheet-copil';

const Copils = () => {
  const [copils, , helperCopils] = useField({ name: 'copils' });

  const addCopil = () => {
    helperCopils.setValue([...copils.value, { name: '', lastname: '', job: '', email: '', phone: '' }]);
  };

  const removeCopil = (idx) => {
    const newCopil = [...copils.value];
    newCopil.splice(idx, 1);
    helperCopils.setValue(newCopil);
  };
  return (
    <>
      <FormGroup row>
        <Label sm={2} className="font-weight-bold">
          {t('communitiesapp_referent_mobility')} :
        </Label>
      </FormGroup>
      {Array.isArray(copils.value) &&
        copils.value.map((val, idx) => (
          <div key={`manager-${idx}`}>
            {idx === 0 ? null : (
              <FormGroup row>
                <Label sm={2} className="font-weight-bold">
                  {t('communitiesapp_member_other')} :
                </Label>
              </FormGroup>
            )}
            <Card className="bg-light mt-3 p-3 w-75">
              <div className="informationsheetSiteposition">
                <Copil idx={idx} />
                {copils.value.length < 2 && idx === 0 ? null : (
                  <Button
                    type="button"
                    className="bg-white rounded-circle border border-danger"
                    style={{ position: 'absolute', top: '-25px', right: '-25px' }}
                    onClick={() => removeCopil(idx)}
                  >
                    <Icon name="times" className="text-danger" />
                  </Button>
                )}
              </div>
            </Card>
          </div>
        ))}
      <FormGroup row>
        <Col sm={7}>
          <Button
            type="button"
            className="shadow-none informationsheetBtncolor mx-auto mt-3 rounded-pill"
            onClick={addCopil}
          >
            <Icon name="plus-circle" className="me-4" />
            {t('communitiesapp_add_members')}
          </Button>
        </Col>
      </FormGroup>
    </>
  );
};

export default Copils;
