/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import 'wever-maps/src/assets/style.scss';

import '@/assets/scss/components/tab-sheet-action.scss';
import Loader from '@/components/common/loader';
import Tabs from '@/components/common/tabs';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import Api from '@/services/api';
import Context from '@/services/context';
import notif from '@/services/notifications';
import getResource from '@/services/resources';
import security from '@/services/security';
import { t } from '@/services/translator';

import Actions from './actions';
import Description from './description';
import DashboardTabs from './show_dashboard';

export default ({ match }) => {
  // const [permitDiscussion, setPermitDiscussion] = useState(false);
  const [load, setLoad] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [isFound, setIsFound] = useState(true);
  const [communityId, setCommunity] = useState();
  const { community } = useContext(Context);
  const [loading, setLoading] = useState(null);

  // const communityHasActionSheet = async () => {
  // const response = await getResource('Community').managedList();
  // setPermitDiscussion(
  //   response.some((community) =>
  //   community.actions && community.actions.some(
  //       (action) => parseInt(action.sheetAction.replace('/sheet_actions/', '')) === parseInt(match.params.id)
  //     )
  //   )
  // );
  // };

  const verifyExistance = async () => {
    setLoading(true);
    const commuId = parseInt(community.replace('/communities/', ''));
    const element = await getResource('Community').getSheetActions(community);
    let isFoundElement = false;
    let sheetActions = null;

    if (element[0] && element[0].sheetActions) {
      sheetActions = element[0].sheetActions;
    }

    sheetActions.forEach((element) => {
      let idSheetAction = parseInt(element.sheetAction.replace('/sheet_actions/', ''));
      if (idSheetAction === parseInt(match.params.id)) {
        isFoundElement = true;
      }
    });

    if (isFoundElement) {
      verifyIfAccepted(parseInt(commuId));
      setCommunity(parseInt(commuId));
    }

    setIsFound(isFoundElement);
    setLoading(false);
  };

  const verifyIfAccepted = async (community) => {
    if (!community) {
      return;
    }

    const resp = await Api.get(`/sheet_actions/is_accepted/${match.params.id}/${communityId || community.id}`);
    setAccepted(resp.data.accepted);
  };

  const accept = async () => {
    setLoad(true);
    try {
      const response = await Api.get(`/sheet_actions/accept/${match.params.id}/${communityId || community.id}`);
      setAccepted(response.data && response.data.success);
      notif.success(t('adminapp_success'), t('adminapp_sheet_action_accepted'));
    } catch (e) {
      console.error(e);
    }
    setLoad(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const commuId = urlParams.get('community');

    if (community) {
      verifyExistance();
    } else {
      verifyIfAccepted(commuId);
      setCommunity(commuId);
    }
  }, [community]);

  useEffect(() => {}, [accepted]);

  if (loading) {
    return <Loader />;
  }

  return (
    <View>
      <div className="return-sheet-action">
        <Link className="ml-2 bg-darkpink rounded-pill px-4" to="/viz/sheet_actions">
          {t('back')}
        </Link>
      </div>
      {!isFound ? (
        <div className="w-100 text-center">{t('adminapp_no_resource_for_this_community')}</div>
      ) : (
        <ResourceForm resource="SheetAction" id={match.params.id} initialValues={{ name: '', url: '', partners: [] }}>
          {({ values, setFieldValue }) => (
            <>
              <Tabs className="tab-sheet-action" baseUri={match.url}>
                <Description color="danger" tab={t('adminapp_description')} resource={values} />
                <DashboardTabs
                  tab={t('adminapp_user_feedback')}
                  dashboard={values.feedbackDashboard}
                  path={`user_feedback`}
                  type="feedback"
                  id={match.params.id}
                  communityId={communityId}
                />
                {/* {permitDiscussion && <Discussion actionSheet={values} tab={t('adminapp_discussion')} path="discussion" />} */}
                <DashboardTabs
                  tab={t('adminapp_operation')}
                  path="operation"
                  dashboard={values.dimensionDashboard}
                  type="operation"
                  id={match.params.id}
                  communityId={communityId}
                />
                <DashboardTabs
                  tab={t('adminapp_review')}
                  path="review"
                  dashboard={values.evaluationDashboard}
                  type="evaluation"
                  id={match.params.id}
                  communityId={communityId}
                />

                {security.isGranted(['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER']) ? (
                  <Actions tab={t('adminapp_actions')} path="actions" values={values} setFieldValue={setFieldValue} />
                ) : null}
              </Tabs>
              <div className="mt-4 mb-4">
                <Button className="btn-wever bg-pink-wever" onClick={accept} disabled={load || accepted} type="button">
                  {load ? t('adminapp_loading') : accepted ? t('adminapp_accepted') : t('adminapp_accept_sheet_action')}
                </Button>
              </div>
            </>
          )}
        </ResourceForm>
      )}
    </View>
  );
};
