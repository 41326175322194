/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, FormFeedback, Spinner, Row, Col } from 'reactstrap';
import * as yup from 'yup';

import Input from '@/components/common/input-wever';
// import OAuthList from '@/components/security/oauth-list';
import api from '@/services/api';
import notifications from '@/services/notifications';
import security from '@/services/security';
import translator, { t } from '@/services/translator';

export default ({ onSuccess, onFailed }) => {
  const [loading, setLoading] = useState(false);

  // Send request to api through security service
  const onSubmit = ({ username, password }) => {
    setLoading(true);

    security
      .login(username, password)
      .then(({ data }) => {
        if (typeof onSuccess === 'function') {
          if (false === onSuccess(data)) {
            setLoading(false);
          }
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          notifications.error(t('oops'), t('invalid_credentials'));
        }

        if (typeof onFailed === 'function') {
          onFailed(e);
        }

        setLoading(false);
      });
  };

  const connectWithToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (!token) {
      return;
    }
    setLoading(true);
    const response = await api.post('/users/magic_token', { magicToken: token });

    setLoading(false);
    if (!response.data) {
      return;
    }

    security.authenticate(response.data.token, response.data.refreshToken);

    if (typeof onSuccess === 'function') {
      onSuccess(response.data);
    }
  };

  useEffect(() => {
    connectWithToken();
  }, []);

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={{ username: '', password: '' }}
        validationSchema={yup.object({
          username: yup.string().required(translator.translate('field_required')),
          password: yup.string().required(translator.translate('field_required'))
        })}
      >
        {({ handleSubmit, handleBlur, errors, touched }) => (
          <Form noValidate role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <Input
                tag={Field}
                placeholder={translator.translate('username')}
                name="username"
                autoComplete="false"
                icon="envelope"
                onBlur={handleBlur}
                invalid={errors.username && touched.username}
              />
              {touched.username && <FormFeedback>{errors.username}</FormFeedback>}
            </FormGroup>
            <FormGroup className="mb-1">
              <Input
                tag={Field}
                placeholder={translator.translate('password')}
                type="password"
                name="password"
                autoComplete="false"
                icon="lock"
                onBlur={handleBlur}
                invalid={errors.password && touched.password}
              />

              {touched.password && <FormFeedback>{errors.password}</FormFeedback>}
            </FormGroup>
            <Row className="text-left mb-3">
              <Col xs="12" className="text-center">
                <p>
                  <small>
                    {t('managerapp_forgot_password')}{' '}
                    <Link className="text-pink-wever" to="/login/magic">
                      {t('managerapp_use_magic_link')}
                    </Link>
                  </small>
                </p>
              </Col>
            </Row>

            <div className="text-center">
              {loading ? (
                <Spinner size="md" />
              ) : (
                <button className="btn-wever bg-pink-wever w-100" type="submit">
                  {t('connexion')}
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {/* <OAuthList /> */}
    </>
  );
};
