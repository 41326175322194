export default [
  // ----- ACTIONS
  {
    path: 'actions',
    name: 'actions',
    resource: 'Action',
    roles: ['ROLE_ACCOUNT_MANAGER'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/actions/action/list').default,
        title: 'action_list_title',
        permissions: {
          Action: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/actions/action/form').default,
        title: 'action_create_title',
        permissions: {
          Action: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/actions/action/form').default,
        title: 'action_update_title',
        permissions: {
          Action: ['read', 'update']
        }
      }
    ]
  }
];
