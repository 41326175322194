import React from 'react';
import { useField, ErrorMessage } from 'formik';
// Style
import { Button, FormGroup, Label, Col, Card } from 'reactstrap';
// Core
import Icon from '@/components/common/icon';
//Core
import { t } from '@/services/translator';
// Même dossier
import Site from './information-sheet-site';

const Sites = () => {
  const [sites, , helper] = useField({ name: 'sites' });

  const addSite = () => {
    helper.setValue([...sites.value, { name: '', addressInformationSheet: {}, capacity: '' }]);
  };

  const removeSite = (idx) => {
    const newList = [...sites.value];
    newList.splice(idx, 1);
    helper.setValue(newList);
  };

  return (
    <>
      {Array.isArray(sites.value) &&
        sites.value.map((val, idx) => (
          <div key={`site-${idx}`}>
            <Label className="mt-3">
              {t('communitiesapp_site')} {idx + 1}
            </Label>
            <Card className="bg-light mt-3 p-2 w-50">
              <div className="informationsheetSiteposition">
                <Site idx={idx} />
                {sites.value.length < 2 ? null : (
                  <Button
                    type="button"
                    className="bg-white rounded-circle border border-danger"
                    style={{ position: 'absolute', top: '-20px', right: '-20px' }}
                    onClick={() => removeSite(idx)}
                  >
                    <Icon name="times" className="text-danger" />
                  </Button>
                )}
              </div>
            </Card>
          </div>
        ))}
      <ErrorMessage name={`sites`}>{(msg) => <div className="informationsheetErrorcolor">{msg}</div>}</ErrorMessage>
      <FormGroup row>
        <Label sm={2} />
        <Col sm={7}>
          <Button
            type="button"
            className="shadow-none informationsheetBtncolor mx-auto mt-3 rounded-pill"
            onClick={addSite}
          >
            <Icon name="plus-circle" className="me-4" />
            {t('communitiesapp_add_site')}
          </Button>
        </Col>
      </FormGroup>
    </>
  );
};

export default Sites;
