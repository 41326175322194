import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody, Input as ReactStrapInput } from 'reactstrap';

import { Input, TableLangValue, SelectIcon, Autocomplete } from '@/components/form';
import MediaInput from '@/components/form/media-input';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import { t } from '@/services/translator';

const ExpectedResponsePatternForm = ({ match }) => {
  const checkColor = (values, color, setValues) => {};

  return (
    <View>
      <ResourceForm
        resource="ExpectedResponsePattern"
        id={match.params.id}
        initialValues={{ alias: '', values: null, name: {}, icon: null, parameters: {} }}
      >
        {({ submitting, renderActions, setValues, values }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={4}>
                    <Input name="alias" placeholder="ex: alias_one_a" pattern="/^[a-zA-Z_0-9]+$" />
                  </Col>
                  <Col lg={4}>
                    <Input name="value" placeholder="ex: 19H30" required={false} />
                  </Col>
                  <Col lg={4}>
                    <SelectIcon name="icon" />
                  </Col>
                  <Col lg={6}>
                    <MediaInput resource="expected_response_patterns" name="iconSvg" />
                  </Col>
                  <Col lg={6}>
                    <Input type="textarea" name="helpMessage" placeholder="message" />
                  </Col>
                  <Col lg={4} className="d-flex align-items-center">
                    <ReactStrapInput
                      value={values.parameters?.color || ''}
                      size="sm"
                      onChange={(e) => setValues({ ...values, parameters: { color: e.target.value } })}
                      type="color"
                      placeholder="ex: blue / #555"
                      required={false}
                    />
                  </Col>
                  <Col lg={6}>
                    <Autocomplete
                      name="categories"
                      resource="Categ<ory"
                      autoload
                      multiple
                      tag="name"
                      display="name"
                      clearable
                      className="w-100"
                    />
                  </Col>
                  <Col lg={12} className="mb-4">
                    <p>{t('adminapp_names')}</p>
                    <TableLangValue name="name" />
                  </Col>
                  <Col lg={12}>
                    <p>{t('adminapp_short_names')}</p>
                    <TableLangValue name="shortNames" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

ExpectedResponsePatternForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default ExpectedResponsePatternForm;
