import React from 'react';
import { Col, Row, Table } from 'reactstrap';

import { Input } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';

export default () => {
  const getImage = (item) => {
    if (item.mimeType.indexOf('image') !== -1) {
      return <img alt="media" src={item.path} />;
    }

    return item.mimeType;
  };

  return (
    <View>
      <ResourceList
        resource="Media"
        deleteMultiple
        filters={() => (
          <Row>
            <Col lg={3}>
              <Input name="name" />
            </Col>
          </Row>
        )}
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                {renderColumn(renderSelect('*'), false)}
                {renderColumn('id')}
                {renderColumn('name')}
                {renderColumn('preview')}
                {renderColumn()}
              </tr>
            </thead>
            <tbody>
              {collection.map((item) => (
                <tr key={item.id}>
                  <td>{renderSelect(item.id)}</td>
                  <td>{item.originalName}</td>
                  <td>
                    <a target="_blank" href={item.path} rel="noopener noreferrer">
                      {item.path}
                    </a>
                  </td>
                  <td>{getImage(item)}</td>
                  <td>{renderItemActions(item)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </View>
  );
};
