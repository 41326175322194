/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import useCommunity from '@/components/hook/use-community';
import View from '@/components/view';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

export default (props) => {
  const { community, isLoadingCommunity, Loader } = useCommunity();
  const [ganttData, setGanttData] = useState();
  const [loading, setLoading] = useState();
  const [sizeLabel, setSizeLabel] = useState(300);

  function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  const load = async () => {
    setLoading(true);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const communityId = community?.id || urlParams.get('community');

    const response = await getResource('Community').getSheetActionGantt(communityId);

    if (response.sheetActionGantt) {
      let size = sizeLabel;
      const gantt = response.sheetActionGantt.map((action) => {
        const lth = getTextWidth(action.name, 'normal 14px Roboto');

        if (lth >= size) {
          action.name = action.name.substring(0, parseInt((250 * action.name.length) / lth)) + '...';
        }

        return [
          action.id,
          action.name,
          action.categories,
          action.start ? new Date(action.start) : null,
          action.end ? new Date(action.end) : null,
          action.duration,
          action.complete,
          action.dependencies
        ];
      });

      setSizeLabel(size);
      setGanttData(gantt);
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, [community]);

  if (isLoadingCommunity || loading) {
    return <Loader />;
  }

  return (
    <View>
      <div className="pt-5">
        <Link className="ml-2 bg-darkpink rounded-pill px-2" to="/viz/gantts">
          {t('back')}
        </Link>
        <h5 className="mt-3">{t('adminapp_gantt_title')}</h5>
        <Row className="mt-3 pt-3">
          <Col className="col-11 mx-auto">
            {Array.isArray(ganttData) && ganttData.length === 0 && <span>{t('adminapp_gantt_no_data')}</span>}
            {Array.isArray(ganttData) && ganttData.length > 0 && (
              <Chart
                width={'100%'}
                height={'400px'}
                chartType="Gantt"
                loader={<div>{t('adminapp_loading_chart')}</div>}
                data={[
                  [
                    { type: 'string', label: t('gantt_id') },
                    { type: 'string', label: t('gantt_task_name') },
                    { type: 'string', label: 'Resource' },
                    { type: 'date', label: t('gantt_start') },
                    { type: 'date', label: t('gantt_start') },
                    { type: 'number', label: 'Durée' },
                    { type: 'number', label: t('gantt_completed_percent') },
                    { type: 'string', label: t('gantt_dependencies') }
                  ],
                  ...ganttData
                ]}
                rootProps={{ 'data-gantt-community': '1' }}
                chartLanguage="fr"
                options={{
                  labelMaxWidth: 300
                }}
                labelMaxWidth={300}
              />
            )}
          </Col>
        </Row>
      </div>
    </View>
  );
};
