/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table, Spinner, Card, Row, Col } from 'reactstrap';

import { useField } from 'formik';

import security from '@/services/security';
import { t } from '@/services/translator';
import getResource from '@/services/resources';

const ActionSheet = ({ id }) => {
  const [field, , helpers] = useField({ name: 'sheetActions' });
  const [actions, setActions] = useState([]);
  const [checked, setChecked] = useState([]);
  const [isProcessing, setIsProcessing] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);

  const loadSheetAction = async (page) => {
    setIsProcessing([true, false]);

    const response = await getResource('SheetAction').readList(page || currentPage);
    const members = response['hydra:member'];

    const next = response['hydra:view'] ? response['hydra:view']['hydra:next'] : null;
    setHasNext(next !== null && next !== undefined);

    setActions(members);

    const temp = new Array(members.length).fill(false);
    const actions = field.value || [];

    members.forEach((sheetAction, index) => {
      actions.forEach((action) => {
        if (action.sheetAction === sheetAction['@id']) temp[index] = true;
      });
    });
    setChecked(temp);
    setIsProcessing([false, false]);
    setCurrentPage(page || currentPage);
  };

  const assignSheetAction = (index, item, itemChecked) => {
    const tmp = [...checked];
    tmp[index] = !itemChecked;
    const actions = field.value || [];
    if (!itemChecked) {
      actions.push({ sheetAction: item['@id'], '@id': null, id: null, '@type': 'SheetActionCommunity' });
      helpers.setValue(actions);
    } else {
      let toDeleteIndex = null;
      actions.forEach((element, index) => {
        if (element.sheetAction === item['@id']) {
          toDeleteIndex = index;
        }
      });

      if (toDeleteIndex >= 0) {
        actions.splice(toDeleteIndex, 1);
      }
    }

    setChecked(tmp);
  };

  const loadPage = () => {
    let min = currentPage - 3;
    min = min < 1 ? 1 : min;

    const components = [];
    for (min; min < currentPage; min++) {
      components.push(
        <Col className="col-1">
          <button className="btn-pagination" onClick={() => loadSheetAction(min)}>
            {min}
          </button>
        </Col>
      );
    }

    return components;
  };

  useEffect(() => {
    loadSheetAction();
  }, []);

  return (
    <Card name="action_sheet">
      {isProcessing[0] ? (
        <Spinner className=" col-centered" color="dark" />
      ) : (
        <div className="crud-list">
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                <td>{t('communitiesapp_id')}</td>
                <td>{t('communitiesapp_name')}</td>
                <td>{t('communitiesapp_assigned')}</td>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(actions) &&
                actions.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    {security.isGranted('ROLE_ACCOUNT_MANAGER') ? (
                      <td key={item}>
                        {isProcessing[1] ? (
                          <Spinner className={`spinner-${index}`} type="grow" color="primary" />
                        ) : (
                          <input
                            disabled={!security.isGranted('ROLE_ACCOUNT_MANAGER')}
                            name={`assign-${index}`}
                            checked={checked[index]}
                            type="checkbox"
                            onClick={() => assignSheetAction(index, item, checked[index])}
                          />
                        )}
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
          <Row className="w-50 mx-auto">
            <Col className="col-3">
              <button
                className="btn-pagination command"
                disabled={currentPage === 1}
                onClick={() => loadSheetAction(currentPage - 1)}
              >
                {t('communitiesapp_previous')}
              </button>
            </Col>
            <Col className="col">
              <Row>
                {loadPage()}
                <Col className="col-1">
                  <button className="btn-pagination current">{currentPage}</button>
                </Col>
              </Row>
            </Col>
            <Col className="col-3">
              <button
                className="btn-pagination command"
                disabled={!hasNext}
                onClick={() => loadSheetAction(currentPage + 1)}
              >
                {t('communitiesapp_next')}
              </button>
            </Col>
          </Row>
        </div>
      )}
    </Card>
  );
};

export default ActionSheet;
