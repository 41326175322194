/* eslint-disable react-hooks/exhaustive-deps  */
import { useField } from 'formik';
import React, { useState, useEffect } from 'react';

import { CreatableSelect } from '@/components/form';
import { t } from '@/services/translator';

const SelectAliases = ({ index, fields }) => {
  const [filterValuesField, , helpers] = useField({ name: `filters.${index}.alias` });
  const [values, setValues] = useState([]);

  const handleOnChange = (valueOptions) => {
    if (!valueOptions) {
      return;
    }

    helpers.setValue(valueOptions.join(';'));
  };

  useEffect(() => {
    if (!filterValuesField.value) {
      return;
    }

    const splitedValues = filterValuesField.value?.split(';');
    if (!Array.isArray(splitedValues)) {
      return;
    }

    setValues(splitedValues.map((val) => ({ value: val, label: val })));
  }, [filterValuesField.value]);

  if (!values || !fields) {
    return null;
  }

  return (
    <CreatableSelect
      value={values}
      options={fields}
      onSelect={handleOnChange}
      label={t('adminapp_filter_alias')}
      multiple
    />
  );
};

export default SelectAliases;
