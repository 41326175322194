/* eslint-disable global-require */

export default [
  {
    path: 'graph-type',
    name: 'graph_type',
    resource: 'GraphType',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/graph-type/list').default,
        title: 'graph-type_list_title',
        permissions: {
          GraphType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/graph-type/form/index').default,
        title: 'graph-type_create_title',
        permissions: {
          GraphType: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/graph-type/form/index').default,
        title: 'graph-type_update_title',
        permissions: {
          GraphType: ['read', 'update']
        }
      }
    ]
  },

  {
    path: 'dashboards',
    name: 'dashboard',
    resource: 'GraphType',
    roles: ['ROLE_ADMIN', 'ROLE_TEAM_WEVER'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      /**
       * -------> LAYOUT
       */
      {
        path: '/layouts',
        name: 'layout',
        resource: 'DashboardLayout',
        roles: ['ROLE_ADMIN', 'ROLE_TEAM_WEVER'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/dashboard-user/layout/list').default,
            title: 'dashboard_layout_list_title',
            permissions: {
              DashboardLayout: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/dashboard-user/layout/form/index').default,
            title: 'dashboard_layout_create_title',
            permissions: {
              DashboardLayout: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/dashboard-user/layout/form/index').default,
            title: 'dashboard_layout_update_title',
            permissions: {
              DashboardLayout: ['read', 'update']
            }
          }
        ]
      },
      /**
       * -------> TYPE
       */
      {
        path: '/types',
        name: 'type',
        resource: 'DashboardLayoutType',
        roles: ['ROLE_ADMIN'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/dashboard-user/type/list').default,
            title: 'dashboard_type_list_title',
            permissions: {
              DashboardLayoutType: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/dashboard-user/type/form').default,
            title: 'dashboard_type_create_title',
            permissions: {
              DashboardLayoutType: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/dashboard-user/type/form').default,
            title: 'dashboard_type_update_title',
            permissions: {
              DashboardLayoutType: ['read', 'update']
            }
          }
        ]
      }
    ]
  }
];
