import PropTypes from 'prop-types';
import React from 'react';

import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

import Form from './form';

const Index = ({ match }) => {
  return (
    <View>
      <ResourceForm
        resource="DashboardLayout"
        id={match.params.id}
        initialValues={{ name: {}, items: [], report: null, type: null }}
      >
        {({ submitting, values, renderActions }) => (
          <>
            <Form submitting={submitting} id={match.params.id} />
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default Index;
