/* eslint-disable global-require */

/** @todo TO FINISH IT. */
export default [
  {
    path: 'geometry_type',
    name: 'geometry_type',
    resource: 'GeometryType',
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/geometry/geometry_type').default,
        title: 'geometry_type_list_title',
        permissions: {
          GeometryType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/geometry/geometry_type/form').default,
        title: 'geometry_type_create_title',
        permissions: {
          GeometryType: ['read', 'create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/geometry/geometry_type/form').default,
        title: 'geometry_type_update_title',
        permissions: {
          GeometryType: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'geometry_group',
    name: 'geometry_group',
    resource: 'GeometryGroup',
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/geometry/geometry_group').default,
        title: 'geometry_group_list_title',
        permissions: {
          GeometryType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/geometry/geometry_group/form').default,
        title: 'geometry_group_create_title',
        permissions: {
          GeometryType: ['read', 'create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/geometry/geometry_group/form').default,
        title: 'geometry_group_update_title',
        permissions: {
          GeometryType: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'geometry_line',
    name: 'geometry_line',
    resource: 'GeometryLine',
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/geometry/line').default,
        title: 'geometry_line_list_title',
        permissions: {
          GeometryType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/geometry/line/form').default,
        title: 'geometry_line_create_title',
        permissions: {
          GeometryType: ['read', 'create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/geometry/line/form').default,
        title: 'geometry_line_update_title',
        permissions: {
          GeometryType: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'geometry_polygon',
    name: 'geometry_polygon',
    resource: 'GeometryPolygon',
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/geometry/polygon').default,
        title: 'geometry_polygon_list_title',
        permissions: {
          GeometryType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/geometry/polygon/form').default,
        title: 'geometry_polygon_create_title',
        permissions: {
          GeometryType: ['read', 'create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/geometry/polygon/form').default,
        title: 'geometry_polygon_update_title',
        permissions: {
          GeometryType: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'geometry_point',
    name: 'geometry_point',
    resource: 'GeometryPoint',
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/geometry/point').default,
        title: 'geometry_point_list_title',
        permissions: {
          GeometryType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/geometry/point/form').default,
        title: 'geometry_point_create_title',
        permissions: {
          GeometryType: ['read', 'create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/geometry/point/form').default,
        title: 'geometry_point_update_title',
        permissions: {
          GeometryType: ['read', 'update']
        }
      }
    ]
  } /* 
      {
        path: '/geometry_type',
        name: 'create',
        title: 'geometry_type_title',
        roles: ['ROLE_MANAGER'],
        exact: true,
        component: require('@/views/geometry/geometry_type/create').default
      }, */
  /* {
        path: '/geometry_group',
        name: 'geometry_group',
        title: 'geometry_group_title',
        roles: ['ROLE_MANAGER'],
        exact: true,
        component: require('@/views/geometry/geometry_group').default
      },
      {
        path: '/geometry_line',
        name: 'geometry_line',
        title: 'geometry_line_title',
        roles: ['ROLE_MANAGER'],
        exact: true,
        component: require('@/views/geometry/line').default
      },
      {
        path: '/geometry_point',
        name: 'geometry_point',
        title: 'geometry_point_title',
        roles: ['ROLE_MANAGER'],
        exact: true,
        component: require('@/views/geometry/point').default
      } */
];
