/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';

import Loader from '@/components/common/loader';
import Context from '@/services/context';
import getResource from '@/services/resources';

const useCommunity = (communityId) => {
  const { community } = useContext(Context);
  const [communityObj, setCommunityObj] = useState();
  const [loading, setLoading] = useState();

  const loadCommunity = async (paramCommunityId) => {
    setLoading(true);
    let id = community || communityId;
    const response = await getResource('Community').read(`${id || paramCommunityId}`.replace('/communities/', ''));
    setCommunityObj(response);
    setLoading(false);
  };

  useEffect(() => {
    if (!community) {
      return;
    }

    loadCommunity();
  }, [community]);

  return { community: communityObj, isLoadingCommunity: loading, setLoading, Loader, loadCommunity };
};

export default useCommunity;
