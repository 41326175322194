/* eslint-disable class-methods-use-this */
import Resource from '@/services/resources/common';

export default class CommandQueuResource extends Resource {
  constructor(entrypoint = '/command_queues', alias = 'CommandQueue') {
    super(entrypoint, alias);
  }

  canCreate() {
    return false;
  }

  canUpdate() {
    return false;
  }
}
