import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_communities',
    icon: <Icon name="building" className="text-pink iconRightMarginMax" />,
    roles: ['!ROLE_TRAD_DIAGNOSTIC', 'ROLE_ACCOUNT_MANAGER'],
    items: [
      {
        label: 'adminapp_menu_communities_list',
        route: 'communities_list',
        roles: ['ROLE_ACCOUNT_MANAGER']
      },
      {
        label: 'adminapp_menu_communities_create',
        route: 'communities_create',
        roles: ['ROLE_ACCOUNT_MANAGER']
      },
      {
        label: 'adminapp_menu_type_client',
        icon: <Icon name="user" className="text-pink iconRightMarginMax" />,
        roles: ['ROLE_ACCOUNT_MANAGER'],
        items: [
          {
            label: 'adminapp_menu_type_client_list',
            route: 'type-client_list',
            roles: ['ROLE_ACCOUNT_MANAGER']
          },
          {
            label: 'adminapp_menu_type_client_create',
            route: 'type-client_create',
            roles: ['ROLE_ACCOUNT_MANAGER']
          }
        ]
      }
    ]
  }
];
