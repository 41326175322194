import React from 'react';
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap';

import IMG from '@/assets/img/common/dashboard.png';
import Icon from '@/components/common/icon';
import translate from '@/components/common/translate';
import notifs from '@/services/notifications';
// import Media from '@/components/common/media';
import { t } from '@/services/translator';

export default ({ community, onSelect }) => {
  const { name, dashboards, report } = community;

  let idCollapse = name.toLowerCase().trim().replace(/\s/g, '');
  idCollapse = idCollapse.replace('(', '').replace(')', '');

  const redirect = (id, nameDashboard) => {
    if (!report) {
      notifs.info(t('adminapp_cannot_access_dashboard'), t('adminapp_no_data_see_admin'));
      return;
    }

    onSelect(community.id, report.replace('/reports/'), id, nameDashboard);
  };

  return (
    <div className="accordion-item wever">
      <h2 className="accordion-header" id={idCollapse}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${idCollapse}_collapse`}
          aria-expanded="true"
          aria-controls={`#${idCollapse}_collapse`}
        >
          {/* <span className="mr-4">{logo && <Media data={logo} width="50px" style={{ borderRadius: '50%' }} />}</span>{' '} */}
          {t(name)}
        </button>
      </h2>
      <div id={`${idCollapse}_collapse`} className="accordion-collapse collapse show" aria-labelledby={idCollapse}>
        <div className="accordion-body">
          <ListGroup className="managerMap">
            {Array.isArray(dashboards) &&
              dashboards.map((dashboard) => (
                <ListGroupItem
                  className="justify-content-between managerMapItem"
                  onClick={() => redirect(dashboard.id, dashboard.name)}
                  key={dashboard.id}
                >
                  <Row>
                    <Col lg="3" sm="5">
                      <img src={IMG} width="65px" alt="" />
                    </Col>
                    <Col lg="6">{translate(dashboard.name)}</Col>
                    <Col lg="3" className="text-right d-flex justify-content-end align-items-center">
                      <span className="text-grey managerMapItemExplore">
                        {t('explore')} <Icon name="chevron-right" />
                      </span>
                    </Col>
                  </Row>
                </ListGroupItem>
              ))}
          </ListGroup>
        </div>
      </div>
    </div>
  );
};
