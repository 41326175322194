/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import Modal from '@/components/common/modal';
// import AdminFooter from '@/components/layout/footers/footer';
import Header from '@/components/layout/headers/header';
import AdminNavbar from '@/components/layout/navbars/navbar';
import Sidebar from '@/components/layout/sidebar/sidebar';
import notifications from '@/services/notifications';
import router from '@/services/router';
import history from '@/services/router/history';
import security from '@/services/security';

import LoadingLayout from './loading-layout';

const Admin = ({ routes }) => {
  const [isAdminLoading, setAdminLoading] = useState(true);
  const mainContent = useRef();

  /**
   * @type {?function}
   */
  let removeNavigationListener = null;

  const initiliaze = async () => {
    if (
      !security.isGranted('ROLE_MANAGER') &&
      !security.isGranted('ROLE_ACCOUNT_MANAGER') &&
      !security.isGranted('ROLE_TRAD_DIAGNOSTIC')
    ) {
      if (router.currentUri !== '/') {
        notifications.error('Oops', 'Only admin and community managers can access to this platform.');
      }
      return router.navigate('/login');
    }

    // Scroll top when user navigates between different pages
    removeNavigationListener = history.listen(() => {
      if (router.previousUri !== router.currentUri) {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        if (mainContent.current) {
          mainContent.current.scrollTop = 0;
        }
      }
    });

    await security.init();
    setAdminLoading(false);
  };

  useEffect(() => {
    initiliaze();

    return () => {
      if (typeof removeNavigationListener === 'function') {
        removeNavigationListener();
      }
    };
  }, []);

  if (isAdminLoading) {
    return <LoadingLayout />;
  }

  return (
    <>
      <Modal />
      <Sidebar />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar />
        <Header />
        {routes()}
        {/* <AdminFooter /> */}
      </div>
    </>
  );
};

export default Admin;
