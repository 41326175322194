import api from '@/services/api';
import Resource from '@/services/resources/common';

export default class ReportResource extends Resource {
  constructor(entrypoint = '/reports', alias = 'Report') {
    super(entrypoint, alias);
  }

  fetchPreview(id, instanceId) {
    return api.get(this.buildUri(id, 'preview_data') + `?instance=${instanceId}`).then(({ data }) => data);
  }

  fetchCalculationTypeParams(name) {
    return api.get(this.buildUri('calculation_types'), { name }).then(({ data }) => data);
  }

  duplicate(id) {
    return api.post(this.buildUri(id, 'duplicate'), {}).then(({ data }) => data);
  }
}
