import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_sheet_actions',
    path: '',
    roles: ['!ROLE_ADMIN', '!ROLE_SUPER_ADMIN', '!ROLE_TRAD_DIAGNOSTIC', 'ROLE_MANAGER'],
    icon: <Icon name="file-alt" className="iconRightMarginMid" />,
    items: [
      {
        label: 'adminapp_sheet_actions_list',
        path: '/viz/sheet_actions',
        icon: <Icon name="file-alt" className="iconRightMarginMid" />
      },
      {
        label: 'adminapp_sheet_actions_gantt',
        path: '/viz/gantts',
        icon: <Icon name="calendar-alt" className="iconRightMarginMid" />
      }
    ]
  },
  {
    label: 'adminapp_menu_viz',
    path: '',
    roles: ['!ROLE_ADMIN', '!ROLE_SUPER_ADMIN', '!ROLE_TRAD_DIAGNOSTIC', 'ROLE_MANAGER'],
    icon: <Icon name="chart-area" className="iconRightMarginMax" />,
    items: [
      {
        label: 'adminapp_menu_viz_map',
        path: '',
        items: [
          {
            label: 'adminapp_menu_viz_map_list',
            path: '/viz/map_list'
          }
          // {
          //   label: 'adminapp_menu_viz_map_create',
          //   path: '/viz/map_create',
          //   roles: ['ROLE_MANAGER']
          // }
        ]
      },
      {
        label: 'adminapp_menu_viz_dashboard',
        path: '',
        items: [
          {
            label: 'adminapp_menu_viz_dashboard_list',
            path: '/viz/dashboard_list'
          }
          // {
          //   label: 'adminapp_menu_viz_dashboard_create',
          //   path: '/viz/dashboard_create',
          //   roles: ['ROLE_ACCOUNT_MANAGER']
          // }
        ]
      }
    ]
  }
];
