import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'data_reference',
    icon: <Icon name="file-alt" className="iconRightMarginMid" />,
    roles: ['ROLE_ACCOUNT_MANAGER'],
    items: [
      {
        label: 'structure_type',
        route: 'structure_types_list',
        roles: ['ROLE_ACCOUNT_MANAGER']
      },
      {
        label: 'structure_activities',
        route: 'structure_activities_list',
        roles: ['ROLE_ACCOUNT_MANAGER']
      },
      {
        label: 'adminapp_menu_diagnostic_tag',
        route: 'tag',
        icon: <Icon name="tags" className="iconRightMarginSmall" />,
        items: [
          {
            label: 'adminapp_menu_diagnostic_tag_list',
            route: 'tag_list'
          },
          {
            label: 'adminapp_menu_diagnostic_tag_create',
            route: 'tag_create'
          }
        ]
      }
    ]
  }
];
