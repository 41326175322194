/* eslint-disable global-require */

export default [
  {
    path: 'translator',
    name: 'translator',
    component: require('@/components/layout/view-layout').default,
    roles: ['!ROLE_SUPER_ADMIN', 'ROLE_TRAD_DIAGNOSTIC'],
    routes: [
      {
        path: '/expected_response_pattern',
        name: 'expected_response_pattern',
        resource: 'ExpectedResponsePattern',
        roles: ['!ROLE_SUPER_ADMIN', 'ROLE_TRAD_DIAGNOSTIC'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            roles: ['!ROLE_SUPER_ADMIN', 'ROLE_TRAD_DIAGNOSTIC'],
            component: require('@/views/diagnostic-translator/expected-response-pattern/list').default,
            title: 'expected_response_pattern_list_title',
            permissions: {
              ExpectedResponsePattern: ['read:list']
            }
          },
          {
            path: '/:id',
            name: 'update',
            resource: 'ExpectedResponsePattern',
            context: 'translator:update',
            exact: true,
            roles: ['ROLE_TRAD_DIAGNOSTIC'],
            component: require('@/views/diagnostic-translator/expected-response-pattern/form').default,
            title: 'expected_response_pattern_update_title',
            permissions: {
              ExpectedResponsePattern: ['translator:update']
            }
          }
        ]
      },
      {
        path: '/question_patterns',
        name: 'question_patterns',
        resource: 'QuestionPattern',
        roles: ['ROLE_TRAD_DIAGNOSTIC'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            roles: ['ROLE_TRAD_DIAGNOSTIC'],
            component: require('@/views/diagnostic-translator/question-pattern/list').default,
            title: 'question_pattern_list_title',
            permissions: {
              QuestionPattern: ['read:list']
            }
          },
          {
            path: '/:id',
            name: 'update',
            resource: 'QuestionPattern',
            context: 'translator:update',
            exact: true,
            roles: ['ROLE_TRAD_DIAGNOSTIC'],
            component: require('@/views/diagnostic-translator/question-pattern/form').default,
            title: 'question_pattern_update_title',
            permissions: {
              QuestionPattern: ['translator:update']
            }
          }
        ]
      }
    ]
  }
];
