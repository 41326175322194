import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { TableLangValue, MediaInput, Editor } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

const Index = ({ match }) => {
  return (
    <View>
      <ResourceForm resource="GeometryType" id={match.params.id} initialValues={{ name: {} }}>
        {({ submitting, renderActions }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={12}>
                    <TableLangValue name="name" />
                  </Col>
                  <Col lg={12}>
                    <MediaInput mineTypeRestriction="image/svg+xml" resource="geometry_types" name="picto" />
                  </Col>
                  <Col lg={12}>
                    <Editor name="style" />
                  </Col>
                  <Col lg={12}>
                    <Editor name="data" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default Index;
