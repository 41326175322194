/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Row, Col, Container, Spinner } from 'reactstrap';

import api from '@/services/api';
import notifications from '@/services/notifications';
import history from '@/services/router/history';
import security from '@/services/security';
import { t } from '@/services/translator';

const MagicRedirect = () => {
  const connectWithToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (!token) {
      return;
    }

    let response = null;
    try {
      response = await api.post('/users/magic_token', { magicToken: token });
    } catch (error) {
      const pathname = 'login';
      history.push({
        pathname,
        state: { from: 'login' }
      });
      return;
    }
    if (!response.data) {
      const pathname = 'login';
      history.push({
        pathname,
        state: { from: 'login' }
      });
      return;
    }

    security.authenticate(response.data.token, response.data.refreshToken);
    if (
      !security.isGranted('ROLE_MANAGER') &&
      !security.isGranted('ROLE_ACCOUNT_MANAGER') &&
      !security.isGranted('ROLE_TRAD_DIAGNOSTIC')
    ) {
      notifications.error(t('oops'), t('required_role_missing'));
      const pathname = 'login';
      history.push({
        pathname,
        state: { from: 'login' }
      });
      return false;
    } else {
      const pathname = 'communities';
      history.push({
        pathname,
        state: { from: 'login' }
      });
      return true;
    }
  };

  useEffect(() => {
    connectWithToken();
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col className="col-12 pl-2">
          <h2 style={{ textAlign: 'center' }}>{t('userapp_magic_redirect')}</h2>
        </Col>
        <Spinner style={{ width: '3rem', height: '3rem' }} color="secondary" />
      </Row>
    </Container>
  );
};

MagicRedirect.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.any
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default MagicRedirect;
