/* eslint-disable react-hooks/exhaustive-deps  */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { Element, scroller } from 'react-scroll';
import { Col, Row, Button, FormGroup, Label, Input as BootstrapInput, Popover, PopoverBody, Tooltip } from 'reactstrap';

import { Input, TableLangValue, Select, CreatableSelect, Checkbox } from '@/components/form';
import { t } from '@/services/translator';

const Graph = ({ fields, setFields, dataTypes, dataTypeOptions, stringFields, setStringFields }) => {
  const [itemsField, , helpers] = useField('items');
  const [loadingField, setLoadingField] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [itemNames, setItemNames] = useState([]);
  const popupTargetRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState({});

  const addItem = () => {
    const values = itemsField.value || [];

    values.push({ name: { fr: 'Name' }, colspan: 12, dataType: null, displayName: true });
    const names = values.map((item, index) => {
      const nameFr = item.name && item.name.fr ? item.name.fr : `${index}`;
      return nameFr
    });
    setItemNames(names);
    helpers.setValue(values);
  };

  const removeItem = (index) => {
    const values = itemsField.value.filter((_, i) => i !== index);
    const names = values.map((item, index) => {
      const nameFr = item.name && item.name.fr ? item.name.fr : `${index}`;
      return nameFr
    });
    setItemNames(names);
    helpers.setValue(values);
  };

  const scrollToItem = (index) => {
    scroller.scrollTo(`graph-item-${index}`, {
      duration: 0,
      delay: 0,
      smooth: 'easeInQuad'
    });
  };

  const moveItem = (currentIndex, newPosition) => {
    let values = [...itemsField.value];
    scrollToItem(newPosition);
    if (currentIndex === -1 || newPosition < 0 || newPosition >= values.length) return;

    const itemToMove = values.splice(currentIndex, 1)[0];
    values.splice(newPosition, 0, itemToMove);

    const names = values.map((item, index) => {
      const nameFr = item.name && item.name.fr ? item.name.fr : `${index}`;
      return nameFr
    });
    setItemNames(names);
    setPopoverOpen(true);

    values.map((item) => {
      item.origin = item.id ?? item._id;
      item['@id'] = null;
      item.id = null;
      item._id = null;
    });

    helpers.setValue(values);
  };

  const PositionSelector = ({ index, currentIndex }) => {
    const itemCount = itemsField.value.length;
    const options = Array.from({ length: itemCount }, (_, i) => (
      <option key={i} value={i} disabled={i === currentIndex}>
        {i + 1}
      </option>
    ));

    return (
      <FormGroup style={{ display: 'flex', alignItems: 'baseline' }}>
        <Label style={{ paddingRight: '10px' }} for={`position-selector-${index}`}>
          {t('adminapp_item_position')}
        </Label>
        <BootstrapInput
          id={`position-selector-${index}`}
          bsSize="sm"
          type="select"
          value={currentIndex}
          style={{ width: '40px' }}
          onChange={(e) => moveItem(index, parseInt(e.target.value, 10))}
        >
          {options}
        </BootstrapInput>
      </FormGroup>
    );
  };

  const updateParameters = (type, index) => {
    if (!dataTypes) {
      return [];
    }

    const value = itemsField.value || [];
    value[index].dataType = type;
    value[index].parameters = {};

    Object.keys(dataTypes).forEach((key) => {
      if (key === type) {
        const parameters = Object.keys(dataTypes[key]);
        parameters.forEach((fieldName) => {
          value[index].parameters[fieldName] = '';
        });
      }
    });

    helpers.setValue(value);
  };

  const AddParametersComponent = ({ onClick }) => {
    const [name, setName] = useState();

    return (
      <div className="mx-2 px-2 py-3 bg-light">
        <p>{t('adminapp_add_new_parameter')}</p>
        <input
          type="text"
          value={name || ''}
          onChange={(e) => setName(e.target.value)}
          placeholder={() => t('adminapp_parameters_name')}
        />
        <br />
        <Button color="info" className="mt-2" onClick={() => onClick(name)}>
          {t('adminapp_add_parameters')}
        </Button>
      </div>
    );
  };

  const addParameters = (name, index) => {
    const value = itemsField.value || [];

    if (!value[index].parameters) {
      value[index].parameters = {};
    }

    value[index].parameters[name] = '';
    helpers.setValue(value);
  };

  const handleCreateField = (fieldValue, field, index) => {
    setLoadingField(true);
    setTimeout(() => {
      const value = itemsField.value || [];
      const newOption = { label: t(fieldValue), value: fieldValue };
      setFields([...fields, newOption]);
      value[index].parameters[field] = fieldValue;
      helpers.setValue(value);
      setLoadingField(false);
    }, 250);
  };

  const addMissingField = (field) => {
    if (!stringFields.includes(field)) {
      setStringFields([...stringFields, field]);
      setFields([...fields, { value: field, label: field }]);
    }
  };

  const onPopoverScroll = (e) => {
    e.stopPropagation();
  };

  return (
    <Row>
      <div
        ref={popupTargetRef}
        style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1050, height: '1px', width: '1px' }}
        id="popupTarget"
      ></div>
      <Popover
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
        placement="auto"
        isOpen={popoverOpen}
        target={popupTargetRef}
        toggle={() => setPopoverOpen(!popoverOpen)}
      >
        <PopoverBody onScroll={onPopoverScroll} >
          <div style={{ position: 'relative' }}>
            <button
              className="pop-up-close-button"
              style={{
                position: 'absolute',
                top: '-22px',
                left: '141px',
                border: 'none',
                background: 'transparent',
                cursor: 'pointer',
                fontSize: '1.25rem'
              }}
              onClick={() => setPopoverOpen(false)} 
            >
              &times; 
            </button>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {itemNames.map((name, index) => (
                <li key={index} style={{ cursor: 'pointer' }} onClick={() => scrollToItem(index)}>
                  <span id={`itemTooltip-${index}`}>{`${index + 1}-${name.length > 20 ? `${name.substring(0, 20)}...` : name}`}</span>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen[`itemTooltip-${index}`]}
                    target={`itemTooltip-${index}`}
                    toggle={() =>
                      setTooltipOpen({
                        ...tooltipOpen,
                        [`itemTooltip-${index}`]: !tooltipOpen[`itemTooltip-${index}`]
                      })
                    }
                  >
                    {`${index + 1}-${name}`}
                  </Tooltip>
                </li>
              ))}
            </ul>
          </div>
        </PopoverBody>
      </Popover>
      <Col lg="12">
        <h5>{t('adminapp_title_items')}</h5>
        <Button onClick={addItem} color="primary" size="sm">
          {t('add_dashboard_component')}
        </Button>
      </Col>
      <Col lg="12" className="mt-2">
        <Row>
          {itemsField.value &&
            itemsField.value.map((item, index) => {
              const hasParameters = item.parameters && Object.keys(item.parameters).length > 0;

              return (
                <Element name={`graph-item-${index}`} key={index}>
                  <Col sm={item.colspan}>
                    <PositionSelector index={index} currentIndex={index} />
                    <Input name={`items.${index}.colspan`} type="number" label={t('adminapp_item_colspan')} />
                    <Select
                      options={dataTypeOptions}
                      value={item.dataType}
                      onSelect={(option) => updateParameters(option, index)}
                      label={t('adminapp_graph_type')}
                    />
                    <Input name={`items.${index}.displayName`} type="checkbox" label={t('adminapp_item_displayName')} />
                    <Input
                      name={`items.${index}.displayLegend`}
                      defaultChecked={true}
                      type="checkbox"
                      label={t('adminapp_item_displayLegend')}
                    />
                    <TableLangValue name={`items.${index}.name`} label={t('adminapp_item_names')} />
                    <hr className="mt-4" />
                    <p>{t('adminapp_parameters')}</p>
                    {hasParameters &&
                      Object.keys(item.parameters).map((field) => {
                        if (field.indexOf('field') === 0) {
                          addMissingField(item.parameters[field]);
                        }

                        return field.indexOf('field') === 0 ? (
                          <CreatableSelect
                            key={`creatable_params_${field}_${index}`}
                            options={fields}
                            name={`items.${index}.parameters.${field}`}
                            label={t(`adminapp_parameters_${field}`)}
                            onCreateOption={(val) => handleCreateField(val, field, index)}
                            isDisabled={loadingField}
                          />
                        ) : (
                          <Input
                            name={`items.${index}.parameters.${field}`}
                            label={t(`adminapp_parameters_${field}`)}
                          />
                        );
                      })}
                    <AddParametersComponent onClick={(newName) => addParameters(newName, index)} />
                    <hr className="mt-4" />
                    <Button className="mt-2" color="danger" onClick={() => removeItem(index)}>
                      {t('adminapp_delete_item')}
                    </Button>
                  </Col>
                </Element>
              );
            })}
        </Row>
      </Col>
    </Row>
  );
};

Graph.propTypes = {
  type: PropTypes.string.isRequired
};

export default Graph;
