import React from 'react';
import { Table, Card } from 'reactstrap';

import { useField } from 'formik';

import { t } from '@/services/translator';

export default () => {
  const [field, ,] = useField({ name: 'sheetActionsDashboards' });

  return (
    <Card name="action_sheet">
      <div className="crud-list">
        <Table responsive hover>
          <thead className="thead-light">
            <tr>
              <td>{t('communitiesapp_id')}</td>
              <td>{t('communitiesapp_tabs')}</td>
              <td>{t('communitiesapp_dashboard')}</td>
              <td>{t('communitiesapp_sheet_action')}</td>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(field.value) &&
              field.value.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.tab}</td>
                  <td>
                    {item.dashboard.id} - {item.dashboard.name}
                  </td>
                  <td>
                    {item.sheetAction.id} - {item.sheetAction.name}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </Card>
  );
};
