/* eslint-disable react-hooks/exhaustive-deps  */
import { useField } from 'formik';
import React, { useState } from 'react';
import { Col, Row, Button, Card, CardBody, CardHeader } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Input, TableLangValue, Select, Autocomplete } from '@/components/form';
import { t } from '@/services/translator';

import EditorValues from './filter-value';
import SelectAlias from './select-alias';

const Filters = ({ types, fields }) => {
  const [filtersField, , helpers] = useField({ name: 'filters' });
  const [filterClosed, setFilterClosed] = useState({});

  const addFilter = () => {
    let { value } = filtersField;

    if (!value) value = [];

    value.push({ inputType: 'select', alias: null, fieldType: null, values: [] });
    helpers.setValue(value);
  };

  const removeFilter = (index) => {
    let { value } = filtersField;

    value.splice(index, 1);
    helpers.setValue(value);
  };

  const handleFilterClosed = (index) => setFilterClosed({ ...filterClosed, [index]: !filterClosed[index] });

  return (
    <Row className="my-4 py-4 bg-light">
      <Col sm="12" col="12">
        <h5>{t('adminapp_title_filters')}</h5>
        <Button color="primary" className="my-2" onClick={addFilter}>
          {t('adminapp_add_filter')}
        </Button>
      </Col>
      <Col sm="12" col="12">
        <Row>
          {Array.isArray(filtersField.value) &&
            filtersField.value.map((filter, index) => {
              return (
                <Col sm="4" className="my-3">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col lg="9">
                          {t('adminapp_filter_header_title')} {index}
                        </Col>
                        <Col lg="3" className="d-flex justify-content-end">
                          <Button
                            size="sm"
                            className="text-black bg-transparent"
                            onClick={() => handleFilterClosed(index)}
                          >
                            <Icon name={filterClosed[index] ? 'chevron-up' : 'chevron-down'} />
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className={filterClosed[index] && 'd-none'}>
                      <Autocomplete
                        name={`filters.${index}.communities`}
                        resource="Community"
                        display="name"
                        tag="name"
                        multiple
                        label="adminapp_filter_access_to_communities"
                        autoload
                        clearable
                      />
                      <Autocomplete
                        name={`filters.${index}.excludedCommunities`}
                        resource="Community"
                        display="name"
                        tag="name"
                        multiple
                        label="adminapp_filter_communities_excluded_to_see_this_filter"
                        autoload
                      />
                      <Autocomplete
                        name={`filters.${index}.excludedCommunities`}
                        resource="Community"
                        display="name"
                        tag="name"
                        multiple
                        label={t('adminapp_filter_communities_excluded_to_see_this_filter')}
                        autoload
                      />
                      <Select
                        name={`filters.${index}.inputType`}
                        options={[{ value: 'select', label: 'select' }]}
                        label={t('adminapp_filter_inputType')}
                      />
                      <Select
                        name={`filters.${index}.filterType`}
                        options={types}
                        label={t('adminapp_filter_filterType')}
                      />
                      <Input name={`filters.${index}.limit`} type="number" label={t('adminapp_filter_limit')} />
                      <Input
                        name={`filters.${index}.fieldType`}
                        label={t('adminapp_filter_fieldType')}
                        placeholder="ex: geometry_polygon (spécifique aux zones aujourd'hui)"
                      />
                      <SelectAlias index={index} fields={fields} />
                      <p>{t('adminapp_filter_name')}</p>
                      <TableLangValue name={`filters.${index}.name`} />

                      <p>{t('adminapp_filter_values')}</p>
                      <p>
                        {t('adminapp_filter_values_example')} : <br />
                        <span className="my-1 bg-light">["valeur A", "valeur B"]</span>
                        <br />
                        <span className="my-1 bg-light">{`{"Label A": "valeur_a", "Label B": "valeur_b"}`}</span>
                      </p>
                      <EditorValues index={index} />
                      <hr className="mt-4" />
                      <Button className="mt-2" color="danger" onClick={() => removeFilter(index)}>
                        {t('adminapp_delete_filter')}
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Col>
    </Row>
  );
};

export default Filters;
