/* eslint-disable global-require */

export default [
  {
    path: 'medias',
    name: 'medias',
    resource: 'Media',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/media/list').default,
        title: 'media_list_title',
        permissions: {
          Media: ['read:list']
        }
      }
    ]
  }
];
