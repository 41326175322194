/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';

import Icon from '@/components/common/icon';
import View from '@/components/view';
import { context } from '@/services/context';
import logger from '@/services/logger';
import { t } from '@/services/translator';

const Description = ({ resource }) => {
  const {
    name,
    categories = [],
    presentation,
    description,
    objectives = [],
    // costs,
    // durations,
    medias,
    evaluationCriteria = []
  } = resource;
  // const [categoryArr, setCategoryArr] = useState([]);
  const [frontImg, setFrontImg] = useState(null);
  const [usefuLinks, setUsefuLinks] = useState([]);

  const manageFrontImg = () => {
    const index = medias?.findIndex((media) => {
      return media.mimeType.includes('image');
    });

    logger.info('SHEETACTION-----', 'media', { medias, index });
    if (index === -1 || typeof medias === 'undefined') {
      setFrontImg(null);
    } else {
      setFrontImg(medias[index]?.file?.path);
    }
  };

  const isYoutubeLink = (url) => (url ? url.includes('https://www.youtube.com') : null);

  const manageUsefulLinks = () => {
    const helper = [];

    medias.forEach((element) => {
      if (isYoutubeLink(element?.webUrl)) {
        helper.push(`https://www.youtube.com/embed/${element.webUrl.split('=')[1]}?autoplay=false`);
      } else {
        helper.push(element.webUrl);
      }
    });

    setUsefuLinks(helper);
  };

  const loadCategories = async () => {
    manageFrontImg();
    manageUsefulLinks();

    // const temp = [];

    // for (const element of categories) {
    // const response = await getResource('SheetActionCategory').read(element.id);
    //temp.push(response);
    // }
    // setCategoryArr(temp);
  };

  const handleImgOnclick = (e, media) => {
    setFrontImg(media.file?.path);
  };

  useEffect(() => {
    loadCategories();
  }, []);

  logger.info('SHEETACTION', 'load', { frontImg });

  return (
    <View>
      <Card>
        {/* <CardBody style={{ overflow:'auto', flex:'1 1 auto', height : '30em'}}> */}
        <CardBody>
          <header style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div id="left">
              <h2>
                {t(`adminapp_operation_name`)} : {t(`${name}`)}
              </h2>
              <div style={{ color: '#D73755', display: 'flex' }}>
                <div>{t('adminapp_categories')} :</div>
                <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '3px' }}>
                  {Array.isArray(categories) && categories.length === 0 ? (
                    <p>N/A</p>
                  ) : (
                    categories?.map((category) => (
                      <div className="discussionCategory">
                        <p>{t(category.name)}</p>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div id="right"></div>
          </header>

          <article style={{ display: 'flex', marginTop: '40px', flexDirection: 'row', justifyContent: 'start' }}>
            <div id="images">
              <img
                style={{ height: 'auto', width: 'auto', maxHeight: '300', maxWidth: '500px' }}
                src={frontImg}
                alt={`img_${parseInt(Math.random() * 1000)}_${frontImg}`}
                hidden={!frontImg}
              />
              <div>
                {Array.isArray(medias) &&
                  medias.map((media) => (
                    <img
                      hidden={!frontImg || !media.mimeType.includes('image') || media.file === null}
                      onClick={(e) => handleImgOnclick(e, media)}
                      className="card-img-wever"
                      src={media?.file?.path}
                      alt={`img_${parseInt(Math.random() * 1000)}_${media.id}`}
                    />
                  ))}
              </div>
            </div>

            <div style={{ marginLeft: !frontImg ? '0px' : '40px' }}>
              <div>
                <h3>{t('adminapp_presentation')}</h3>
                {presentation || <>{t('adminapp_no_value_presentation')} hhhhh</>}
              </div>
              <div style={{ marginTop: '20px' }}>
                <h3>{t('adminapp_objectives')}</h3>
                {Array.isArray(objectives) && objectives.length === 0 ? (
                  <p>{t('adminapp_no_value_objectives')}</p>
                ) : (
                  <ul>
                    {objectives.map((objective) => (
                      <li>{objective.name[context.locale]}</li>
                    ))}
                  </ul>
                )}
              </div>
              <div id="icones"></div>
            </div>
          </article>

          <article style={{ marginTop: '20px' }}>
            <div id="detailedDescription">
              <h3>{t('adminapp_detailled_description')}</h3>
              <p>{description || <>{t('adminapp_no_value_detailed_description')}</>}</p>
            </div>

            <div id="criteria">
              <h3>{t('adminapp_evaluation_criteria')}</h3>
              {Array.isArray(evaluationCriteria) && evaluationCriteria.length === 0 ? (
                <p>{t('adminapp_no_value_eval_criteria')}</p>
              ) : (
                <ul>
                  {evaluationCriteria.map((criteria) => (
                    <li>{criteria?.value}</li>
                  ))}
                </ul>
              )}
            </div>

            <div id="media" style={{ justifyContent: 'center' }}>
              <h3>{t('adminapp_useful_medias')}</h3>
              <div className="weverCardUsefulMedia">
                {medias?.length === 0 ? (
                  <>{t('adminapp_no_value_useful_media')}</>
                ) : (
                  Array.isArray(medias) &&
                  medias.map((media, index) => {
                    return (
                      <div>
                        <Card id="usefulMediaCard">
                          {media.mimeType.includes('video') ? (
                            <video
                              style={{ marginRight: '10px' }}
                              width="350"
                              height="200"
                              src={media?.file?.path}
                              controls
                              autoplay
                            >
                              <source type="video/*" alt="video" />
                            </video>
                          ) : media?.webUrl?.length > 0 && isYoutubeLink(usefuLinks[index] ?? 'http') ? (
                            <iframe
                              style={{ marginRight: '10px' }}
                              src={usefuLinks[index]}
                              title="link"
                              controls
                              allowFullScreen
                            />
                          ) : (
                            <Button
                              style={{ marginRight: '10px' }}
                              color="primary"
                              hidden={media.mimeType.includes('image')}
                              onClick={() => window.open(media?.file?.path ?? media?.webUrl, '_blank')}
                            >
                              {media?.originalName || media?.webUrl} <Icon name="download" />
                            </Button>
                          )}
                        </Card>
                        <div
                          hidden={media.mimeType.includes('image')}
                          style={{ color: '#D73755', textDecoration: 'underline' }}
                        >
                          {media?.description || media?.webUrl || t('addminapp_click_to_see')}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </article>
        </CardBody>
      </Card>
    </View>
  );
};
export default Description;
