/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik';
import { useState, useEffect } from 'react';

import getResource from '@/services/resources';

/**
 * Permet de gérer certains cas spécifique selon le type.
 */
export default (index) => {
  const [type] = useField({ name: `instances[${index}].type` });
  const [, , fieldsHelpers] = useField({ name: `instances[${index}].fields` });
  const [, , calculationsHelpers] = useField({ name: `instances[${index}].calculations` });
  const [instanceTypes, setInstanceTypes] = useState();
  const [instanceTypeName, setInstanceTypeName] = useState();

  const checkInstanceType = () => {
    const typeValue = type.value;
    let name = 'user';
    instanceTypes.forEach((instanceType) => {
      if (typeof typeValue === 'string' && typeValue === instanceType['@id']) {
        name = instanceType.name;
      } else if (typeof typeValue === 'object' && typeValue.name === instanceType.name) {
        name = instanceType.name;
      }
    });

    setInstanceTypeName(name);

    switch (name) {
      case 'user':
      case 'community':
        fieldsHelpers.setValue([]);
        break;
      case 'diagnostic':
        calculationsHelpers.setValue([]);
        break;
      default:
        calculationsHelpers.setValue([]);
    }
  };

  useEffect(() => {
    getResource('ReportInstanceType')
      .list()
      .then((data) => {
        setInstanceTypes(data['hydra:member']);
      });
  }, []);

  useEffect(() => {
    if (!instanceTypes || !type.value) {
      return;
    }

    checkInstanceType();
  }, [type.value, instanceTypes]);

  return { instanceTypeName };
};
