import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_media',
    icon: <Icon name="file-alt" className="iconRightMarginMid" />,
    roles: ['ROLE_ADMIN'],
    route: 'medias_list'
  }
];
