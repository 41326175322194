/* eslint-disable global-require */

export default [
  {
    path: 'eligibility_rules',
    name: 'eligibility_rules',
    resource: 'EligibilityRule',
    roles: ['ROLE_ACCOUNT_MANAGER'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/eligibility-rule/list').default,
        title: 'eligibility-rules_list_title',
        permissions: {
          EligibilityRule: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/eligibility-rule/form').default,
        title: 'eligibility-rules_create_title',
        permissions: {
          EligibilityRule: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/eligibility-rule/form').default,
        title: 'eligibility-rules_update_title',
        permissions: {
          EligibilityRule: ['read', 'update']
        }
      },
      {
        path: '/:id',
        name: 'delete',
        component: require('@/views/eligibility-rule/form').default,
        title: 'eligibility-rules_delete_title',
        permissions: {
          EligibilityRule: ['delete']
        }
      }
    ]
  }
];
