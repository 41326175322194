/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Col, Row, Table } from 'reactstrap';
import 'wever-maps/src/assets/style.scss';

import '@/assets/scss/components/tab-sheet-action.scss';
import { Input } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';
import Context from '@/services/context';

const GeoGroup = ({ match }) => {
  // const [permitDiscussion, setPermitDiscussion] = useState(false);
  const { locale } = useContext(Context);

  return (
    <View>
      <ResourceList
        resource="GeometryGroup"
        deleteMultiple
        filters={() => (
          <Row>
            <Col lg={3}>
              <Input name="name" />
            </Col>
          </Row>
        )}
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => {
          return (
            <Table responsive hover>
              <thead className="thead-light">
                <tr>
                  {renderColumn(renderSelect('*'), false)}
                  {renderColumn('id')}
                  {renderColumn('name')}
                  {renderColumn()}
                </tr>
              </thead>
              <tbody>
                {collection.map((item) => (
                  <tr key={item.id}>
                    <td>{renderSelect(item.id)}</td>
                    <td>{item.id}</td>
                    <td>{item.name[locale]}</td>
                    <td>{renderItemActions(item, null, true)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          );
        }}
      />
    </View>
  );
};
export default GeoGroup;
