import Resource from '@/services/resources/common';
import router from '@/services/router';
import security from '@/services/security';

export default class ExpectedResponsePatternResource extends Resource {
  entrypointSecondary = '/translator/expected_response_patterns';

  constructor(entrypoint = '/expected_response_patterns', alias = 'ExpectedResponsePattern') {
    super(entrypoint, alias);
  }

  canUpdate() {
    const isTranslator =
      !security.isGranted('ROLE_SUPER_ADMIN') &&
      !security.isGranted('ROLE_ADMIN') &&
      security.isGranted('ROLE_TRAD_DIAGNOSTIC');
    const permission = isTranslator ? 'translator:update' : 'update';

    return security.hasPermissions({ [this.alias]: [permission] });
  }

  update(...args) {
    const isTranslator =
      !security.isGranted('ROLE_SUPER_ADMIN') &&
      !security.isGranted('ROLE_ADMIN') &&
      security.isGranted('ROLE_TRAD_DIAGNOSTIC');
    this.entrypoint = isTranslator ? this.entrypointSecondary : this.entrypoint;

    return super.update(...args);
  }

  navigateToList() {
    if (
      !security.isGranted('ROLE_SUPER_ADMIN') &&
      !security.isGranted('ROLE_ADMIN') &&
      security.isGranted('ROLE_TRAD_DIAGNOSTIC')
    ) {
      router.navigate('translator_expected_response_patterns_list');
      return true;
    }

    return false;
  }
}
