export default [
  {
    path: 'token_exporter',
    name: 'token_exporter',
    //resource: 'PreBuildRequest',
    roles: ['ROLE_ACCOUNT_MANAGER'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'token_exporter',
        //   context: 'read:list',
        component: require('@/views/exporter/service').default,
        title: 'token_exporter_title'
        // permissions: {
        //     Action: ['read:list']
        // }
      }
    ]
  }
];
