import React, { useState, useEffect, useReducer } from 'react';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import _Api from '@/services/api';
import _Logger from '@/services/logger';

const Address = (props) => {
  const { onChange, value, index, onUpdate, errorName } = props;
  // STATE
  const [selectedAsyncAddress, setSelectedAsyncAddress] = useState(); // pour voir l'adresse séléctionner par l'utilisateur
  const [addressTimer, setAddressTimer] = useState({}); // Le temps de recherche d'une adresse

  const mapReducer = (state, action) => {
    switch (action.type) {
      case 'all':
        return action.value;
      case 'address':
      case 'postalCode':
      case 'city':
      case 'country':
      case 'region':
      case 'coordinate':
        const newState = { ...state, [action.type]: action.value };
        onChange(newState, null, index);
        return newState;
      default:
        return state;
    }
  };

  const [, dispatch] = useReducer(mapReducer, {
    address: '',
    postalCode: '',
    city: '',
    country: ''
  });

  /**
   * @param {string} terms
   * nous permet de récupérer des sugestions d'adresse grâce aux caractères entrée
   */

  const getSuggestions = async (terms) => {
    try {
      const response = await _Api.get(`/provider/geocoder/suggestions/${terms}/1`);
      return response.data.map((item) => {
        return { value: item, label: item.address };
      });
    } catch (e) {
      _Logger.error('Erreur suggestion', '', { e });
    }
  };

  /**
   * When typing on the react-select its start this function. careful need to return a resolving promise.
   *
   * @param {string} terms
   */
  const loadAsyncOptions = async (terms) => {
    clearTimeout(addressTimer);
    if (terms.length < 5) {
      return;
    }
    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        resolve(getSuggestions(terms));
      }, 650);
      setAddressTimer(timeout);
    });
  };

  /**
   * When clicking on the chosen address.
   *
   * @param {object} option
   */
  const onChangeAddress = (option) => {
    const selection = { ...option };
    setSelectedAsyncAddress(selection);
    const selectedValue = selection.value;
    if (!selectedValue || !selectedValue.coordinate) {
      return;
    }
    // external influence
    if (onUpdate && typeof onUpdate === 'function') {
      onUpdate(selectedValue);
    }
    const newState = {
      address: selection.label,
      coordinate: selectedValue.coordinate
    };
    newState.postalCode = selectedValue.postcode;
    newState.city = selectedValue.city;
    newState.country = selectedValue.country;
    newState.region = selectedValue.region;
    onChange(newState, null, index);
    dispatch({ type: 'all', value: newState });
  };

  /**
   * @param {object} value
   */
  const initializeValue = (value) => {
    setSelectedAsyncAddress({
      value: {
        name: value['address'],
        coordinate: value['coordinate']
      },
      label: value['address']
    });
    dispatch({ type: 'all', value: value });
  };

  /**
   * If address is used in another component that want to update its value.
   */
  useEffect(() => {
    if (!value) {
      return;
    }
    initializeValue(value);
  }, [value]);

  return (
    <Col sm={6}>
      <AsyncCreatableSelect
        cacheOptions
        loadOptions={loadAsyncOptions}
        value={selectedAsyncAddress}
        onChange={onChangeAddress}
        isClearable
      />
      <ErrorMessage name={errorName}>{(msg) => <div className="informationsheetErrorcolor">{msg}</div>}</ErrorMessage>
    </Col>
  );
};

Address.propTypes = {
  onChange: PropTypes.func.isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape({}))
};

Address.defaultProps = {
  elements: []
};

export default Address;
