import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Error, Input } from '@/components/form';
import { t } from '@/services/translator';

const Calculations = ({ index, getFieldHelpers }) => {
  const [fields] = useField({ name: `instances[${index}].fields` });
  const { setValue, setError, setTouched } = getFieldHelpers(`instances[${index}].fields`);
  const { value } = fields;

  return (
    <Row>
      <div></div>
      <Error name="fields" />
      {(fields.value || []).map((field, i) => (
        <Col lg={4} key={i}>
          <Card className="mb-3 shadow" key={`card_${i}`}>
            <CardHeader className="p-2 flex justify-content-between align-items-center">
              <h5 className="m-0">
                {t('weverapp_field')} {i + 1}
              </h5>
              <Button
                size="sm"
                color="gray"
                outline
                className="shadow-none"
                onClick={() => {
                  value.splice(i, 1);
                  setValue(value);
                  setError({});
                  setTouched(false);
                }}
              >
                <Icon name="times" />
              </Button>
            </CardHeader>
            <CardBody className="p-3">
              <Row key={`row_${i}`}>
                <Col lg={12}>
                  <Input name={`instances[${index}].fields[${i}]`} label={null} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      ))}
      <Col lg="12">
        <Button
          size="sm"
          color="secondary"
          className="mb-3"
          onClick={() => {
            const tmpValue = value ? [...value] : [];
            tmpValue.push('fill alias');
            setValue(tmpValue);
          }}
        >
          <Icon name="plus-circle" /> {t('weverapp_add_field')}
        </Button>
      </Col>
    </Row>
  );
};

Calculations.propTypes = {
  index: PropTypes.number.isRequired,
  getFieldHelpers: PropTypes.func.isRequired
};

export default Calculations;
